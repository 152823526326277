import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { post } from '../../util/api';
import { Segment, Breadcrumb, Icon, Button, Dropdown, Header, Table, Form, TableCell, Message, Input, Popup, Label, TableRow, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _, { add } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class Folder extends Component {
	state = {
		loading: true,
		saveButton: 'save',
		addbrand: 'Add',
		data: {},
		index: 0,
		checked: false,
		openPreview: false,
		preview: 'mobile',
		openRemoveBrandModal: false,
		removeDataId: 0,
		deleteData: {},
	};
	baseState = {
		logo: '',
		cover_photo: '',
		name: '',
		link: '',
		indexes: '',
		checked: '',
		other_texts: [],
	};

	componentDidMount() {
		this.getData();
	}

	async getData() {
		let { shop } = this.props;
		await post('/shop/brand/list', { pid: shop.pid }).then((data) => {
			let result = data && data.data && data.data.result && data.data.result.data;
			if (result && result.length > 0) {
				let element2 = {},
					indexes;
				result.forEach((element, i) => {
					element2 = element;
					indexes = i;
					element2.indexes = indexes;
				});
				if (result.length > 0) {
					this.setState({
						link: result[0].link,
						name: result[0].name,
						logo: result[0].logo,
						cover_photo: result[0].cover_photo,
						indexes: result[0].indexes,
						checked: result[0].active,
						other_texts: result[0].other_texts,
					});
				}
				let filtered_data = _.filter(result, (it) => it.name);
				this.setState({ data: filtered_data, loading: false });
			} else {
				let data = [{ link: '', name: 'NEW BRAND', logo: '', cover_photo: '', indexes: 0, checked: false, other_texts: [] }];
				this.setState({
					data,
					loading: false,
					link: '',
					name: 'NEW BRAND',
					logo: '',
					cover_photo: '',
					indexes: 0,
					checked: false,
					other_texts: [],
				});
			}
		});
	}

	handleEdit = (temp, i) => {
		let { t } = this.props;
		let { logo, name } = this.state;
		let control = name ? 1 : 0;
		control += logo ? 1 : 0;
		if (control > 1) {
			let { logo, cover_photo, name, link, indexes, active, other_texts } = temp;
			this.setState({
				logo,
				cover_photo: cover_photo || '',
				name,
				link,
				indexes,
				checked: active,
				other_texts,
			});
		} else {
			this.setState({ alert: t('alert'), openModal: true });
		}
	};
	removeBrandPreparation = (temp, i) => {
		this.setState({ openRemoveBrandModal: true, removeDataId: i, deleteData: temp });
	};
	deleteBrandData = async () => {
		let { removeDataId, data } = this.state;
		let shop_pid = this.props.shop.pid;
		data.splice(removeDataId, 1);
		await post('/shop/brand/upsert', { data, shop_pid }).then((data) => {
			let result = data.data;
			console.log('result', result);
			this.setState({ openRemoveBrandModal: false });
			this.getData();
		});
	};
	handleSave = async () => {
		this.setState({ loadingSaveButton: true });
		let { t, shop } = this.props;
		let { data, name, logo } = this.state;
		let saved;
		if ((!name && !logo) || (!name && logo) || (!logo && name)) {
			saved = t('error');
			this.setState({ loadingSaveButton: false, saveButton: saved, alert: t('alert0'), openModal: true });
		} else if (data[data.length - 1].name && data[data.length - 1].logo) {
			data.forEach((element) => {
				delete element.indexes;
			});
			await post('/shop/brand/upsert', { data, shop_pid: shop.pid }).then((data) => {
				let result = data.data;
				saved = result.success ? t('added') : t('error');
				this.setState({ loadingSaveButton: false, saveButton: saved });
			});
			this.getData();
		} else {
			saved = t('error');

			this.setState({ loadingSaveButton: false, saveButton: saved, alert: t('alert0'), openModal: true });
		}
		await setTimeout(() => this.setState({ saveButton: t('save') }), 1000);
	};

	newBrand = async () => {
		this.setState({ loadingaddbrand: true });
		let saved;
		let { t } = this.props;
		let { data, logo, name } = this.state;
		let control = name ? 1 : 0;
		control += logo ? 1 : 0;
		if (control > 1) {
			if (data.length > 0) {
				if (data[data.length - 1].name && data[data.length - 1].logo) {
					data.push({
						logo: '',
						cover_photo: '',
						name: 'NEW BRAND - ' + data.length,
						link: '',
						indexes: data.length,
						active: false,
						other_texts: [],
					});
					saved = 'added';
					let value = data[data.length - 1];
					this.setState({
						data,
						logo: value.logo,
						cover_photo: value.cover_photo,
						name: value.name,
						indexes: value.indexes,
						link: value.link,
						checked: value.active,
						other_texts: [],
					});
				} else {
					saved = 'error';
					this.setState({ alert: t('alert'), openModal: true });
				}
			} else {
				data.push({ logo: '', cover_photo: '', name: '', link: '', indexes: 0, other_texts: [] });
				saved = t('added');
				let value = data[0];
				this.setState({
					data,
					logo: value.logo,
					cover_photo: value.cover_photo,
					name: value.name,
					indexes: value.indexes,
					link: value.link,
					checked: value.active,
					other_texts: [],
				});
			}
		} else {
			saved = 'error';
			this.setState({ alert: t('alert'), openModal: true });
		}

		await setTimeout(() => this.setState({ loadingaddbrand: false, addbrand: saved }), 1000);
	};

	fileUpload(file, name) {
		let { t } = this.props;
		let { logo, data: _data, indexes } = this.state;
		const url = 'https://uploads.yapaytech.com/flow/uploadPermImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		let { type } = file;
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				if (type === 'image/png') {
					let images = this.state.logo;
					logo = image_url;
					_data.forEach((element, i) => {
						if (element['indexes'] === indexes) {
							_data[i]['logo'] = logo;
							this.setState({ [data]: _data, logo });
						}
					});
				} else {
					this.setState({ alert: t('alert1'), openModal: true });
				}
			});
	}

	fileCover(file, name) {
		let { t } = this.props;
		let { cover_photo, data: _data, indexes } = this.state;
		const url = 'https://uploads.yapaytech.com/flow/uploadPermImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		let { type } = file;
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				if (type === 'image/png') {
					let images = this.state.cover_photo;
					cover_photo = image_url;
					_data.forEach((element, i) => {
						if (element['indexes'] === indexes) {
							_data[i]['cover_photo'] = cover_photo;
							this.setState({ [data]: _data, cover_photo });
						}
					});
				} else {
					this.setState({ alert: t('alert1'), openModal: true });
				}
			});
	}

	handleChange = (e, { name, value }) => {
		let { data, indexes } = this.state;
		if (data.length == 0) {
			data[0][name] = value;
			this.setState({ [name]: value });
		}
		data.forEach((element, i) => {
			if (element['indexes'] === indexes) {
				data[i][name] = value;
				this.setState({ [name]: value, data });
			}
		});
	};

	closeModal = () => {
		this.setState({ openModal: false, openRemoveBrandModal: false });
	};

	openPreview = () => {
		this.setState({ openPreview: true });
	};

	closePreview = () => {
		this.setState({ openPreview: false });
	};

	changePreview = (e, { name }) => {
		this.setState({ preview: name });
	};

	updateCheck = () => {
		let { checked, indexes, data } = this.state;
		data.forEach((element, i) => {
			if (element['indexes'] === indexes) {
				data[i]['active'] = !checked;
				this.setState({ checked: !checked, data });
			}
		});
	};

	handleFileInput = (e) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileUpload(file, name);
	};
	handleCoverInput = (e) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileCover(file, name);
	};

	onDragEnd = (result) => {
		let from = result.source && result.source.index;
		let to = result.destination && result.destination.index;
		if ((to === 0 || to) && from !== to) {
			let { data } = this.state;

			data.splice(to, 0, data.splice(from, 1)[0]);
			this.setState({
				data: data.map((it) => it),
			});
		}
	};

	handleArrayChange = (e, { name, value }) => {
		let other_texts = this.state.other_texts;
		other_texts[name] = value;
		this.setState({ other_texts });
	};

	handleRemove = (index) => {
		let other_texts = this.state.other_texts;
		_.pullAt(other_texts, [index]);
		this.setState({ other_texts });
	};

	render() {
		let { t, shop } = this.props;
		let { keyword } = shop;
		let { loading, data, btnLoading, logo, cover_photo, name, link, indexes, alert, checked, preview, other_texts } = this.state;
		var mapObj = {
			ü: 'u',
			ş: 's',
			ğ: 'g',
			ç: 'c',
			ı: 'i',
			I: 'i',
			İ: 'i',
			i: 'i',
		};
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="list" />
								{t('brands')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="list" />
							{t('brands')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Button color="blue" style={{ position: 'absolute', right: '100px' }} onClick={this.openPreview} loading={this.state.loadingSaveButton}>
							{t('preview')}
						</Button>
						<Button style={{ position: 'absolute', right: '10px' }} onClick={this.handleSave} loading={this.state.loadingSaveButton}>
							{t(this.state.saveButton)}
						</Button>
					</Breadcrumb>
				}
			>
				<Modal id={'ModalPreview-' + preview} open={this.state.openPreview} onClose={this.closePreview}>
					<Header>
						{t('preview')}
						<Icon style={{ float: 'right', cursor: 'pointer' }} onClick={this.changePreview} name="mobile" />
						<Icon style={{ float: 'right', cursor: 'pointer' }} onClick={this.changePreview} name="desktop" />
					</Header>

					<Modal.Content
						style={{
							background: 'url(https://cdnydm.com/permedia/DMwYNk7qj03-BkIVw0yTUg.png?size=540x981)',
							backgroundColor: '#e5ddd5',
							// height: '500px',
						}}
					>
						<div className={`brand-menu-table home`}>
							<div className="brands">
								<div className="brand-table">
									{_.map(
										_.filter(data, (o) => o.active),
										(it, i) => {
											return (
												<div className={'brand-card-' + preview} key={i}>
													{it.logo ? <img src={`${it.logo}`} alt={it.name}></img> : <p>{it.name}</p>}
												</div>
											);
										}
									)}
								</div>
							</div>
						</div>
					</Modal.Content>
					<Modal.Actions>
						<div style={{ display: 'flex' }}>
							<div style={{ marginLeft: 'auto' }}>
								<Button color="red" onClick={this.closePreview}>
									<Icon name="remove" /> {t('close')}
								</Button>
							</div>
						</div>
					</Modal.Actions>
				</Modal>
				<Modal open={this.state.openModal} onClose={this.closeModal}>
					<Header>
						<Icon name="exclamation triangle" />
					</Header>
					<Modal.Content>
						<a style={{ color: 'black', fontSize: '20px' }}>{alert}</a>
					</Modal.Content>
					<Modal.Actions>
						<div style={{ display: 'flex' }}>
							<div style={{ marginLeft: 'auto' }}>
								<Button color="red" onClick={this.closeModal}>
									<Icon name="remove" /> {t('close')}
								</Button>
							</div>
						</div>
					</Modal.Actions>
				</Modal>
				<Modal open={this.state.openRemoveBrandModal} onClose={this.closeModal}>
					<Modal.Header>
						<Icon name="trash" color="red" />
						<span>{t('deletedBrand')}</span>
					</Modal.Header>
					<Modal.Content>
						<Message warning>
							<Message.Header>{t('areusurefordeletebrand')}</Message.Header>
							<span>{t('thisactioncannotbeundone')}</span>
						</Message>
					</Modal.Content>
					<Modal.Actions>
						<div>
							<Button icon="remove" color="red" content={t('no')} onClick={() => this.closeModal()} />
							<Button icon="checkmark" color="green" content={t('yes')} onClick={() => this.deleteBrandData()} />
						</div>
					</Modal.Actions>
				</Modal>
				<Page.Wrap>
					<div style={{ display: 'flex', maxHeight: '93%' }} className="ui segment">
						<Table style={{ width: '30%' }}>
							<Table.Header>
								<Table.HeaderCell>
									<Button
										color="blue"
										onClick={() => this.newBrand(this.baseState)}
										style={{ marginRight: '35px' }}
										loading={this.state.loadingaddbrand}
									>
										{t('add')}
									</Button>{' '}
									{t('brands')}
								</Table.HeaderCell>
							</Table.Header>
							<DragDropContext onDragEnd={this.onDragEnd}>
								<Droppable droppableId="actions" type="list">
									{(provided) => {
										return (
											<div
												className="files-list"
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={{ overflowY: 'auto', maxHeight: '100%', height: '600px', overflowX: 'hidden' }}
											>
												<Table.Body>
													{_.map(
														_.filter(data, (o) => o.name),
														(it, i) => {
															return (
																<Draggable draggableId={it.name} index={i} key={it.name}>
																	{/*key={it['name']} draggableId={it['name']} index={i} */}
																	{(provided) => {
																		return (
																			<Table.Row>
																				<div
																					ref={provided.innerRef}
																					className="action-body margin-yt"
																					{...provided.draggableProps}
																				>
																					<Button
																						color={it.active ? 'green' : 'red'}
																						onClick={() => this.handleEdit(it, i)}
																					>
																						{t('Edit')}
																					</Button>
																					<div {...provided.dragHandleProps}>
																						<Icon name="arrows alternate vertical" size="large"></Icon>
																					</div>
																					<div alt="img" size="medium" style={{ marginLeft: '10px' }}>
																						{it.name}
																					</div>
																					<a
																						target={'_blank'}
																						href={
																							`https://${keyword}.whatsapp-shop.com/tr?modal=1&brand=` +
																							it.name
																								.replace(/ü|ş|ğ|ç|ı|i̇|I|İ/gi, function (matched) {
																									return mapObj[matched];
																								})
																								.toLowerCase()
																						}
																						style={{ paddingLeft: '15px' }}
																					>
																						<Icon name="external alternate" />
																					</a>
																					{/* <Icon
																						style={{ textAlign: 'right', cursor: 'pointer' }}
																						name="times"
																						size="large"
																						onClick={() => this.handleDelete(it, i)}
																					></Icon> */}
																					<a
																						style={{ marginLeft: '10px' }}
																						href={it.name}
																						target="_blank"
																						rel="noopener noreferrer"
																					></a>
																					<div
																						style={{ cursor: 'pointer' }}
																						onClick={() => this.removeBrandPreparation(it, i)}
																					>
																						<Icon color="red" name="trash" />
																					</div>
																				</div>
																				{/* </Table.Cell> */}
																			</Table.Row>
																		);
																	}}
																</Draggable>
															);
														}
													)}
													{provided.placeholder}
												</Table.Body>
											</div>
										);
									}}
								</Droppable>
							</DragDropContext>
							{/* </Segment> */}
						</Table>
						<div className="ke-info" style={{ heigth: '600px', marginLeft: '15px', width: '70%' }}>
							<Form className="panel-extra">
								<Segment>
									<div style={{ marginLeft: 'auto', marginBottom: '15px' }}>{/* position: 'fixed', right: '10px' */}</div>

									<Form.Input required fluid label={t('brandName')} name="name" value={name} onChange={this.handleChange}></Form.Input>
									<Form.Input required fluid label={t('logo')} name="logo" value={logo} onChange={this.handleChange}>
										<Popup
											on="click"
											hideOnScroll
											position="bottom left"
											content={<img width={180} src={logo} alt={name}></img>}
											trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
										></Popup>
										<input />
										<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
											<label htmlFor="logo">
												<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
											</label>
											<Input
												id="logo"
												type="file"
												name={logo}
												accept="image/png"
												style={{ display: 'none' }}
												onChange={(e) => this.handleFileInput(e)}
											/>
										</div>
									</Form.Input>
									<Form.Input fluid label={'Kapak Fotoğrafı'} name="cover_photo" value={cover_photo} onChange={this.handleChange}>
										<Popup
											on="click"
											hideOnScroll
											position="bottom left"
											content={<img width={180} src={cover_photo} alt={name}></img>}
											trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
										></Popup>
										<input />
										<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
											<label htmlFor="cover_photo">
												<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
											</label>
											<Input
												id="cover_photo"
												type="file"
												name={cover_photo}
												accept="image/png"
												style={{ display: 'none' }}
												onChange={(e) => this.handleCoverInput(e)}
											/>
										</div>
									</Form.Input>
									<Form.Input fluid label={'Telegram ' + t('link')} name="link" value={link} onChange={this.handleChange}></Form.Input>
									<Form.Field>
										<label>Config</label>
										{/* <Form.Checkbox label="Yeni ürün mü?" name="base" onChange={'this.toggle'} checked={'base'}></Form.Checkbox> */}
										<Form.Checkbox label="Aktif" name="checked" onChange={this.updateCheck} checked={checked}></Form.Checkbox>
									</Form.Field>
									<label style={{ display: 'block' }}>{t('other_options')}</label>
									<div style={{ display: 'inline-grid' }}>
										{_.map(other_texts, (it, i) => (
											<Input
												key={i}
												style={{ marginTop: '5px', marginRight: '10px' }}
												name={i}
												value={it}
												onChange={this.handleArrayChange}
											>
												<input />
												<Form.Button icon type="button" onClick={() => this.handleRemove(i)} style={{ marginLeft: '10px' }}>
													<Icon name="trash"></Icon>
												</Form.Button>
											</Input>
										))}
									</div>
									<Button
										icon
										size="tiny"
										type="button"
										style={{ marginTop: '5px', display: 'block' }}
										onClick={() => {
											if (!other_texts) {
												other_texts = [];
											}
											other_texts.push('');
											data[indexes].other_texts = other_texts;
											this.setState({ other_texts });
										}}
									>
										<Icon name="plus"></Icon>
									</Button>
								</Segment>
							</Form>
						</div>
					</div>
				</Page.Wrap>
			</Page>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Folder));
