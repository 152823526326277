import React, { Component, Suspense } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';
import { get, post } from '../../util/api';
import { Header, Segment, Breadcrumb, Icon, Checkbox, Form, Button, Modal, Image, Dropdown } from 'semantic-ui-react';
//import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import ReactImageMagnify from 'react-image-magnify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

// eslint-disable-next-line
import styled from 'styled-components/macro';

function createHash(obj) {
	if (obj && !obj._hash) obj._hash = _.deburr(obj.name).toLowerCase().trim();
}

function urltimer() {
	return '?t=' + Math.floor(new Date().getTime() / 60000);
}

const Editor = React.lazy(() => import('./editor'));

function Magnifier(props) {
	let { barcode_img, selected_zoom_img } = props;

	let data = React.useMemo(() => {
		let sizes = (barcode_img && barcode_img.image.__probe) || { width: 3000, height: 3000 };
		let ratio = sizes.width > 3000 ? 3 : 1;
		return {
			smallImage: {
				alt: 'barcode',
				src: selected_zoom_img.replace('wg.dialogtab.com/', 'wg.dialogtab.com/proxy/insecure/q:40/plain/local:///') + urltimer(),
				width: 400 * (sizes.width / sizes.height || 1.5),
				height: 400,
			},
			largeImage: {
				src: selected_zoom_img.replace('wg.dialogtab.com/', 'wg.dialogtab.com/proxy/insecure/q:40/plain/local:///') + urltimer(),
				width: sizes.width / ratio,
				height: sizes.height / ratio,
			},
			enlargedImageContainerDimensions: {
				width: 400,
				height: 400,
			},
		};
	}, [barcode_img, selected_zoom_img]);

	return <ReactImageMagnify {...data} />;
}

class Folder extends Component {
	state = {
		exportdata: {},
		loading: true,
		wait: false,
	};

	editor = React.createRef();

	async getData() {
		let { file, subfolder } = this.props.match.params;
		let data = await get('/shop/schema/list');
		let kolaj = await post('/shop/kolaj/get/' + file, {});
		let result = kolaj.data.result;
		let colors = await post('/shop/kolaj/listColor', {
			subfolder_id: subfolder,
			kolaj_name: (result && result.data && result.data.kolaj_name) || '',
			code: (result && result.data && result.data.kolaj_editor && result.data.kolaj_editor.text_data && result.data.kolaj_editor.text_data.code) || '',
		});
		let otherColors = _.filter(colors.data.result, (it) => it.id !== file);
		if (!result) {
			return this.setState({ loading: false, error: true });
		}
		let selected_images =
			_.map(result.data.images, (it) => {
				let name = it && it.split('/');
				name = name && name[name.length - 1];
				return { path: it, name, checked: true };
			}) || result.data.selected_images;
		let kolaj_config = result.data.kolaj_editor && result.data.kolaj_editor.text_data;
		let template_id = result.data.kolaj_editor && result.data.kolaj_editor.template;
		let template_data = data.result;
		let selectedTemplate = (template_id && _.find(template_data, { id: template_id })) || data.result[0];
		let schemas_filtered = _.orderBy(
			_.filter(template_data, (it) => it === selectedTemplate || (it.data && !it.data.hidden)),
			(it) => it.data && (it.data.default || it.data.force)
		);

		let template_dropdown = _.map(schemas_filtered, (it) => ({ text: it.data.name, value: it.id }));

		let refined_images = result.data.refined_images;
		let barcode_img = refined_images && _.find(refined_images, { has_barcode: true });
		let selected_zoom_img;
		if (barcode_img) {
			selected_zoom_img = barcode_img.image.__image;
		} else {
			selected_zoom_img = _.last(result.data.images);
		}
		let zoom_images = _.map(result.data.images, (it) => ({
			text: it.replace(/.*_/, ''),
			value: encodeURI(it),
		}));
		if (data.brands) _.each(data.brands, createHash);
		this.setState({
			data: result,
			brands: data.brands,
			selected_images,
			loading: false,
			folder_data: kolaj.data.result.folder,
			subfolder_data: kolaj.data.result.subfolder,
			kolaj_config: kolaj_config || { price: '0' },
			refined_images,
			barcode_img,
			selected_zoom_img,
			template_data,
			template_dropdown,
			selectedTemplateId: selectedTemplate.id,
			selectedTemplate: selectedTemplate,
			zoom_images,
			otherColors,
		});
	}
	componentDidMount() {
		this.getData();
	}
	handleChangeArray(i) {
		let { selected_images } = this.state;
		this.setState({
			selected_images: selected_images.map((it, _i) => {
				if (i === _i) it.checked = !it.checked;
				return it;
			}),
		});
	}
	handleChangeJSON(obj) {
		this.setState({ wait: true });
		let { selectedTemplate } = this.state;

		if (!obj.error) {
			let temp = {};
			_.assignIn(temp, selectedTemplate, { id: (selectedTemplate.id || 0) + 1, data: obj.jsObject });
			this.setState({ temp_template: temp });
		}
		setTimeout(() => {
			this.setState({ wait: false });
		}, 1000);
	}
	handleUpdateJson() {
		let { temp_template } = this.state;
		if (temp_template) this.setState({ selectedTemplate: temp_template, selectedTemplateId: temp_template.id });
	}
	onDragEnd = (result) => {
		//console.log(result);
		let from = result.source && result.source.index;
		let to = result.destination && result.destination.index;
		if ((to === 0 || to) && from !== to) {
			let { selected_images } = this.state;
			selected_images.splice(to, 0, selected_images.splice(from, 1)[0]);
			this.setState({
				selected_images: selected_images.map((it) => it),
			});
		}
		//if ((to === 0 || to) && from !== to) this.props.helper.move(from, to);
	};

	handleChange = (e, { name, value }) => {
		let { kolaj_config } = this.state;
		kolaj_config[name] = value;
		this.setState({ kolaj_config });
	};
	async saveData(data, publish) {
		let kolaj_editor = this.editor.current && this.editor.current.save();
		if (!kolaj_editor) return;
		this.setState({ btnLoading: true });
		let body = { id: data.id, data: { kolaj_editor, selected_images: this.state.selected_images } };
		if (publish) {
			body.status = 'done';
		}
		console.log('body ', body);
		await post('/shop/kolaj/save', body).then(() => {
			if (!publish) this.setState({ btnLoading: false });
		});
		if (publish) {
			await get(`/shop/kolaj/final/${data.id}`).then((response) => {
				let exportdata = response.result.data.export;
				this.setState({ openExportModal: true, btnLoading: false, exportdata });
			});
		}
	}

	async exportData(data) {
		this.setState({ modalButton: true });
		let body = { id: data.id, data: {}, status: 'verified' };
		await post('/shop/kolaj/save', body).then(() => {
			this.setState({ openExportModal: false, modalButton: false });
		});
	}
	handleChangeTemplate = (e, { name, value }) => {
		let { template_data, selectedTemplate } = this.state;
		selectedTemplate = _.find(template_data, { id: value });
		this.setState({ [name]: value, selectedTemplate, selectedTemplateId: value });
	};
	render() {
		const { t } = this.props;
		let {
			loading,
			data,
			folder_data,
			subfolder_data,
			selected_images,
			kolaj_config,
			btnLoading,
			//template_data,
			template_dropdown,
			selectedTemplate,
			selectedTemplateId,
			openExportModal,
			exportdata,
			selected_zoom_img,
			barcode_img,
			refined_images,
			zoom_images,
			error,
			brands,
			otherColors,
		} = this.state;
		let { folder, subfolder } = this.props.match.params;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		if (error) {
			return <Redirect to="/whatsapp-shop" />;
		}
		let kolaj_editor = data.data.kolaj_editor;
		// console.log('selectedTemplateId', this.state);
		let first_result = data.data.first_result;
		let isAll = window.location.search && /all=true/.test(window.location.search);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/whatsapp-shop/${folder}`}>
							{folder_data.data.name}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/whatsapp-shop/${folder}/${subfolder}`}>
							{subfolder_data.data.name}
						</Breadcrumb.Section>

						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>{data.data.kolaj_name}</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
			>
				<div className="konva-editor-wrap">
					<div className="ke-info">
						<Segment>
							<div style={{ display: 'flex' }}>
								<div>
									<Button basic labelPosition="left" color="grey" icon as={Link} to={`/whatsapp-shop/${folder}/${isAll ? 'all' : subfolder}`}>
										<Icon name="arrow left"></Icon>
										{t('back')}
									</Button>
								</div>
								<Header style={{ margin: '7px 7px 0px 10px', maxWidth: '300px' }}>
									<p style={{ wordBreak: 'break-word' }}>{data.data.kolaj_name}</p>
								</Header>
								<div style={{ marginLeft: 'auto' }}>
									<Button loading={btnLoading} primary onClick={() => this.saveData(data, false)}>
										Kaydet
									</Button>
									<Button loading={btnLoading} positive onClick={() => this.saveData(data, true)}>
										Onaya Gönder
									</Button>
								</div>
							</div>
						</Segment>
						<Segment>
							<Header>Resimler</Header>

							<DragDropContext onDragEnd={this.onDragEnd}>
								<Droppable droppableId="actions" type="list">
									{(provided) => {
										return (
											<div className="files-list" {...provided.droppableProps} ref={provided.innerRef}>
												{_.map(selected_images, (it, i) => {
													return (
														<Draggable key={it.name} draggableId={it.name} index={i}>
															{(provided) => {
																return (
																	<div ref={provided.innerRef} className="action-body" {...provided.draggableProps}>
																		<div {...provided.dragHandleProps}>
																			<Icon name="arrows alternate vertical" size="large"></Icon>
																		</div>
																		<Checkbox checked={it.checked} onChange={() => this.handleChangeArray(i)}></Checkbox>
																		<div alt="img" size="medium" style={{ marginLeft: '10px' }}>
																			{it.name}
																		</div>
																		<a
																			style={{ marginLeft: '10px' }}
																			href={it.path}
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			<Icon name="external"></Icon>
																		</a>
																	</div>
																);
															}}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</div>
										);
									}}
								</Droppable>
							</DragDropContext>
						</Segment>
						<Segment>
							<Header>Panel</Header>
							<Form className="panel-extra">
								<Form.Field inline>
									<label>Şablon</label>
									<Dropdown options={template_dropdown} selection value={selectedTemplateId} onChange={this.handleChangeTemplate} />
								</Form.Field>
								{_.map(selectedTemplate.data.panel, (it) => {
									let s_value = first_result && first_result[it.keyword];

									if (it.type && it.type.search('_dropdown') != -1 && selectedTemplate.data[it.type]) {
										if (!selectedTemplate[it.type])
											selectedTemplate[it.type] = _.map(selectedTemplate.data[it.type], (it) => ({
												value: it.range,
												text: it.range,
											}));
										return (
											<div className="inline field" key={it.keyword}>
												<label>{it.title}</label>
												<Dropdown
													search
													placeholder={it.default}
													selection
													name={it.keyword}
													value={kolaj_config['' + it.keyword]}
													onChange={this.handleChange}
													options={selectedTemplate[it.type]}
												/>
											</div>
										);
									} else if (it.type === 'brands' && selectedTemplate.data.brands) {
										if (!selectedTemplate.__brands) {
											/* selectedTemplate.__brands = _.map(selectedTemplate.data.brands, (it) => ({
												value: it,
												text: it,
											})); */
										}
										selectedTemplate.__brands = _.map(brands, (it) => ({
											value: it.name,
											text: it.name,
										}));
										return (
											<div className="inline field" key={it.keyword}>
												<label>{it.title}</label>
												<Dropdown
													search
													placeholder={it.default}
													selection
													name={it.keyword}
													value={kolaj_config['' + it.keyword]}
													onChange={this.handleChange}
													options={selectedTemplate.__brands}
												/>
												{first_result && kolaj_config['' + it.keyword] !== s_value ? (
													<Icon
														name="pencil alternate"
														style={{ marginLeft: '22px', marginTop: 'auto', marginBottom: 'auto' }}
													></Icon>
												) : (
													''
												)}
											</div>
										);
									} else
										return (
											<div className="inline field" style={{ display: 'flex' }}>
												<Form.Input
													inline
													key={it.keyword}
													label={it.title}
													placeholder={it.default}
													name={it.keyword}
													value={kolaj_config['' + it.keyword]}
													onChange={this.handleChange}
												/>
												{first_result && kolaj_config['' + it.keyword] !== s_value ? (
													<Icon
														name="pencil alternate"
														style={{ marginLeft: '10px', marginTop: 'auto', marginBottom: 'auto' }}
													></Icon>
												) : (
													''
												)}
											</div>
										);
								})}
							</Form>
						</Segment>
						<div
							css={`
								padding: 0 0 5px 0 !important;
								> div {
									z-index: 1;
									margin: 0 auto;
									> img {
										box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
										border-radius: 0.28571429rem;
										border: 1px solid rgba(34, 36, 38, 0.15);
									}
								}
							`}
						>
							<Dropdown
								selection
								fluid
								options={zoom_images}
								value={selected_zoom_img}
								onChange={(e, { value }) => {
									let barcode_img = refined_images && _.find(refined_images, (it) => it.image && it.image.__image === value);
									this.setState({ barcode_img, selected_zoom_img: value });
								}}
							></Dropdown>
							<Magnifier barcode_img={barcode_img} selected_zoom_img={selected_zoom_img} />
						</div>
						{otherColors && otherColors.length > 0 ? (
							<Segment>
								<Header>Diğer Renkler</Header>
								<Image.Group size="small">
									{_.map(otherColors, (it) => (
										<a href={`/whatsapp-shop/${it.folder.id}/${it.subfolder_id}/${it.id}`}>
											<Image src={(it.data.export && it.data.export.url) || it.data.images[0]} />
										</a>
									))}
								</Image.Group>
							</Segment>
						) : null}
					</div>
					<div className="ke-editor">
						<Suspense fallback={<div>Yükleniyor...</div>}>
							<Editor
								editor={this.editor}
								kolaj_config={kolaj_config}
								images={selected_images}
								kolaj_editor={kolaj_editor}
								template={selectedTemplate}
								brands={this.state.brands}
							/>
						</Suspense>
					</div>
					{/debug=true/.test(window.location.search) ? (
						<div>
							<Button disabled={this.state.wait} positive onClick={() => this.handleUpdateJson()}>
								Update
							</Button>
							<JSONInput
								id="a_unique_id"
								placeholder={selectedTemplate.data}
								locale={locale}
								height="720px"
								onChange={(e) => this.handleChangeJSON(e)}
							/>
						</div>
					) : (
						''
					)}
				</div>
				<Modal
					className="export"
					style={{ width: 'auto' }}
					open={openExportModal}
					size="large"
					onClose={() => this.setState({ openExportModal: false })}
				>
					<Modal.Content style={{ padding: '4px' }}>
						<a href={exportdata.url} target="_blank" rel="noopener noreferrer">
							<Image centered src={exportdata.url} style={{ height: '85vh', width: 'auto' }}></Image>
						</a>
					</Modal.Content>
					<Modal.Actions style={{ display: 'flex' }}>
						<h4>{t('imageVerify')}</h4>
						<Button style={{ marginLeft: 'auto' }} negative onClick={() => this.setState({ openExportModal: false })}>
							{t('no')}
						</Button>
						<Button positive loading={this.state.modalButton} onClick={() => this.exportData(data)}>
							{t('yes')}
						</Button>
					</Modal.Actions>
				</Modal>
			</Page>
		);
	}
}

export default withTranslation()(Folder);
