import React, { PureComponent } from 'react';
import { Switch, Route, Redirect, NavLink, Link } from 'react-router-dom';
import { Menu, Icon, Breadcrumb } from 'semantic-ui-react';
import Page from '../../components/Page';
import VideoTable from './videoTable'
class Reels extends PureComponent {
	render() {
		let { t, i18n } = this.props;
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/reels`}>
							<Icon name="youtube" />
							Reels
						</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap style={{ marginLeft: '0px !important' }}>
                <Route path={'/reels'} exact component={VideoTable}></Route>
                </Page.Wrap>
			</Page>
		);
	}
}
export default Reels;
