import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Segment, Icon, Table, Button, Message, Checkbox, Confirm, Modal, Form } from 'semantic-ui-react';
import { getWG, postWg, toWapi, post } from '../../util/api';
import _ from 'lodash';
import moment from 'moment';

class WTable extends Component {
	state = {
		loading: true,
		selected_array: [],
		selectedHour: 'all',
		oneDay: false,
		onlydiscount: false,
	};
	async componentDidMount() {
		let { subfolder } = this.props.match.params;
		let { shop } = this.props;
		let { server_config } = shop;
		let group_key = (server_config && server_config.group_key) || 'created_at';
		let bc_order_by_name = server_config.bc_order_by_name;
		await getWG(`/api/${shop.keyword}/log`).then((data) => {
			this.setState({ log: data.result });
		});
		await post('/shop/kolaj/list', { subfolder_id: subfolder, picks: ['export', 'kolaj_name', 'kolaj_editor.text_data'] }).then((data) => {
			let _data = _.orderBy(data.data.result, [group_key], ['desc']);
			_data = _.filter(_data, (it) => moment(it.updated_at).year() === moment().year());
			let result = data.data.result;
			let verified_table = _.filter(_data, (it) => it.status === 'verified' && it.state === 'active' && it.data.export);
			let discount_table = _.filter(
				verified_table,
				(it) => it.data.kolaj_editor.text_data.discountprice || it.data.kolaj_editor.text_data.discountedprice
			);
			//console.log('verified_table', verified_table, discount_table);
			if (bc_order_by_name) verified_table = _.orderBy(verified_table, ['data.kolaj_name'], ['asc']);
			//console.log('verified_table', verified_table);
			let g_verified_table = _.orderBy(
				_.groupBy(verified_table, (it) => moment(it[group_key]).format('DD-MM-YYYY')),
				(o) => moment(o[0][group_key]),
				['desc']
			);
			let gd_verified_table = _.orderBy(
				_.groupBy(discount_table, (it) => moment(it[group_key]).format('DD-MM-YYYY')),
				(o) => moment(o[0][group_key]),
				['desc']
			);
			if (result && result.length) {
				this.setState({
					data: _data,
					verified_table,
					g_verified_table,
					subfolder_data: result[0].subfolder,
					folder_data: result[0].folder,
					loadRefresh: false,
					discount_table,
					gd_verified_table,
				});
			} else {
				this.setState({ loading: false, data: [] });
			}
		});
		await this.getListPhone();
	}
	async getListPhone() {
		//console.log(this.props);
		console.log('test');
		let { shop } = this.props;
		let { whatsapp } = shop.server_config;
		toWapi(`/api/${whatsapp.product_id}/listPhones`, whatsapp.key)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				let activePhones = _.filter(data, (it) => it.status === 'active');
				let warningPhones = _.filter(data, (it) => it.status !== 'active');
				this.setState({ phones: data, activePhones, warningPhones, loading: false, btnLoading: false });
			})
			.catch((err) => {
				console.error(err);
			});
	}
	async wait(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}
	async handleSubmit() {
		this.setState({ sendBtnLoad: true });
		let { selected_array, verified_table, subfolder_data, folder_data, selectedHour, log, discount_table } = this.state;
		let { shop } = this.props;
		let { server_config } = shop;
		let group_key = (server_config && server_config.group_key) || 'created_at';
		let group_by_name = (server_config && server_config.group_by_name) || false;
		let send_discount_products_brand = server_config.send_discount_products_brand || false;
		let send_discount_products_brand_key = server_config.send_discount_products_brand_key || 'outlet';
		let images_list = [],
			dis_images_list = [];
		if (selected_array.length === 1 && selectedHour === 'onlyAfter') {
			let selected_day = selected_array[0];
			let status = _.find(log, (lit) => lit.brand === subfolder_data.data.name && lit.day === selected_day);
			let status_updateDate = status && status.update_date ? moment(status.update_date) : '';
			images_list = _.filter(
				verified_table,
				(it) => _.includes(selected_array, moment(it[group_key]).format('DD-MM')) && moment(it[group_key]).isAfter(status_updateDate, 'm')
			);
			dis_images_list = _.filter(
				discount_table,
				(it) => _.includes(selected_array, moment(it[group_key]).format('DD-MM')) && moment(it[group_key]).isAfter(status_updateDate, 'm')
			);
		} else {
			images_list = _.filter(verified_table, (it) => _.includes(selected_array, moment(it[group_key]).format('DD-MM')));
			dis_images_list = _.filter(discount_table, (it) => _.includes(selected_array, moment(it[group_key]).format('DD-MM')));
		}
		if (group_by_name) {
			images_list = _.orderBy(images_list, (it) => it.data.kolaj_name, ['asc']);
			dis_images_list = _.orderBy(dis_images_list, (it) => it.data.kolaj_name, ['asc']);
		}
		let send_list = images_list && images_list.length && _.map(images_list, (it) => it.data.export.url);
		let dis_send_list = dis_images_list && dis_images_list.length && _.map(dis_images_list, (it) => it.data.export.url);

		/* console.log(
			'send_list,send_list',
			_.map(images_list, (i) => i.data.kolaj_name)
		); */
		/* console.log('send_discount_products_brand', send_discount_products_brand);
		console.log(discount_table, dis_images_list);
		console.log({
			send_discount_products_brand_key,
			dis_images_list,
			dis_send_list,
			discount_table,
			verified_table,
			images_list,
			images: send_list,
			firm: shop.keyword,
			brand: subfolder_data.data.name,
			days: selected_array,
		}); */
		/* return; */
		if (send_discount_products_brand && dis_images_list && dis_images_list.length) {
			await postWg('/api/send_kolaj', {
				images: dis_send_list,
				firm: shop.keyword,
				brand: send_discount_products_brand_key,
				days: selected_array,
				onlySaleGroup: true,
				sendAfter: this.state.selectedHour === 'all' ? false : true,
			}).then((data) => {
				if (data.data.success) {
					this.setState({
						open: false,
						cardType: 'green',
						cardMessage: 'Seçmiş olduğunuz klasör atılmaya başlamıştır.',
					});
					this.componentDidMount();
				} else {
					this.setState({ open: false, cardType: 'red', cardMessage: 'Bu klasör daha önce atılmıştır.' });
				}
			});
		}
		if (send_list && send_list.length > 24) {
			const batches = [];
			for (let i = 0; i < send_list.length; i += 25) {
				batches.push(send_list.slice(i, i + 25));
			}
			for (let i = 0; i < batches.length; i++) {
				const batch = batches[i];
				try {
					const data = await postWg('/api/send_kolaj', {
						images: batch,
						firm: shop.keyword,
						brand: subfolder_data.data.name,
						days: selected_array,
						sendAfter: this.state.selectedHour === 'all' ? false : true,
						batch: '_' + i,
					});

					if (data.data.success) {
						console.log(`Batch ${i + 1}/${batches.length} sent successfully`);
					} else {
						console.error(`Failed to send batch ${i + 1}/${batches.length}:`, data.data);
						// Hata durumunda ne yapılacağını buraya ekleyebilirsiniz
					}

					// Son batch değilse 1 saniye bekle
					if (i < batches.length - 1) {
						await this.wait(1000); // 1 saniye bekle
					}
				} catch (error) {
					console.error(`Error sending batch ${i + 1}/${batches.length}:`, error);
					// Hata durumunda ne yapılacağını buraya ekleyebilirsiniz
				}
			}
			this.setState({
				open: false,
				sendBtnLoad: false,
				cardType: 'green',
				cardMessage: 'Seçmiş olduğunuz klasör atılmaya başlamıştır.',
			});
			this.componentDidMount();
		} else {
			await postWg('/api/send_kolaj', {
				images: send_list,
				firm: shop.keyword,
				brand: subfolder_data.data.name,
				days: selected_array,
				sendAfter: this.state.selectedHour === 'all' ? false : true,
			}).then((data) => {
				if (data.data.success) {
					this.setState({
						open: false,
						cardType: 'green',
						cardMessage: 'Seçmiş olduğunuz klasör atılmaya başlamıştır.',
					});
					this.componentDidMount();
				} else {
					this.setState({ open: false, cardType: 'red', cardMessage: 'Bu klasör daha önce atılmıştır.' });
				}
			});
		}
	}
	handleChange(value) {
		let { selected_array } = this.state;
		let isExist = _.includes(selected_array, value);
		if (isExist) {
			_.pull(selected_array, value);
		} else {
			selected_array.push(value);
		}
		this.setState(selected_array);
	}
	TimeModal(selected_array, selectedHour, log) {
		let { subfolder } = this.props.match.params;
		let { subfolder_data, verified_table } = this.state;
		let selected_day = selected_array[0];
		let status = _.find(log, (lit) => lit.brand === subfolder_data.data.name && lit.day === selected_day);
		//console.log('hghhhh', log, selected_day, status, subfolder_data);
		let { shop } = this.props;
		let { server_config } = shop;
		let group_key = (server_config && server_config.group_key) || 'created_at';
		let status_updateDate = status && status.update_date ? moment(status.update_date).format('HH:mm') : '';
		let status_updateDate_2 = status && status.update_date ? moment(status.update_date) : '';
		let all_list = _.filter(verified_table, (it) => _.includes(selected_array, moment(it[group_key]).format('DD-MM')));
		let after_list = _.filter(
			verified_table,
			(it) => _.includes(selected_array, moment(it[group_key]).format('DD-MM')) && moment(it[group_key]).isAfter(status_updateDate_2, 'm')
		);

		if (status) {
			return (
				<Form style={{ marginTop: '10px' }}>
					<Form.Field>Gönderim Seçimi</Form.Field>
					<Form.Field>
						<Checkbox
							radio
							label={`Hepsini Gönder (${all_list.length})`}
							name="selectedHour"
							value="all"
							checked={selectedHour === 'all'}
							onChange={() => this.setState({ selectedHour: 'all', checkLengthList: all_list })}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							radio
							label={`${status_updateDate} Saatinden Sonrakileri Gönder (${after_list.length})`}
							name="checkboxRadioGroup"
							value="onlyAfter"
							checked={selectedHour === 'onlyAfter'}
							onChange={() => this.setState({ selectedHour: 'onlyAfter', checkLengthList: after_list })}
						/>
					</Form.Field>
				</Form>
			);
		}
	}
	render() {
		let {
			data,
			loading,
			subfolder_data,
			cardType,
			cardMessage,
			phones,
			activePhones,
			warningPhones,
			g_verified_table,
			gd_verified_table,
			selected_array,
			log,
			selectedHour,
			oneDay,
			onlydiscount,
		} = this.state;
		const { t, shop } = this.props;
		let { server_config } = shop;
		if (loading) {
			return (
				<Page title={'WhatsApp Group'} icon="whatsapp">
					<Page.Loader></Page.Loader>
				</Page>
			);
		}

		if (!data.length) {
			return <Redirect to="/wgroup" />;
		}
		let group_key = (server_config && server_config.group_key) || 'updated_at';
		let show_list = onlydiscount ? gd_verified_table : g_verified_table;
		return (
			<Page title={'WhatsApp Group'} icon="whatsapp">
				<Page.Wrap>
					<div className="wapi-state" style={{ display: 'flex' }}>
						<div className="but">
							<Button
								icon
								color="green"
								basic
								onClick={() => {
									this.setState({ btnLoading: true });
									this.componentDidMount();
								}}
							>
								<Icon name="refresh" loading={this.state.btnLoading} color="white"></Icon>
							</Button>
						</div>
						<div className="logo">
							<div class="wapi-logo" style={{ background: '#25D366' }}>
								<img src="/images/whatsapp.svg" alt="whatsapp" style={{ height: '36px', display: 'flex', padding: '6px' }}></img>
							</div>
						</div>
						<div className="info" style={{ width: '100%', display: 'flex' }}>
							<div className="active-part" style={{ display: 'flex', background: '#d4edda', lineHeight: '36px', color: '#155724' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="check" color="green" /> Aktif {(activePhones && activePhones.length) + ' / ' + (phones && phones.length)}
								</div>
							</div>
							{warningPhones && warningPhones.length ? (
								<div className="warning-part" style={{ display: 'flex', background: '#fff3cd', lineHeight: '36px', color: '#856404' }}>
									<div style={{ marginLeft: '10px', marginRight: '10px' }}>
										<Icon name="warning" color="brown" />{' '}
										{_.map(warningPhones, (it, i) => (
											<span>{it.number + '(' + it.id + '): ' + it.status + (i !== warningPhones.length - 1 ? ', ' : ' ')}</span>
										))}
									</div>
								</div>
							) : (
								''
							)}
							<div className="phonestat" style={{ display: 'flex', lineHeight: '36px', background: '#9696f378', color: 'darkblue' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="mobile alternate" color="darkblue" />{' '}
									<Link to="/wgroup/phones_status" style={{ color: 'darkblue' }}>
										Kuyruk Durumuna Git
									</Link>
								</div>
							</div>
						</div>
					</div>
					<Segment>
						{cardType && <Message color={cardType} content={cardMessage}></Message>}
						{this.state.btnLoading ? (
							<Page.Loader></Page.Loader>
						) : (
							<div>
								{server_config.onlydiscount ? (
									<div>
										{
											<Checkbox
												name="onlydiscount"
												label="Sadece İndilimli Ürünler"
												onChange={() => this.setState({ onlydiscount: !this.state.onlydiscount })}
												checked={this.state.onlydiscount}
											></Checkbox>
										}
									</div>
								) : null}
								<Table>
									<Table.Header>
										<Table.HeaderCell width={2}>{subfolder_data.data.name}</Table.HeaderCell>
										<Table.HeaderCell>Gün</Table.HeaderCell>
										<Table.HeaderCell>Resim Sayısı</Table.HeaderCell>
										<Table.HeaderCell width={3}>
											<Button positive onClick={() => this.setState({ open: true })}>
												Gönder
											</Button>
										</Table.HeaderCell>
									</Table.Header>
									<Table.Body>
										{_.map(show_list, (it, i) => {
											let status = _.find(
												log,
												(lit) =>
													lit.brand === subfolder_data.data.name &&
													lit.day === moment(it[0][group_key]).format('DD-MM') &&
													lit.year === moment(it[0][group_key]).year()
											);
											//console.log('status', status, moment(it[0].updated_at).format('DD-MM'));
											return (
												<Table.Row>
													<Table.Cell>
														<Checkbox onClick={() => this.handleChange(moment(it[0][group_key]).format('DD-MM'))}></Checkbox>
													</Table.Cell>
													<Table.Cell>{moment(it[0][group_key]).format('DD-MM')}</Table.Cell>
													<Table.Cell>{it.length}</Table.Cell>
													<Table.Cell>
														{(status &&
															status.status &&
															t(status.status) +
																(status.update_date ? ' (' + moment(status.update_date).format('DD/MM - HH:mm') + ')' : '')) ||
															'Gönderilmedi'}
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</div>
						)}
					</Segment>
					<Modal open={this.state.open}>
						<Modal.Content>
							<Modal.Description>Kolaj gönderim işlemine başlamak istediğinize emin misiniz?</Modal.Description>
							{server_config.sendOnlyAfter && selected_array.length === 1 ? this.TimeModal(selected_array, selectedHour, log) : ''}
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={() => this.setState({ open: false, oneDay: false, selectedHour: 'all' })}>{t('no')}</Button>
							<Button
								disabled={this.state.selectedHour !== 'all' && !this.state.checkLengthList.length}
								onClick={() => this.handleSubmit()}
								positive
								loading={this.state.sendBtnLoad}
							>
								{t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
				</Page.Wrap>
			</Page>
		);
	}
}

export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(WTable));
