import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { get, post } from '../../util/api';
import { Header, Segment, Breadcrumb, Icon, Button, Image, Container } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

class Folder extends Component {
	state = { loading: true, ind: 0 };
	componentDidMount() {
		this.getData();
	}
	componentDidUpdate(oldState) {
		let { done_kolaj } = this.state;
		console.log('do', done_kolaj);
		if (!_.isEqual(this.state['ind'], oldState['ind']) && done_kolaj[this.state.ind + 1]) {
			get(`/shop/kolaj/final/${done_kolaj[this.state.ind + 1].id}`).then((response) => {
				let exportdata = response.result;
				done_kolaj[this.state.ind + 1] = exportdata;
			});
		}
	}
	async getData() {
		let { folder } = this.props.match.params;
		await post(`/shop/folder/get/${folder}/all`, {}).then((data) => {
			console.log('data', data);
			let kolaj = data.data.result.kolaj;
			let done_kolaj = _.filter(kolaj, { status: 'done' });
			if (done_kolaj && done_kolaj[0])
				get(`/shop/kolaj/final/${done_kolaj[0].id}`).then((response) => {
					let exportdata = response.result;
					done_kolaj[0] = exportdata;
				});
			this.setState({
				done_kolaj,
				data: data.data.result.kolaj,
				loading: false,
				subfolder_data: data.data.result.subfolder[0],
				folder_data: data.data.result.folder[0],
			});
		});
	}
	async exportData(data) {
		let body = { id: data.id, data: {}, status: 'verified' };
		await post('/shop/kolaj/save', body).then(() => {
			this.setState({ ind: this.state.ind + 1 });
		});
	}
	render() {
		const { t } = this.props;
		let { folder } = this.props.match.params;
		let { done_kolaj, loading, ind } = this.state;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		let selected_kolaj = done_kolaj && done_kolaj[ind];
		console.log('selected_kolaj', selected_kolaj);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>

						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>Verify</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
			>
				<Container>
					<Page.Wrap>
						<Button basic labelPosition="left" color="grey" icon as={Link} to={`/whatsapp-shop`}>
							<Icon name="arrow left"></Icon>
							{t('back')}
						</Button>
						<Segment>
							<Header textAlign="center">{selected_kolaj.data.kolaj_name}</Header>
							<Image src={selected_kolaj.data.export.url} size="large" centered></Image>
							<div style={{ marginTop: '20px', textAlign: 'center' }}>
								<Button color="yellow" disabled={!done_kolaj[ind + 1]} onClick={() => this.setState({ ind: ind + 1 })}>
									Geç
								</Button>
								<Button color="blue" as={Link} to={'/whatsapp-shop/' + folder + '/' + selected_kolaj.subfolder_id + '/' + selected_kolaj.id}>
									Düzenle
								</Button>
								<Button color="green" onClick={() => this.exportData(selected_kolaj)}>
									Onayla
								</Button>
							</div>
						</Segment>
					</Page.Wrap>
				</Container>
			</Page>
		);
	}
}

export default withTranslation()(Folder);
