import React, { Component } from 'react';
import Page from '../../components/Page';
import { post, log, toWapi, get } from '../../util/api';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
	Segment,
	Table,
	Breadcrumb,
	Icon,
	Button,
	Popup,
	Grid,
	Header,
	Container,
	GridColumn,
	Form,
	Input,
	Modal,
	Dropdown,
	Checkbox,
	Divider,
	Image,
	Label,
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
class Video_table extends Component {
	constructor(props) {
		super(props);
		this.state = {
			watchId: '',
			VideoId: '',
			productid: '',
			videoName: '',
			modalBrandName: '',
			modalVideoId: '',
			modalVideoName: '',
			videoToBeAdded: [],
			newVideoList: [],
			allVideos: [],
			changeList: false,
			openModal: false,
			modalData: [],
			selectVideoId: '',
			dropDownOptions: '',
			youtubePlayList: '',
			googleKey: '',
			writing: false,
		};
	}
	async componentDidMount() {
		this.getAllVideos();
		let { shop } = this.props;
		let { dropDownOptions } = this.state;
		await post('/shop/brand/list', { pid: shop.pid }).then((data) => {
			let result = data && data.data && data.data.result && data.data.result.data;
			if (result && result.length > 0) {
				let element2 = {},
					indexes;
				result.forEach((element, i) => {
					element2 = element;
					indexes = i;
					element2.indexes = indexes;
				});
				if (result.length > 0) {
					this.setState({
						link: result[0].link,
						name: result[0].name,
						logo: result[0].logo,
						cover_photo: result[0].cover_photo,
						indexes: result[0].indexes,
						checked: result[0].active,
						other_texts: result[0].other_texts,
					});
				}
				let filtered_data = _.filter(result, (it) => it.name);
				// this.setState({ data: filtered_data, loading: false });
				let liste = _.map(filtered_data, (it, i) => ({
					text: it.name.toLowerCase(),
					value: it.name.toLowerCase(),
					key: it.name.toLowerCase(),
				}));
				this.setState({ dropDownOptions: liste });
			}
		});
	}
	async getAllVideos() {
		let { shop } = this.props;
		await post('/shop/reels/list', { pid: shop.pid }).then((data) => {
			let res = data && data.data && data.data.result && data.data.result.data;
			this.setState({ allVideos: res });
		});
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	saveVideo = async () => {
		let { VideoId, productid, videoName, allVideos } = this.state;
		let holdAllVideos = allVideos && allVideos.length > 1 ? allVideos.length - 1 : 0;
		let videoLength = holdAllVideos;
		let mainIdDef = allVideos && allVideos.length > 0 ? allVideos[videoLength].mainId + 1 : 0;
		let cloneAllVideos = allVideos && allVideos.length > 0 ? allVideos : [];
		let loadVideoObject = {
			id: VideoId,
			mainId: mainIdDef,
			name: videoName,
			productBrand: `?brand=${productid}`,
		};

		cloneAllVideos.push(loadVideoObject);
		let data = cloneAllVideos;
		await post('/shop/reels/upsert', { data })
			.then((data) => {
				this.getAllVideos();
			})
			.catch((err) => console.log('err', err));
		this.setState({ VideoId: '', videoName: '', productid: '' });
	};
	saveModalVideo = async () => {
		let { modalBrandName, modalVideoId, modalVideoName, allVideos, selectVideoId } = this.state;
		let degisecekDataIndex, degisecekData, mainId;
		_.map(allVideos, (it, i) => {
			if (it.id === selectVideoId) {
				degisecekDataIndex = i;
				degisecekData = it;
				mainId = it.mainId;
			}
		});
		let changingVideoData = {
			id: modalVideoId,
			mainId: mainId,
			name: modalVideoName,
			productBrand: `?brand=${modalBrandName}`,
		};
		let data = changingVideoData;
		setTimeout(async () => {
			allVideos[degisecekDataIndex] = changingVideoData;
			let data = allVideos;
			await post('/shop/reels/upsert', { data })
				.then((data) => {
					this.getAllVideos();
				})
				.catch((err) => {
					console.log('tryCatch Error', err);
				});
			this.setState({ openModal: false });
		}, 500);
	};
	onDragEnd = (result) => {
		let from = result.source && result.source.index;
		let to = result.destination && result.destination.index;
		let { allVideos } = this.state;
		if ((to === 0 || to) && from !== to) {
			let data = allVideos;
			data.splice(to, 0, data.splice(from, 1)[0]);
			this.setState({
				newVideoList: data.map((it) => it),
			});
		}
		this.setState({ changeList: true });
	};
	onChangeList = async () => {
		let { allVideos } = this.state;
		let data = allVideos;
		await post('/shop/reels/changeList', { data })
			.then((data) => {
				this.getAllVideos();
			})
			.catch((err) => {
				console.log('tryCatch Error', err);
			});
	};
	deleteVideo = async (it) => {
		let { allVideos } = this.state;
		let filteringVideo = _.filter(allVideos, (x, y) => {
			if (x.id !== it) {
				return it;
			}
		});
		this.setState({ allVideos: filteringVideo });
		let data = filteringVideo;
		await post('/shop/reels/upsert', { data })
			.then((data) => {
				this.getAllVideos();
			})
			.catch((err) => {
				console.log('tryCatch Error', err);
			});
	};
	modalUp(name, id, productBrand) {
		let pBrand = productBrand.split('=')[1];
		this.setState({ modalVideoName: name, modalVideoId: id, modalBrandName: pBrand, selectVideoId: id, openModal: true });
	}
	closeModal = () => {
		this.setState({ openModal: false });
	};
	addYoutubePlayList = async () => {
		let axiosData, newData;
		let youtubePlasyList = this.props && this.props.shop && this.props.shop.client_config && this.props.shop.client_config.youtubePlasyList;

		await post('/shop/reels/upsert', { youtubePlasyList })
			.then((data) => {
				this.getAllVideos();
			})
			.catch((err) => console.log('err', err));
	};
	look = async () => {
		let data = await get('/shop/allreels');
	};
	render() {
		let { t } = this.props;
		let {
			watchId,
			writing,
			allVideos,
			dropDownOptions,
			changeList,
			openModal,
			modalData,
			VideoId,
			productid,
			videoName,
			modalVideoId,
			modalBrandName,
			modalVideoName,
		} = this.state;
		let youtubePlayList = this.props && this.props.shop && this.props.shop.client_config && this.props.shop.client_config.youtubePlasyList;
		return (
			<div>
				<Grid>
					<GridColumn>
						<Segment>
							<div>
								<div>
									{' '}
									<YouTube style={{ marginBottom: '10px' }} videoId={watchId} />
								</div>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
										<Input
											className="youtubePlayList"
											placeholder={'Youtube PlayList'}
											name={'youtubePlayList'}
											value={youtubePlayList}
											onChange={this.handleChange}
											style={{ marginBottom: '10px' }}
											disabled
										/>
									</div>
									{youtubePlayList ? (
										<Button onClick={() => this.addYoutubePlayList()} style={{ marginLeft: '20px' }}>
											Yenile <Icon name="refresh" />
										</Button>
									) : (
										''
									)}
									{/* <Button onClick={() => this.look()} style={{ marginLeft: '20px' }}>Yenile <Icon name='refresh' /></Button> */}
								</div>
							</div>
							<Table className="videoTable">
								<Table.Header style={{ marginLeft: '11px' }}>
									<Table.HeaderCell style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '5px' }}>
										{/* <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '30px',marginTop:'10px' }}> */}
										<Table.Row>
											<Table.Cell>
												<Icon name="arrows alternate vertical" size="large"></Icon>
											</Table.Cell>
											<Table.Cell id="videoName">{t('Video name')}</Table.Cell>
											<Table.Cell id="videoId">{t('Video Id')}</Table.Cell>
											{/* <Table.Cell id="videoProductId">{t('productId')}</Table.Cell> */}
										</Table.Row>
										{allVideos && allVideos.length > 0 && changeList ? (
											<Button style={{ width: '200px', marginTop: '10px' }} color="blue" onClick={() => this.onChangeList()}>
												{t('save')}
											</Button>
										) : null}
									</Table.HeaderCell>
								</Table.Header>
								<DragDropContext onDragEnd={this.onDragEnd}>
									<Droppable droppableId="actions" type="list">
										{(provided) => {
											return (
												<div
													className="files-list"
													{...provided.droppableProps}
													ref={provided.innerRef}
													style={{ overflowY: 'auto', maxHeight: '100%', height: '600px', overflowX: 'hidden' }}
												>
													<Table.Body id="videoTableBody" style={{ display: 'flex', flexDirection: 'column' }}>
														{_.map(allVideos, (it, i) => {
															return (
																<Draggable
																	draggableId={it.productBrand.split('/')[3] || it.id}
																	index={i}
																	key={it.productBrand.split('/')[3] || it.id}
																>
																	{(provided) => {
																		return (
																			<Table.HeaderCell>
																				<Table.Row>
																					<div
																						ref={provided.innerRef}
																						className="action-body margin-yt"
																						style={{ marginLeft: '9px' }}
																						{...provided.draggableProps}
																					>
																						<Table.Cell>
																							<div {...provided.dragHandleProps}>
																								<Icon name="arrows alternate vertical" size="large"></Icon>
																							</div>
																							<div alt="img" size="medium"></div>
																						</Table.Cell>
																						<Table.Cell id="videoName" style={{ minWidth: '200px !important' }}>
																							{it.name}
																						</Table.Cell>
																						<Table.Cell id="videoId">{it.id}</Table.Cell>
																						{/* <Table.Cell id="videoProductId">
																							{it.productBrand.split('/')[3] !== '111'
																								? it.productBrand.split('/')[3]
																								: ''}
																						</Table.Cell> */}

																						<Table.Cell>
																							<Button
																								onClick={() => this.setState({ watchId: it.id })}
																								color="green"
																							>
																								İzle <Icon name="youtube" style={{ marginLeft: '5px' }} />
																							</Button>
																						</Table.Cell>
																						<Table.Cell>
																							<Button onClick={() => this.deleteVideo(it.id)} color="red">
																								Sil <Icon name="delete" style={{ marginLeft: '5px' }} />
																							</Button>
																						</Table.Cell>
																						<Table.Cell>
																							<Button
																								onClick={() => this.modalUp(it.name, it.id, it.productBrand)}
																							>
																								Edit <Icon name="edit" />
																							</Button>
																						</Table.Cell>
																					</div>
																				</Table.Row>
																			</Table.HeaderCell>
																		);
																	}}
																</Draggable>
															);
														})}
													</Table.Body>
												</div>
											);
										}}
									</Droppable>
								</DragDropContext>
							</Table>
						</Segment>
					</GridColumn>
				</Grid>
				<Modal open={openModal} onClose={this.closeModal}>
					<Modal.Header> Video edit</Modal.Header>
					<Modal.Content>
						<Form onSubmit={this.saveModalVideo}>
							<div style={{ marginBottom: '1rem' }}>
								<Label>video Id</Label>
								<Form.Input
									required
									className="wi-90"
									value={this.state.modalVideoId}
									fluid
									placeholder={'Video Id'}
									name="modalVideoId"
									onChange={this.handleChange}
								></Form.Input>
							</div>
							{/* <Form.Input
                                required
                                className="wi-90"
                                value={(this.state.modalBrandName)}
                                fluid
                                label="Product Brand"
                                name="modalBrandName"
                                onChange={this.handleChange}
                            ></Form.Input> */}
							<div style={{ marginBottom: '1rem' }}>
								<Label>Product Brand</Label>
								<Dropdown
									className="wi-90"
									name="productid"
									style={{ marginBottom: '1rem' }}
									label="Product Brand"
									options={dropDownOptions}
									placeholder="marka seçiniz"
									fluid
									selection
									value={this.state.modalBrandName}
									onChange={this.handleChange}
								/>
							</div>
							<div>
								<Label>Video Name</Label>
								<Form.Input
									required
									className="wi-90"
									value={this.state.modalVideoName}
									fluid
									placeholder={'Video Name'}
									name="modalVideoName"
									onChange={this.handleChange}
								></Form.Input>
							</div>
							<Form.Button
								type="submit"
								color="blue"
								style={{
									display: 'flex',
									marginTop: '24px',
								}}
							>
								Değişiklikleri kaydet
							</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Video_table));
