import React, { Component } from 'react';
import { connect } from 'react-redux';
import { post } from '../../util/api';
import { Icon, Button, Table, Modal, Checkbox, Popup, Image, Divider } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import examp1 from '../../exampleSchemes/firstexamp1.json';
import examp2 from '../../exampleSchemes/firstexamp2.json';
import examp3 from '../../exampleSchemes/secondexamp1.json';
import examp4 from '../../exampleSchemes/secondexamp2.json';
import examp5 from '../../exampleSchemes/thirthexamp1.json';
import examp6 from '../../exampleSchemes/thirthexamp2.json';
import _ from 'lodash';
class ChooseSchema extends Component {
	state = {
		newdata: [],
		modalOpen: false,
		modalImage: '',
	};
	addSchema = async () => {
		let shop = this.props && this.props.shop;
		let { newdata } = this.state;
		let isMulti = false;
		if (newdata.length > 1) isMulti = true;
		let data = newdata;
		console.log('data', data);
		await post('/shop/addExampSchema', { shop, data })
			.then((res) => {
				if (res && res.data && res.data.success && res.data.success == true) window.location.pathname = '/whatsapp-shop';
			})
			.catch((err) => console.log('err', err));
	};
	clickedSchema = (e, data) => {
		// console.clear();
		let { newdata } = this.state;
		console.clear();
		if (newdata.includes(data) === true) {
			//zaten var
			let index = newdata.indexOf(data);
			if (index > -1) newdata.splice(index, 1);
		} else {
			newdata.push(data);
			const uniqueArr = newdata.filter((item, index) => {
				return newdata.indexOf(item) === index;
			});
			newdata = uniqueArr;
		}
		console.log('------------------');
		console.log('newdata', newdata);
		this.setState({ newdata });
	};
	openModalWithImage = (data) => {
		this.setState({ modalOpen: true, modalImage: data });
	};
	closeModal = () => this.setState({ modalOpen: false });
	render() {
		let { t } = this.props;
		let { newdata } = this.state;
		let exampDatas = [examp1, examp2, examp3, examp4, examp5, examp6]; // localdeki örnek semalar burada tanımla
		let schemaNames = ['Yapaytech (1)', 'Yapaytech (1-1)', 'Yapaytech (1-2)'];
		const Images = {
			'Yapaytech (1) first': 'https://wg.dialogtab.com/export/1/2/6.jpg',
			'Yapaytech (1) second': 'https://wg.dialogtab.com/export/1/3/2.jpg',
			'Yapaytech (1-1) first': 'https://wg.dialogtab.com/export/1/2/8.jpg',
			'Yapaytech (1-1) second': 'https://wg.dialogtab.com/export/1/3/3.jpg',
			'Yapaytech (1-2) first': 'https://wg.dialogtab.com/export/1/3/4.jpg',
			'Yapaytech (1-2) second': 'https://wg.dialogtab.com/export/1/2/9.jpg',
		};
		return (
			<Modal open={true}>
				<Modal.Header>Örnek şemalardan seçebilirsin</Modal.Header>
				<Modal.Content>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>KOLAJ ADI</Table.HeaderCell>
								<Table.HeaderCell>Örnek Resim</Table.HeaderCell>
								<Table.HeaderCell>
									<Icon name="check" />
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(exampDatas, (it, i) => (
								<Table.Row key={i}>
									<Table.Cell>{it.name}</Table.Cell>
									<Table.Cell>
										<Icon name="image" size="large" onClick={() => this.openModalWithImage(_.find(Images, (k, y) => y === it.name))} />
									</Table.Cell>
									<Table.Cell>
										<Checkbox name={it.name} onChange={(e) => this.clickedSchema(e, it)} />
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Modal.Content>
				<Modal.Actions>
					<Button disabled={newdata.length > 0 ? false : true} onClick={() => this.addSchema()}>
						{t('yes')}
					</Button>
				</Modal.Actions>
				<Modal open={this.state.modalOpen} style={{ width: 'auto' }}>
					<Modal.Actions style={{ padding: '0', borderTop: '0' }}>
						<Button onClick={() => this.closeModal()} color="red" style={{ marginRight: '0px' }}>
							X
						</Button>
					</Modal.Actions>
					<Modal.Content>
						<Image centered src={this.state.modalImage} style={{ height: '85vh', width: 'auto' }}></Image>
					</Modal.Content>
				</Modal>
			</Modal>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(ChooseSchema));
