import React, { Component } from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu, Icon } from 'semantic-ui-react';
import Users from './users';
import Company from './company';
import { withTranslation } from 'react-i18next';

class Account extends Component {
	render() {
		const { t } = this.props;

		return (
			<Page title={t('account')} icon="user" subtitle={t('manageAccount')} data={this.props}>
				<Page.Wrap>
					<Menu className="settings" pointing secondary>
						<Menu.Item as={NavLink} exact to="/account">
							<Icon name="setting" />
							{t('accountSettings')}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/account/company">
							<Icon name="building" />
							{t('companySettings')}
						</Menu.Item>
					</Menu>
					<Switch>
						<Route path="/account/company" component={Company} />
						<Route path="/account" exact component={Users} />
						<Redirect to="/account" />
					</Switch>
				</Page.Wrap>
			</Page>
		);
	}
}
export default withTranslation()(Account);
