import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { toWapi, post, get, postEx } from '../../util/api';
import { Button, Segment, Table, Grid, GridColumn, Header, Modal, Icon, Tab } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Phone extends Component {
	state = {
		phones: [],
		loading: true,
		targets: [],
		message_type: 'template',
		sw_groups: true,
		sw_contacts: false,
		start_date: new Date(),
	};
	componentDidMount() {
		this.listSchedules();
		this.getData();
	}
	async getData() {
		let { t, shop } = this.props;
		await post('/shop/brand/list', { pid: shop.pid }).then((data) => {
			let result = data.data.result.data;
			let filtered_data = _.filter(result, (it) => it.link);
			this.setState({ brands: filtered_data, loading: false });
		});
	}
	async listSchedules() {
		let { shop } = this.props;
		await post('/shop/schedulelist', { pid: shop.pid }).then((data) => {
			let list = data.data.result;
			this.setState({ scheduleList: list });
		});
	}
	async sendMessage() {
		this.setState({ btnLoading: true });
		let { shop } = this.props;
		let { brands, planned, start_date } = this.state;
		let pid = shop.server_config.whatsapp.product_id;
		let key = shop.server_config.whatsapp.key;
		let keyword = shop.keyword;
		let show_videos = (shop.client_config && shop.client_config.show_videos) || false;
		let button_count = (shop.server_config && shop.server_config.button_count) || 3;
		let telegram = shop.server_config.telegram;
		let whatsapp_contact_message = shop.server_config.whatsapp_contact_message;
		let whatsapp_contact_message2 = shop.server_config.whatsapp_contact_message2;
		let whatsapp_contact_message3 = shop.server_config.whatsapp_contact_message3;
		let telegram_button_extra = shop.server_config.telegram_button_extra;
		let buttons = [[{ text: 'WHATSAPP SHOP 👗', url: `https://${shop.keyword}.whatsapp-shop.com`, type: 'url' }]];

		if (telegram_button_extra) buttons.push(...telegram_button_extra);
		if (shop.server_config.no_web_url) buttons = [];

		if (whatsapp_contact_message) {
			buttons.push([
				{
					text: 'WHATSAPP CONTACT',
					url: whatsapp_contact_message,
					type: 'url',
				},
			]);
		}

		if (whatsapp_contact_message2) {
			buttons.push([
				{
					text: 'WHATSAPP CONTACT - 2',
					url: whatsapp_contact_message2,
					type: 'url',
				},
			]);
		}
		if (whatsapp_contact_message3) {
			buttons.push([
				{
					text: 'WHATSAPP CONTACT - 3',
					url: whatsapp_contact_message3,
					type: 'url',
				},
			]);
		}
		if (show_videos) {
			buttons.push([
				{
					text: (keyword && _.upperCase(keyword) + ' REELS 🎥') || 'REELS 🎥',
					url: `https://${shop.keyword}.whatsapp-shop.com/reels`,
					type: 'url',
				},
			]);
		}
		let telegram_format_data = _.map(
			_.filter(brands, (o) => o.active || o.link === 'https://t.me/+0WjuL2v6NjE4M2Jk'),
			(it) => ({
				text: it.name,
				url: it.link,
				type: 'url',
			})
		);
		for (let index = 0; index < telegram_format_data.length; index = index + button_count) {
			let temp = _.slice(telegram_format_data, index, index + button_count);
			buttons.push(temp);
		}
		let isArray = _.isArray(telegram);
		if (!isArray) {
			let body = {
				to_number: telegram.main_group,
				type: 'text',
				message: telegram.buttons_message,
				buttons,
			};
			if (telegram.video_url) {
				body = {
					to_number: telegram.main_group,
					type: /mp4|mov/.test(telegram.video_url) ? 'video' : 'media',
					text: telegram.buttons_message,
					message: telegram.video_url,
					buttons,
				};
			}
			if (planned) {
				await post('/shop/addscheduletelegram', { pid: shop.pid, time: start_date }).then(() => this.listSchedules());
			} else {
				if (telegram.new_telegram) {
					await postEx(`https://app.dialogtab.com/api/telegram/sendMessage/${pid}/${telegram.telegram_id}`, body)
						.then((data) => {
							if (data.status !== 200) {
								return;
							}
							return data;
						})
						.catch((err) => {
							console.error(err);
						});
				} else {
					await toWapi(`/api/${pid}/${telegram.telegram_id}/sendMessage`, key, body)
						.then((data) => {
							if (data.status !== 200) {
								return;
							}
							return data;
						})
						.catch((err) => {
							console.error(err);
						});
				}
			}
		} else {
			if (planned) {
				await post('/shop/addscheduletelegram', { pid: shop.pid, time: start_date }).then(() => this.listSchedules());
			} else {
				for (let i = 0; i < telegram.length; i++) {
					let element = telegram[i];
					let body = {
						to_number: element.main_group,
						type: 'text',
						message: element.buttons_message,
						buttons,
					};
					if (element.video_url) {
						body = {
							to_number: element.main_group,
							type: /mp4|mov/.test(element.video_url) ? 'video' : 'media',
							text: element.buttons_message,
							message: element.video_url,
							buttons,
						};
					}
					if (element.nobuttons) {
						body = _.pick(body, ['to_number', 'type', 'text', 'message']);
					}
					if (element.new_telegram) {
						await postEx(`https://app.dialogtab.com/api/telegram/sendMessage/${pid}/${element.telegram_id}`, body)
							.then((data) => {
								if (data.status !== 200) {
									return;
								}
								return data;
							})
							.catch((err) => {
								console.error(err);
							});
					} else {
						await toWapi(`/api/${pid}/${element.telegram_id}/sendMessage`, key, body)
							.then((data) => {
								if (data.status !== 200) {
									return;
								}
								return data;
							})
							.catch((err) => {
								console.error(err);
							});
					}
				}
			}
		}
		this.setState({ btnLoading: false, openModal: false });
	}

	async sendMessage2() {
		this.setState({ btnLoading: true });
		let { shop } = this.props;
		let { planned, start_date } = this.state;
		let pid = shop.server_config.whatsapp.product_id;
		let key = shop.server_config.whatsapp.key;
		let telegram = shop.server_config.telegram1;
		let buttons = [];

		if (planned) {
			await post('/shop/addschedulestatictelegram', { pid: shop.pid, time: start_date }).then(() => this.listSchedules());
		} else {
			for (let i = 0; i < telegram.length; i++) {
				let element = telegram[i];
				buttons = element.buttons;
				let body = {
					to_number: element.main_group,
					type: 'text',
					message: element.buttons_message,
					buttons,
				};
				if (element.video_url) {
					body = {
						to_number: element.main_group,
						type: /mp4|mov/.test(element.video_url) ? 'video' : 'media',
						text: element.buttons_message,
						message: element.video_url,
						buttons,
					};
				}
				if (element.nobuttons) {
					body = _.pick(body, ['to_number', 'type', 'text', 'message']);
				}
				if (telegram.new_telegram) {
					await postEx(`https://app.dialogtab.com/api/telegram/sendMessage/${pid}/${telegram.telegram_id}`, body)
						.then((data) => {
							if (data.status !== 200) {
								return;
							}
							return data;
						})
						.catch((err) => {
							console.error(err);
						});
				} else {
					await toWapi(`/api/${pid}/${element.telegram_id}/sendMessage`, key, body)
						.then((data) => {
							if (data.status !== 200) {
								return;
							}
							return data;
						})
						.catch((err) => {
							console.error(err);
						});
				}
			}
		}
		this.setState({ btnLoading: false, openModal: false });
	}
	async cancelSchedule() {
		let { shop } = this.props;
		await post('/shop/cancelschedule', { pid: shop.pid }).then(() => this.listSchedules());
		this.setState({ btnLoading: false, openDeleteModal: false });
	}
	closeModal = () => {
		this.setState({ openModal: false, planed: false, openDeleteModal: false });
	};
	render() {
		let { loading, scheduleList, start_date, selected_index } = this.state;
		let { t, shop } = this.props;

		let groups = [
			{
				number: 0,
				name: 'Markalar Şablonu',
				id: '-1001602838599',
			},
		];
		if (shop && shop.server_config && shop.server_config.telegram1)
			groups.push({
				number: 1,
				name: 'Bilgi Şablonu',
				id: '-1001602838599',
			});
		console.log('shop', scheduleList);
		if (loading) return <Page.Loader></Page.Loader>;
		const panes = [
			{
				menuItem: 'Mesaj Gönder',
				render: () => (
					<Tab.Pane>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('name')}</Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(groups, (it) => (
									<Table.Row>
										<Table.Cell>{it.name}</Table.Cell>
										<Table.Cell textAlign={'right'}>
											<Button positive onClick={() => this.setState({ openModal: true, selected_index: it.number })}>
												{t('sendTemplate')}
											</Button>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Tab.Pane>
				),
			},
			{
				menuItem: t('Schedule'),
				render: () => (
					<Tab.Pane>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('name')}</Table.HeaderCell>
									<Table.HeaderCell width={1}></Table.HeaderCell>
									<Table.HeaderCell width={2}></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(groups, (it) => (
									<Table.Row>
										<Table.Cell>{it.name}</Table.Cell>

										<Table.Cell textAlign={'right'}>
											<DatePicker
												selected={start_date}
												onChange={(date) => this.setState({ start_date: date })}
												showTimeSelect
												showTimeSelectOnly
												timeFormat="HH:mm"
												timeIntervals={15}
												timeCaption={t('time')}
												dateFormat="HH:mm"
											/>
										</Table.Cell>
										<Table.Cell textAlign={'right'}>
											<Button positive onClick={() => this.setState({ openModal: true, planned: true, selected_index: it.number })}>
												{t('schedule')}
											</Button>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
						<br />
						{scheduleList && scheduleList.length ? (
							<>
								<p>{t('scheduleList')}</p>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell>{t('createDate')}</Table.HeaderCell>
											<Table.HeaderCell>{t('runTime')}</Table.HeaderCell>
											<Table.HeaderCell></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(scheduleList, (it) => {
											let cron = it.cron.split(' ');
											let hour = cron[1];
											let min = cron[0] === '0' ? '00' : cron[0];
											return (
												<Table.Row>
													<Table.Cell>Markalar Şablonu</Table.Cell>
													<Table.Cell>{moment(it.created_on).format('DD-MM-YYYY HH:mm')}</Table.Cell>
													<Table.Cell>{`${t('everyday')} ${hour}:${min}`}</Table.Cell>
													<Table.Cell style={{ textAlign: 'right' }}>
														<Button negative onClick={() => this.setState({ openDeleteModal: true })}>
															{t('delete')}
														</Button>
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</>
						) : null}
					</Tab.Pane>
				),
			},
		];
		return (
			<Page title={'Telegram'} icon="telegram">
				<Page.Wrap>
					<Grid>
						<GridColumn width={16}>
							<Segment className="scroll">
								<div style={{ display: 'flex', marginBottom: '10px' }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Header>{t('Telegram Grup')}</Header>
									</div>
								</div>
								<Tab panes={panes} />
							</Segment>
						</GridColumn>
					</Grid>
					<Modal open={this.state.openModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
						<Header icon="telegram" content={t('sendMessage')} />
						<Modal.Content>
							<p style={{ display: 'flex', alignItems: 'center' }}>{this.state.planned ? t('plannedTelegramSure') : t('sendTelegramsSure')}</p>
						</Modal.Content>
						<Modal.Actions>
							<div style={{ display: 'flex' }}>
								<div style={{ marginLeft: 'auto' }}>
									<Button color="red" onClick={this.closeModal}>
										<Icon name="remove" /> {t('no')}
									</Button>
									<Button
										color="green"
										onClick={() => {
											if (selected_index == 0) this.sendMessage();
											else if (selected_index == 1) this.sendMessage2();
										}}
									>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
								</div>
							</div>
						</Modal.Actions>
					</Modal>
					<Modal open={this.state.openDeleteModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
						<Header icon="telegram" content={t('cancelSchedule')} />
						<Modal.Content>
							<p style={{ display: 'flex', alignItems: 'center' }}>{t('deleteScheduleQuestion')}</p>
						</Modal.Content>
						<Modal.Actions>
							<div style={{ display: 'flex' }}>
								<div style={{ marginLeft: 'auto' }}>
									<Button color="red" onClick={this.closeModal}>
										<Icon name="remove" /> {t('no')}
									</Button>
									<Button color="green" onClick={() => this.cancelSchedule()}>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
								</div>
							</div>
						</Modal.Actions>
					</Modal>
				</Page.Wrap>
			</Page>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Phone));
