import React, { Component } from 'react';
import { Form, Segment, Icon, Message, Button } from 'semantic-ui-react';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import { Formik } from 'formik';
import { FormikInput, MiniContainer } from '../../components/FormikInput';
import * as Yup from 'yup';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';

function customizer(objValue, srcValue) {
	return _.isUndefined(objValue) || objValue === null ? srcValue : objValue;
}

var defaults = _.partialRight(_.assignWith, customizer);
class UserPage extends Component {
	state = {
		userInfo: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		userPassword: { icon: 'save', color: 'blue', saved: 'Save', sending: false },
		newpassword: '',
		ex_file: '',
		loading: false,
	};
	componentDidMount() {}

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	handleSubmit = (values) => {
		const { t } = this.props;
		if (this.state.userInfo.sending) return;
		this.setState({ userInfo: { sending: true } });
		this.props.updateUser(values);
		this.setState({
			userInfo: { sending: false, errormessage: '', saved: t('saved'), color: 'teal', icon: 'check' },
		});
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userInfo: { icon: 'save', color: 'blue', saved: t('save') } });
		}, 2000);
		this.postData(values);
	};
	handleSubmitPassword = (values) => {
		const { t } = this.props;
		if (this.state.userPassword.sending) return;
		this.setState({ userPassword: { sending: true } });
		this.props.updateUser(values);
		this.postPassword(values);
		if (this.state.passwordError) {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: t('unSaved'), color: 'red', icon: 'x' },
			});
		} else {
			this.setState({
				userPassword: { sending: false, errormessage: '', saved: t('saved'), color: 'teal', icon: 'check' },
			});
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({ userPassword: { icon: 'save', color: 'blue', saved: t('save') } });
		}, 2000);
	};
	postData(values) {
		let body = {
			name: values.name,
			phone: values.phone,
			company: values.company,
			email: values.email,
			agent: true,
		};
		if (!values.__isAgent) {
			body.extra = _.merge(values.extra, {
				ecommerce: values.ecommerce,
				domain: values.domain,
			});
		}
		post('/auth/updateInfo', body);
	}
	postPassword(values) {
		post('/auth/updatePassword', {
			newpassword: values.newpassword,
		}).then((data) => {
			this.setState({ passwordErrorMessage: data.data.message, passwordError: data.data.success });
		});
	}

	postVerifyMail(values) {
		post('/mailer/verify', { email: values.email }).then((data) => {
			if (data.data.success) {
				this.setState({ verifyMessage: 'New verify link was sent your email.' });
			} else {
				this.setState({ verifyMessage: 'Error...' });
			}
		});
	}

	render() {
		const { t, host, user } = this.props;
		let { loading } = this.state;
		const initials = defaults({}, user, user.extra, { name: '', company: '', phone: '', newpassword: '', confirmpassword: '' });
		/* const options = [
			{
				text: t('yes'),
				value: 'yes',
			},
			{
				text: t('no'),
				value: 'no',
			},
		]; */
		if (loading) return <Page.Loader></Page.Loader>;
		return (
			<Segment>
				<MiniContainer>
					<h3>
						<Icon name="user" />
						{t('personalInformation')}
					</h3>
					{host === 'app.dialogtab.com' && !this.props.user.verified ? (
						<Message error>
							<span>{t('accountNotVerified')}</span>
							<Button size="mini" primary style={{ marginLeft: '10px' }} onClick={() => this.postVerifyMail({ email: this.props.user.email })}>
								{t('sendMail')}
							</Button>
						</Message>
					) : (
						''
					)}
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmit}
							validationSchema={Yup.object().shape({
								name: Yup.string().required(t('nameisRequired')),
								company: Yup.string().required(t('companyisRequired')),
								//phone: Yup.string().matches(/^(\+90|0)?(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/, 'Phone number is not valid!'),
							})}
						>
							{(props) => {
								const { values, handleChange, handleBlur, handleSubmit } = props;

								return (
									<Form onSubmit={handleSubmit}>
										<FormikInput
											id="name"
											label={t('name')}
											name="name"
											icon="user"
											iconPosition="left"
											placeholder={t('userPlaceholder', { query: t('name') })}
											type="text"
											value={values.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											id="company"
											label={t('companyName')}
											icon="building"
											name="company"
											placeholder={t('userPlaceholder', { query: t('company') })}
											iconPosition="left"
											type="text"
											value={values.company}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											id="email"
											label={t('email')}
											name="email"
											icon="at"
											iconPosition="left"
											placeholder={t('userPlaceholder', { query: t('email') })}
											type="text"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											readOnly
										/>
										<FormikInput
											id="phone"
											label={t('phone')}
											iconPosition="left"
											name="phone"
											icon="phone"
											placeholder={t('userPlaceholder', { query: t('phone') })}
											type="phone"
											value={values.phone}
											onChange={handleChange}
											onBlur={handleBlur}
										/>

										<Form.Button
											fluid
											style={{ marginTop: '15px' }}
											icon={this.state.userInfo.icon}
											type="submit"
											color={this.state.userInfo.color}
											content={this.state.userInfo.saved}
											loading={this.state.userInfo.sending}
										/>
									</Form>
								);
							}}
						</Formik>
					</Segment>
					<h3 style={{ marginTop: '5rem' }}>
						<Icon name="lock" />
						{t('password')}
					</h3>
					<Segment>
						<Formik
							initialValues={initials}
							onSubmit={this.handleSubmitPassword}
							validationSchema={Yup.object().shape({
								newpassword: Yup.string()
									.required('Password is required!')
									.matches(
										/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
										'Password must be min 8, at least one uppercase, lowercase and special character.'
									),
								confirmpassword: Yup.string().oneOf([Yup.ref('newpassword'), null], t('passwordValidationMatch')),
							})}
						>
							{(props) => {
								const { handleChange, handleBlur, handleSubmit } = props;
								return (
									<Form onSubmit={handleSubmit}>
										<FormikInput
											label={t('newPassword')}
											icon="key"
											iconPosition="left"
											name="newpassword"
											type="password"
											placeholder={t('enterNewPassword')}
											id="newpassword"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<FormikInput
											label={t('confirmPassword')}
											icon="key"
											iconPosition="left"
											name="confirmpassword"
											type="password"
											placeholder={t('enterNewPasswordAgain')}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<Form.Button
											fluid
											icon={this.state.userPassword.icon}
											type="submit"
											color={this.state.userPassword.color}
											content={this.state.userPassword.saved}
											loading={this.state.userPassword.sending}
										/>
									</Form>
								);
							}}
						</Formik>
					</Segment>
				</MiniContainer>
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
