import React from 'react';
import { Container, Image } from 'semantic-ui-react';
import Page from './Page';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { logout } from '../actions/access.actions';
import { connect } from 'react-redux';

const AgentAccount = (props) => (
	<Page.Wrap>
		<Container>
			<div id="notfound">
				<Image
					src={window.CONFIG.icon}
					style={{ width: '10rem', margin: '10px', position: 'absolute', left: '50%', top: '25%', transform: 'translate(-50%, -50%)' }}
				/>

				<div className="notfound">
					<div className="notfound-404">
						<h1>{props.code}</h1>
					</div>
					<h2>{props.title}</h2>
					<p style={{textTransform: "lowercase"}}>{props.list || []}</p>
					<a href="#" onClick={props.logoutUser}>
						Logout
					</a>
				</div>
			</div>
		</Container>
	</Page.Wrap>
);

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(logout()),
});
export default connect((state) => ({ shop: state.product.shop }), mapDispatchToProps)(AgentAccount);
