import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { post, getFull } from '../../util/api';
import { Segment, Table, Breadcrumb, Icon, Button, Modal, Checkbox, Label } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import dumbdata from '../../exampleSchemes/dumbdata.json';
class Folder extends Component {
	state = {
		loading: true,
		openModal: false,
		activeStep: 1,
		post_data: [],
	};
	componentDidMount() {
		let { shop } = this.props;
		if (shop && shop.server_config && shop.server_config.production_images) {
			this.getProductionData();
		}
		if (shop && shop.server_config && shop.server_config.mobile_images) {
			this.getMobileData();
		}

		this.getData();
	}
	onClose() {
		this.setState({ openModal: false, activeStep: 1, post_data: [], sub_folders: [] });
	}
	async importData() {
		this.setState({ loadingImport: true });
		let { post_data } = this.state;
		try {
			await post('/shop/folder/import', { folder: post_data }).then((data) => {
				if (data.data.success) {
					window.location.reload();
				}
			});
		} catch (error) {
			window.alert('Error');
		}
	}
	async getData() {
		await post('/shop/folder/list', {}).then((data) => {
			this.setState({ data: data.data.result, loading: false });
		});
	}
	async getProductionData() {
		let { shop } = this.props;
		await getFull(shop.server_config.production_images).then((data) => {
			this.setState({ productionImages: data.result });
		});
	}
	async getMobileData() {
		let { shop } = this.props;
		await getFull(shop.server_config.mobile_images).then((data) => {
			this.setState({ mobileImages: data.result });
		});
	}
	async getModalData() {
		this.setState({ importBtnLoad: true });
		let { keyword } = this.props;
		await post('/shop/folder/list_all', { base_path: keyword, folders: true, files: true }).then((data) => {
			console.log(data);
			this.setState({ wg_data: data.data.result, openModal: true, importBtnLoad: false });
		});
	}
	async getModalSubData(id, subfolder) {
		let { post_data } = this.state;
		let { keyword } = this.props;
		let post_object = { name: subfolder, path: keyword + '/' + subfolder };
		if (id) post_object.id = id;
		post_data.push(post_object);
		this.setState({ post_data });
		if (id)
			await post('/shop/subfolder/list', { folder_id: id }).then((folders) => {
				let sub_folders = folders.data.result;
				this.setState({ sub_folders });
			});
		await post('/shop/folder/list_all', { base_path: keyword + '/' + subfolder, folders: true, files: true }).then((data) => {
			let wg_sub_data = data.data.result[0].folders;
			wg_sub_data = _.filter(wg_sub_data, (it) => it.files && it.files.length);
			this.setState({ wg_sub_data });
		});
		this.selectArray();
		this.setState({ activeStep: 2 });
	}
	selectArray() {
		let { wg_sub_data, sub_folders } = this.state;
		console.log('wg_sub_data', wg_sub_data);
		_.map(wg_sub_data, (it) => {
			let files = it.files;
			let group_images = _.groupBy(files, (o) => o.name.replace(/_.*/, ''));
			let group_images_arr = _.map(group_images, (it) => it);
			let wg_sub_item = _.find(sub_folders, (o) => o.data.name === it.name);
			console.log('group_images_arr', group_images_arr, wg_sub_item);
			if (!wg_sub_item || group_images_arr.length !== parseInt(wg_sub_item.count)) {
				this.handleChangePostData(it, group_images_arr);
			}
		});
	}
	handleChangePostData(data, groups) {
		let { post_data } = this.state;
		if (!post_data[0].sub_folders) post_data[0].sub_folders = [];
		let name = data.name || data.data.name;
		let i = _.findIndex(post_data[0].sub_folders, (it) => it.name === name);
		if (i >= 0) {
			_.pullAt(post_data[0].sub_folders, [i]);
		} else {
			post_data[0].sub_folders.push({ name: data.name || data.data.name, path: data.path || data.data.path, kolaj: groups });
		}
		this.setState({ post_data });
	}
	render() {
		const { t, shop } = this.props;
		let { loading, data, productionImages, mobileImages } = this.state;
		data = _.sortBy(data, ['data.name']);
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/wgroup`}>
								<Icon name="feed" />
								Broadcast
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		let count_production_images = productionImages && productionImages.folders && _.sumBy(productionImages.folders.files, 'images.length');
		let count_mobile_images = mobileImages && mobileImages.folders && _.sumBy(mobileImages.folders.files, 'images.length');
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/wgroup`}>
							<Icon name="feed" />
							Broadcast
						</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap>
					<Segment>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('name')}</Table.HeaderCell>
									<Table.HeaderCell>{t('date')}</Table.HeaderCell>
									<Table.HeaderCell width={6}>{t('status')}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(data, (it, i) => {
									if (it.kolaj && it.kolaj[0] && it.kolaj[0].status) {
										let verified_table = _.filter(it.kolaj, (o) => o.status === 'verified');
										return (
											<Table.Row key={i}>
												<Table.Cell>
													<Link to={'/wgroup/' + it.id}>{it.data.name}</Link>
												</Table.Cell>
												<Table.Cell>{moment(it.updated_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
												<Table.Cell style={{ textAlign: 'right' }}>
													{verified_table && _.sumBy(verified_table, 'count') ? (
														<Label color="green" image>
															Onaylanan
															<Label.Detail>{_.sumBy(verified_table, 'count')}</Label.Detail>
														</Label>
													) : null}
												</Table.Cell>
											</Table.Row>
										);
									}
								})}
								{productionImages && productionImages.folders ? (
									<Table.Row key={'productionImages'}>
										<Table.Cell>
											<Link to={'/wgroup/production_images'}>{productionImages.folders.name}</Link>
										</Table.Cell>
										<Table.Cell>
											{moment(productionImages.folders && productionImages.folders.stats && productionImages.folders.stats.mtime).format(
												'DD-MM-YYYY HH:mm'
											)}
										</Table.Cell>
										<Table.Cell style={{ textAlign: 'right' }}>
											{count_production_images ? (
												<Label color="green" image>
													Toplam Resim
													<Label.Detail>{count_production_images}</Label.Detail>
												</Label>
											) : null}
										</Table.Cell>
									</Table.Row>
								) : (
									''
								)}
								{mobileImages && mobileImages.folders ? (
									<Table.Row key={'mobileImages'}>
										<Table.Cell>
											<Link to={'/wgroup/mobile_images'}>{'Mobil Kolajsız'}</Link>
										</Table.Cell>
										<Table.Cell>
											{moment(mobileImages.folders && mobileImages.folders.stats && mobileImages.folders.stats.mtime).format(
												'DD-MM-YYYY HH:mm'
											)}
										</Table.Cell>
										<Table.Cell style={{ textAlign: 'right' }}>
											{count_mobile_images ? (
												<Label color="green" image>
													Toplam Resim
													<Label.Detail>{count_mobile_images}</Label.Detail>
												</Label>
											) : null}
										</Table.Cell>
									</Table.Row>
								) : (
									''
								)}
							</Table.Body>
						</Table>
					</Segment>
				</Page.Wrap>
			</Page>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Folder));
