import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';
import { post } from '../../util/api';
import { Segment, Table, Breadcrumb, Icon, Label, Button, Dropdown, Modal, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
class Folder extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.getData();
	}
	async getData() {
		let { folder } = this.props.match.params;
		await post('/shop/subfolder/list', { folder_id: folder }).then((data) => {
			let result = data.data && data.data.result;
			this.setState({ data: result, loading: false, tableloading: false, folder_data: result && result[0] && result[0].folder });
		});
	}
	sumFilter(data, status) {
		return _.sumBy(_.flatten(_.map(data, (o) => _.filter(o.kolaj, (it) => it && it.status === status))), 'count');
	}
	build() {
		this.setState({ buildLoading: true });
		let { folder } = this.props.match.params;
		post('/shop/build', { folder_id: folder }).then(() => this.setState({ buildLoading: false }));
	}
	async stateUpdate(subfolder_id, state_now) {
		let state = state_now === 'active' ? 'passive' : 'active';
		this.setState({ tableloading: true });
		await post('/shop/state/update', { subfolder_id: parseInt(subfolder_id), state }).then(() => this.getData());
	}
	async updateSubFolder() {
		this.setState({ btnLoadName: true });
		let { selected_sub, showing_name } = this.state;

		let body = {
			...selected_sub.data,
			showing_name,
		};
		await post(`/shop/subfolder/update/${selected_sub.id}`, { data: body }).then(() => {
			this.setState({ openNameModal: false, btnLoadName: false });
			this.getData();
		});
	}
	render() {
		const { t, subdomain } = this.props;
		let { loading, data, folder_data, tableloading, openNameModal, showing_name, btnLoadName, selected_sub, buildLoading } = this.state;
		let { folder } = this.props.match.params;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/wgroup`}>
								<Icon name="feed" />
								Broadcast
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		if (!data.length) {
			return <Redirect to="/wgroup" />;
		}
		let verified_total = this.sumFilter(data, 'verified');

		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/wgroup`}>
							<Icon name="feed" />
							Broadcast
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>{folder_data.data.name}</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				{tableloading ? (
					<Page.Loader></Page.Loader>
				) : (
					<Page.Wrap>
						<Segment>
							<div style={{ display: 'flex' }}>
								<div style={{ marginLeft: 'auto' }}>
									{verified_total ? (
										<Label color="green" image>
											Onaylanan
											<Label.Detail>{verified_total}</Label.Detail>
										</Label>
									) : null}
								</div>
							</div>
							<Table>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell width={4}>{t('name')}</Table.HeaderCell>
										<Table.HeaderCell width={2}>{t('date')}</Table.HeaderCell>
										<Table.HeaderCell width={2}>{t('status')}</Table.HeaderCell>
										<Table.HeaderCell width={8}>{t('details')}</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(data, (it) => {
										let verified_table = _.filter(it.kolaj, (o) => o && o.status === 'verified');
										return (
											<Table.Row key={it.data.name}>
												<Table.Cell>
													<Link to={'/wgroup/send/' + it.id}>
														{it.data.name} {it.data.showing_name ? ' (' + it.data.showing_name + ')' : null}
													</Link>
												</Table.Cell>

												<Table.Cell>{moment(it.updated_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
												<Table.Cell>
													{<Icon name={it.state === 'active' ? 'check' : 'x'} color={it.state === 'active' ? 'green' : 'red'}></Icon>}
													{t(it.state)}
												</Table.Cell>
												<Table.Cell style={{ textAlign: 'right' }}>
													{verified_table && _.sumBy(verified_table, 'count') ? (
														<Label color="green" image>
															Onaylanan
															<Label.Detail>{_.sumBy(verified_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{/* <Label color="red">
														Gönderilmedi
														{console.log('verified_tableverified_table',verified_table)}
														<Label.Detail>bakacaz</Label.Detail>
													</Label> */}
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						</Segment>
					</Page.Wrap>
				)}
			</Page>
		);
	}
}

export default withTranslation()(Folder);
