import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu } from 'semantic-ui-react';

import userSettings from './user_settings';
import createCollage from './create_collage';

export default (props) => {
	return (
		<Page title="Admin" icon="user secret">
			<Menu className="settings" attached="top" tabular>
				<Menu.Item as={NavLink} to="/yta" exact>
					Firma Ayarları
				</Menu.Item>
				{/* <Menu.Item as={NavLink} to="/yta/collage">
					Kolaj yap
				</Menu.Item> */}
			</Menu>
			<Switch>
				<Route path="/yta" exact component={userSettings} />
				<Route path="/yta/settings" exact component={userSettings} />

				<Route path="/yta/collage" exact component={createCollage} />

				<Redirect to="/yta" />
			</Switch>
		</Page>
	);
};
