import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { toWapi } from '../../util/api';
import { Button, Modal, Segment, Table, Breadcrumb, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
class Phone extends Component {
	state = {
		phones: [],
		loading: true,
	};
	async componentDidMount() {
		await this.getListPhone();
		let { phones } = this.state;
		for (let index = 0; index < phones.length; index++) {
			const element = phones[index];
			await this.getQueue(element.id);
		}
		this.setState({ loading: false, tableloading: false });
	}

	async getListPhone() {
		let { shop } = this.props;
		let { whatsapp } = shop.server_config;
		await toWapi(`/api/${whatsapp.product_id}/listPhones`, whatsapp.key)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp' || it.type === 'telegram';
					}),
					'id'
				);
				this.setState({ phones: data, btnLoading: false });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	async getQueue(phone_id) {
		let { phones } = this.state;
		//let phone = _.find(phones, { id: phone_id });
		let { shop } = this.props;
		let { whatsapp } = shop.server_config;
		let stat = await toWapi(`/api/${whatsapp.product_id}/${phone_id}/queue`, whatsapp.key)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				return data.data.stats && data.data.stats.sending;
			})
			.catch((err) => {
				console.error(err);
			});
		_.find(phones, { id: phone_id }).stat = stat;
		this.setState({ phones });
	}
	async purgeQueue() {
		this.setState({ btnLoading: true });
		let { selectedId, phones } = this.state;
		let { shop } = this.props;
		let { whatsapp } = shop.server_config;
		await toWapi(`/api/${whatsapp.product_id}/${selectedId}/purgeQueue`, whatsapp.key)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				_.find(phones, { id: selectedId }).stat = 0;
				this.setState({ btnLoading: false, open: false, phones });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		let { phones, loading } = this.state;
		if (loading)
			return (
				<Page title="Mesaj Kuyruğu">
					<Page.Loader></Page.Loader>
				</Page>
			);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/wgroup`}>
							<Icon name="arrow left"></Icon>
							{'Mesaj Kuyruğu'}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap>
					<Segment>
						<Button
							positive
							loading={this.state.tableloading}
							onClick={() => {
								this.setState({ tableloading: true });
								this.componentDidMount();
							}}
						>
							Yenile
						</Button>

						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Id</Table.HeaderCell>
									<Table.HeaderCell>Numara</Table.HeaderCell>
									<Table.HeaderCell>İsim</Table.HeaderCell>
									<Table.HeaderCell>Durum</Table.HeaderCell>
									<Table.HeaderCell>Kuyruk</Table.HeaderCell>
									<Table.HeaderCell></Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(phones, (it) => (
									<Table.Row>
										<Table.Cell>{it.id}</Table.Cell>
										<Table.Cell>{it.number}</Table.Cell>
										<Table.Cell>{it.name}</Table.Cell>
										<Table.Cell>{it.type === 'telegram' && it.number ? 'active' : it.status}</Table.Cell>
										<Table.Cell>{it.stat}</Table.Cell>
										<Table.Cell>
											{
												<Button primary onClick={() => this.setState({ open: true, selectedId: it.id })}>
													Temizle
												</Button>
											}
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
						</Table>
					</Segment>
					<Modal open={this.state.open}>
						<Modal.Header>Kuyruk Temizle</Modal.Header>
						<Modal.Content>Mesaj kuyruğunu temizlemek istediğinize emin misiniz?</Modal.Content>
						<Modal.Actions>
							<Button.Group>
								<Button primary loading={this.state.btnLoading} onClick={() => this.purgeQueue()}>
									Evet
								</Button>
								<Button color="red" onClick={() => this.setState({ open: false })}>
									İptal
								</Button>
							</Button.Group>
						</Modal.Actions>
					</Modal>
				</Page.Wrap>
			</Page>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Phone));
