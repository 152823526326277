import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
/* import Table from './table';
import FolderPage from './folder';
import TelegramSend from './telegramsend'; */
import Folder from './folder';
/* import SubFolder from './sub_folder'; */
class Analytic extends PureComponent {
	render() {
		return (
			<Switch>
				<Route path="/analytic" exact component={Folder}></Route>
				<Redirect to="/analytic" />
			</Switch>
		);
	}
}

export default Analytic;
