import React, { Component } from 'react';
import Page from '../../components/Page';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import {
	Table,
	Breadcrumb,
	Icon,
	Button,
	List,
	Checkbox,
	Form,
	Input,
	Popup,
	Label,
	Tab,
	Dropdown,
	Image,
	Modal,
	TextArea,
	Message,
	Header,
	Segment,
	FormGroup,
	Loader,
	Dimmer,
} from 'semantic-ui-react';
import _ from 'lodash';
import useFetch from 'use-http';
import { ChromePicker } from 'react-color';

import { withTranslation } from 'react-i18next';
import LangList from './languages.json';

/* const languages = [
	{
		key: 'tr',
		text: 'TR',
		value: 'TR',
		flag_key: 'tr',
		image: { avatar: false, src: '/icons/flags/tr.png' },
	},
	{
		key: 'ru',
		text: 'RU',
		value: 'RU',
		flag_key: 'ru',
		image: { avatar: false, src: '/icons/flags/ru.png' },
	},
	{
		key: 'en',
		text: 'EN',
		value: 'EN',
		flag_key: 'gb',
		image: { avatar: false, src: '/icons/flags/gb.png' },
	},
	{
		key: 'fr',
		text: 'FR',
		value: 'FR',
		flag_key: 'fr',
		image: { avatar: false, src: '/icons/flags/fr.png' },
	},
	{
		key: 'ar',
		text: 'AR',
		value: 'AR',
		flag_key: 'sa',
		image: { avatar: false, src: '/icons/flags/sau.png' },
	},
];
 */

function createHash(obj) {
	return _.deburr(obj).replace(/\s+/g, '').toLowerCase().trim();
}
const dropdown = [
	{
		key: 'share',
		text: 'share',
		value: 'share',
	},
	{
		key: 'giveOrder',
		text: 'giveOrder',
		value: 'giveOrder',
	},
];
const LoaderExampleLoader = () => <Loader active inline />;
const panes = [{ menuItem: 'Genel Ayarlar' }, { menuItem: 'Agent Ayarlar' }];

const previews = {
	contain: [
		{
			src: 'https://cdnydm.com/permedia/kdw3oMCVA2o8CqMRCagvlw.jpg?size=800x800',
			value: false,
			description: 'Resimleri kutucuklara tam sığacak şekilde ayarını buradan yapabilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/2PNDrBIDCFUSrw5tBRa6XQ.jpg?size=800x800', value: true },
	],
	select_agent: [
		{
			src: 'https://cdnydm.com/permedia/XoU-HI0mhKXXg1VZFDu6Vw.jpg?size=800x800',
			value: false,
			description: 'Agentlarınıza yönlendirme yaptırtmak istiyorsanız bu seçeneği aktif edebilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/h3NJ5qofDPq1YgH7s_zINA.jpg?size=800x800', value: true },
	],
	show_all_brands: [
		{
			src: 'https://cdnydm.com/permedia/QllLMICQ6pbB2mjiUt9BsQ.jpg?size=800x800',
			value: false,
			description:
				'Markaların içerisinde eğer ürün yok ise o marka listelenir ve içerisinde ürün yok şeklinde bir yazı gelir. Bu özelliği buradan değiştirebilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/KQXIsyNyRH09S_-0eNSf8g.jpg?size=800x800', value: true },
	],
	open_brands: [
		{
			src: 'https://cdnydm.com/permedia/mpnWKHArSxFtlpwbtXOhKg.jpg?size=800x800',
			value: false,
			description: 'Markalar yeni bir tab ile mi gösterilecek yoksa ana menüye mi konulacak bu seçeneği bu kısımdan ayarlayabilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/eJGJnMUIzmW3aQCYlRhSIA.jpg?size=800x800', value: true },
	],
	table_menu: [
		{
			src: 'https://cdnydm.com/permedia/E6S3pzpV95VJp6RFSSqpog.jpg?size=800x800',
			value: false,
			description: 'Markaların listelenmesi solda ayrı bir panelde listelenmesini istiyorsanız bu özelliği açabilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/eJGJnMUIzmW3aQCYlRhSIA.jpg?size=800x800', value: true },
	],
	button_design: [
		{
			src: 'https://cdnydm.com/permedia/aemLeOzI4QTcOFcSlYwv0w.jpg?size=800x800',
			value: false,
			description: 'Markaların listelenme görününümü buradaki kısımdan değiştirebilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/hwvW9cXh9ZLX3uyoiO88_g.jpg?size=800x800', value: true },
	],
	cover_photo: [
		{
			src: 'https://cdnydm.com/permedia/GFMU2CPmYq1GZ_bqvG26oA.jpg?size=1100x639',
			value: false,
			description: 'Markalara kapak fotoğrafı eklemeyi buradaki kısımdan ayarlayabilirsiniz.',
		},
		{ src: 'https://cdnydm.com/permedia/1cTpipKeUOj5of4QLAtqSQ.png?size=812x440', value: true },
	],
	same_products_show: [
		{
			src: 'https://cdnydm.com/permedia/vuc23uxFzqO_dIg2Ehz4Ag.png?size=482x976',
			value: true,
			description: 'same_products_showDescription',
		},
		{
			src: 'https://cdnydm.com/permedia/tt2qstmPGnLvccnQd22P-g.png?size=483x967',
			value: false,
		},
	],
	same_products_fast: [
		{
			src: 'https://cdnydm.com/permedia/pv2pp0ydI9MCJQM2lFbLoQ.png?size=639x848',
			value: true,
			description: 'same_products_fastDescription',
		},
		{
			src: 'https://cdnydm.com/permedia/U-2QVpQWBo1c4iiWSIAvGQ.png?size=639x848',
			value: false,
		},
	],
	show_videos: [
		{
			src: 'https://cdnydm.com/permedia/Ro9oL2uOt7CfHtHb5ny7kQ.png?size=1123x340',
			value: true,
			description: 'show_videosDescriotion',
		},
		{
			src: 'https://cdnydm.com/permedia/W0_NueW2JfPajheXS2i7pQ.png?size=991x339',
			value: false,
		},
	],
	video_to_product: [
		{
			src: 'https://cdnydm.com/permedia/Be-9QtviWpfRQFyX6eCV4w.png?size=404x169',
			value: true,
			description: 'Video da gözüken ürününüzün ait olduğu kategori sayfasına yönlendirme',
		},
		{
			src: 'https://cdnydm.com/permedia/xAsJwdoQmhuphjm_bVRsYA.png?size=407x121',
			value: false,
		},
	],
	show_detail: [
		{
			src: 'https://cdnydm.com/permedia/J8Bt3EsRdneihjzF_VtYoA.png?size=1280x720',
			value: true,
			description: 'show_detailDescription',
		},
		{
			src: 'https://cdnydm.com/permedia/-dfNhzC1W3Ok5vwdX0qPIw.png?size=633x845',
			value: false,
		},
	],
	divide_into_types: [
		{
			src: 'https://cdnydm.com/permedia/0znM-24mBAQPWFNFtUB5jw.png?size=636x58',
			value: true,
			description: 'divide_into_typesDescription',
		},
		{
			src: 'https://cdnydm.com/permedia/KKQxJYRp1_gbQqQuBkjOHw.png?size=639x58',
			value: false,
		},
	],
	discount_products_brand: [
		{
			value: true,
			description: 'discount_products_brandDescription',
		},
		{
			value: false,
		},
	],
	new_products_brand: [
		{
			value: true,
			description: 'new_products_brandDescription',
		},
		{
			value: false,
		},
	],
	slider_quality: [
		{
			value: true,
			description: 'sliderQualityDescription',
			warning: '40 önerilendir',
		},
		{
			value: false,
		},
	],
	image_quality: [
		{
			value: true,
			description: 'imageQualityDescription',
			warning: '70 önerilendir',
		},
		{
			value: false,
		},
	],
	header_color: [
		{
			value: true,
			description: 'headerColorDescription',
			warning: '(Whatsapp Rengi -> #008069) (Telegram rengi -> #4788a9)',
		},
		{
			value: false,
		},
	],
	background_image_color: [
		{
			value: true,
			description: 'headerImageDescription',
		},
		{
			value: false,
		},
	],
	cart_settings: [
		{
			value: true,
			description: 'CartSettingsDescription',
		},
		{
			value: false,
		},
	],
	bar_menu: [
		{
			value: true,
			description: 'BarMenuDescription',
			warning: 'Bu Özelliği kapatmak için 0 değeri verin!',
		},
		{
			value: false,
		},
	],
};

const agentTypes = [{ text: 'Agent', value: 'agent' }];

//var agents = [{ agent_mail: 'whatsappshop@dialogtab.com', agent_name: 'Furkan Yazan', agent_password: 'Test1234.', agent_pos: 'agent' }];

class Folder extends Component {
	state = {
		open_brands: false,
		show_all_brands: false,
		table_menu: false,
		search_product: false,
		select_agent: false,
		agent_brand: false,
		whatsapp_phone: '',
		logo: '',
		favicon: '',
		navbarIcon:'',
		cover_photo: false,
		title: '',
		description: '',
		language: 'tr',
		agent_list: [],
		contain: false,
		share_button_text: 'share',
		activeIndex: 0,
		preview: '',
		button_design: '',
		same_products_show: false,
		same_products_fast: false,
		slider_quality: '100',
		image_quality: '100',
		cart_settings: '',
		header_color: '#008069',
		background_image: false,
		background_color: '#e5ddd5',
		bar_menu: '',
		show_videos: false,
		video_to_product: false,
		show_detail: false,
		discount_products_brand: false,
		divide_into_types: false,
		previewContent: [],
		brand_data: [],
		telegram_button_message: '',
		broadcast: '',
		telegram: '',
		saveButton: 'Kaydet',
		mobile_settings: false,
		agentPanel: false,
		agent_mail: '',
		agent_name: '',
		agent_password: '',
		agent_pos: 'agent',
		baseState: { agent_mail: '', agent_name: '', agent_password: '', agent_pos: 'agent' },
		button_name: 'Ekle',
		areusure: false,
		passworderror: false,
		agent_loading: false,
		loadingImage: false,
		false_brands: [],
		multiple_brands: '',
		new_products_brand: {
			logo: 'https://wg.dialogtab.com/static/newproducts.png',
			name: 'New Products',
			_hash: 'newproducts',
			active: true,
		},
		google_analytics: '',
	};

	handleRangeChange = (e) => this.setState({ activeIndex: e.target.value });
	handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

	componentDidMount() {
		this.getLang();
		this.getData();
		this.getBrands();
	}
	getLang() {
		let _langs = _.map(LangList, (it) => ({
			key: it.countryCode,
			text: it.name,
			value: it.countryCode.toUpperCase(),
			flag_key: it.countryCode,
			flag: it.countryCode,
		}));
		this.setState({ languages: _langs });
	}
	async getBrands() {
		let { shop } = this.props;
		await post('/shop/brand/list', { pid: shop.pid }).then((data) => {
			let result = data && data.data && data.data.result && data.data.result.data;
			if (result && result.length > 0) {
				let { client_config } = shop;

				if (client_config && client_config.cover_photo && client_config.cover_photo === true) {
					this.setState({ cover_photo: result[0].cover_photo });
				}

				let filtered_data = _.filter(result, (it) => it.name);
				let brand_data_dropdown = _.map(filtered_data, (e, i) => {
					return { key: e.name, text: e.name, value: e.name };
				});
				this.setState({ brand_data: filtered_data, loading: false, brand_data_dropdown });
			}
		});
	}
	async getData() {
		let { shop } = this.props;
		let {
			open_brands,
			show_all_brands,
			table_menu,
			search_product,
			title,
			logo,
			select_agent,
			whatsapp_phone,
			favicon,
			navbarIcon,
			cover_photo,
			description,
			language = language || 'TR',
			agent_list,
			contain,
			share_button_text = share_button_text || 'share',
			button_design,
			same_products_show,
			same_products_fast,
			slider_quality,
			image_quality,
			cart_settings,
			header_color,
			background_color,
			background_image,
			bar_menu,
			new_products_brand,
			show_videos,
			video_to_product,
			youtubePlasyList,
			show_detail,
			discount_products_brand,
			divide_into_types,
			brands,
			google_analytics,
		} = shop.client_config;
		let { broadcast, telegram, mobile_settings } = shop.server_config
		let telegram_button_message = telegram && telegram.buttons_message;
		this.setState({
			open_brands,
			show_all_brands,
			table_menu,
			search_product,
			title,
			logo,
			select_agent,
			whatsapp_phone,
			favicon,
			navbarIcon,
			cover_photo,
			description,
			agent_list,
			language,
			contain,
			share_button_text,
			button_design,
			same_products_show,
			same_products_fast,
			slider_quality,
			image_quality,
			cart_settings,
			header_color,
			background_color,
			background_image,
			bar_menu,
			new_products_brand,
			show_videos,
			video_to_product,
			youtubePlasyList,
			show_detail,
			discount_products_brand,
			divide_into_types,
			brands,
			broadcast,
			telegram_button_message: telegram_button_message || null,
			telegram: telegram || null,
			mobile_settings,
			google_analytics,
		});

		if (
			mobile_settings &&
			!_.find(panes, (e, i) => {
				return e.menuItem === 'Mobil Ayarlar';
			})
		) {
			panes.push({ menuItem: 'Mobil Ayarlar' });
			this.getAgents();
		}
	}

	async getAgents() {
		let { shop } = this.props;
		let { pid } = shop;
		let body = { pid };
		let result = await post('/shop/agents/list', body).then((response) => {
			return response.data;
		});
		this.setState({ agents: result && result.agents });
	}

	handleChange = (e, { name, value }) => {
		if (name === 'same_products_show') {
			this.setState({ same_products_show: true, same_products_fast: false });
		} else if (name === 'same_products_fast') {
			this.setState({ same_products_fast: true, same_products_show: false });
		}
		if (name !== 'same_products_show' || name !== 'same_products_fast') {
			this.setState({ [name]: !this.state[name] });
		}
	};

	handleChangePhone = (e, { name, value }) => this.setState({ [name]: value });
	handleChangeQuality = (e, { name, value }) => {
		console.log('name ', name);
		if (name == 'bar_menu') {
			if (value >= 10) value = 2;
			if (value < 0) value = 0;
		} else {
			if (value >= 100) value = 100;
			if (value < 0) value = 0;
		}
		value = value.toString();
		this.setState({ [name]: value });
	};
	handleChangeYoutubePlatList = (e, { name, value }) => this.setState({ [name]: value });
	deleteAgent = (agent) => {
		let { agent_list } = this.state;
		_.remove(agent_list, function (n) {
			return n.value === agent;
		});
		this.setState({ agent_list });
	};

	addNewAgent = () => {
		let { agent_list, agentName, newName, newPhone, newImage, agentPhone, selectedAgent, updateAgent, newLang, languages } = this.state;
		let count;
		let selected_lang = _.map(newLang, (it) => {
			let f_key = _.find(languages, { value: it });
			if (f_key) return f_key.flag_key;
		});
		selected_lang = _.filter(selected_lang, (it) => it);
		if (updateAgent) {
			agent_list[selectedAgent] = {
				image: { avatar: true, src: newImage },
				text: newName,
				agentPhone: newPhone,
				value: String(selectedAgent),
				agentLang: newLang,
				flags: selected_lang || [],
			};
		} else {
			if (agent_list && agent_list.length > 0) {
				count = agent_list[agent_list.length - 1].value;
				agent_list.push({
					image: { avatar: true, src: newImage },
					text: newName,
					agentPhone: newPhone,
					agentLang: newLang,
					flags: selected_lang || [],
					value: String(parseInt(count) + 1),
				});
			} else {
				count = '0';
				agent_list = [
					{
						image: { avatar: true, src: newImage },
						text: newName,
						agentPhone: newPhone,
						value: count,
						agentLang: newLang,
						flags: selected_lang || [],
					},
				];
			}
		}

		this.setState({ agent_list, open: false, newName: '', newPhone: '', newImage: '', newLang: '' });
	};

	handleSave = async () => {
		let { t, shop } = this.props;
		this.setState({ loadingSaveButton: true });
		let {
			open_brands,
			show_all_brands,
			table_menu,
			search_product,
			title,
			logo,
			select_agent,
			whatsapp_phone,
			favicon,
			navbarIcon,
			description,
			cover_photo,
			agent_list,
			language,
			contain,
			share_button_text,
			button_design,
			brands,
			same_products_show,
			same_products_fast,
			slider_quality,
			image_quality,
			cart_settings,
			header_color,
			background_color,
			background_image,
			bar_menu,
			new_products_brand,
			show_videos,
			video_to_product,
			youtubePlasyList,
			show_detail,
			discount_products_brand,
			divide_into_types,
			telegram_button_message,
			telegram,
			google_analytics,
		} = this.state;

		let client_config = {
			open_brands: open_brands || false,
			show_all_brands: show_all_brands || false,
			table_menu: table_menu || false,
			search_product: search_product || false,
			title: title || 'Whatsapp-Shop',
			logo: logo || '',
			select_agent: select_agent || false,
			whatsapp_phone: whatsapp_phone || '',
			favicon: favicon || '',
			navbarIcon : navbarIcon || '',
			cover_photo: cover_photo || false,
			description: description || '',
			agent_list: agent_list || '',
			language: language || 'TR',
			contain: contain || false,
			share_button_text: share_button_text || 'share',
			button_design: button_design || false,
			brands: brands || '',
			same_products_show: same_products_show || false,
			same_products_fast: same_products_fast || false,
			slider_quality: slider_quality || '100',
			image_quality: image_quality || '100',
			cart_settings: cart_settings || false,
			header_color: header_color || '#008069',
			background_color: background_color || '#e5ddd5',
			background_image: background_image || false,
			bar_menu: bar_menu || 0,
			new_products_brand: new_products_brand || {
				logo: 'https://wg.dialogtab.com/static/newproducts.png',
				name: 'New Products',
				_hash: 'newproducts',
				active: true,
			},
			show_videos: show_videos || '',
			video_to_product: video_to_product || '',
			youtubePlasyList: youtubePlasyList || '',
			show_detail: show_detail || '',
			discount_products_brand: discount_products_brand || false,
			divide_into_types: divide_into_types || false,
			//telegram_button_message: telegram_button_message || '',
			google_analytics: google_analytics || '',
		};

		let saved, check;

		await post('/shop/updateClientConfig', { client_config }).then((result) => {
			saved = result.data.success ? 'Kaydedildi' : 'Hata';
			check = result.data.success ? true : false;
		});

		if (shop && shop.server_config && shop.server_config.telegram) {
			if (!_.isArray(telegram)) {
				shop.server_config.telegram.buttons_message = telegram_button_message;
			} else {
				let check = _.find(shop.server_config.telegram, (e, i) => {
					return e && e.buttons_message;
				});
				if (check && check.buttons_message && telegram_button_message) check.buttons_message = telegram_button_message;
			}
			if (check) {
				await post('/shop/updateServerConfig', { server_config: shop.server_config }).then((result) => {
					saved = result.data.success ? 'Kaydedildi' : 'Hata';
				});
			}
		}
		this.setState({ loadingSaveButton: false, saveButton: saved });

		setTimeout(() => this.setState({ saveButton: t('save') }), 2000);
	};

	handleFileInput = (e) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileUpload(file, name);
		this.setState({ loadingImage: true });
	};

	openPreviewModal = (e) => {
		let { preview } = this.state;
		console.log('test ', {
			preview: true,
			previewContent: previews[e],
			previewName: e,
			expl: previews[e][0].description,
			warning: (previews[e][0] && previews[e][0].warning) || '',
		});
		this.setState({
			preview: true,
			previewContent: previews[e],
			previewName: e,
			expl: previews[e][0].description,
			warning: (previews[e][0] && previews[e][0].warning) || '',
		});
	};

	fileUpload(file, name) {
		let { favicon, logo,navbarIcon } = this.state;
		const url = 'https://uploads.yapaytech.com/flow/uploadPermImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		let { type } = file;
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				// if (type === 'image/png') {
				if (name === 'new_products_brand.logo') {
					let new_products_brand = {
						logo: image_url,
						name: 'New Products',
						_hash: 'newproducts',
						active: true,
					};
					this.setState({ new_products_brand });
				} else this.setState({ [name]: image_url });

				this.setState({ loadingImage: false });
				// }
			});
	}

	validPassword() {
		let isTrue = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(this.state.agent_password);
		return isTrue;
	}
	changeAgentStateBrand(e) {
		let { email, name, password, agent_pos, uid } = e;
		this.setState({
			agent_mail: email,
			agent_uid: uid,
			agent_name: name,
			agent_password: password,
			agent_pos: 'agent',
		});
	}

	changeAgentState(e) {
		let { email, name, password, agent_pos, uid } = e;
		this.setState({
			agent_mail: email,
			agent_uid: uid,
			agent_name: name,
			agent_password: password,
			agent_pos: 'agent',
			agentPanel: true,
			button_name: 'Güncelle',
		});
	}

	saveAgentSettings = async () => {
		this.setState({ btn_load: true });

		let { agent_mail, agent_name, agent_password, agent_pos, multiple_brands } = this.state;
		let { shop } = this.props;

		// Buraya Gönder. ??
		await post('/shop/agents/update', {
			role: agent_pos,
			email: agent_mail,
			password: agent_password,
			name: agent_name,
			pid: shop.pid,
			uid: this.state.agent_uid,
			extra: { isAgent: true, selected_brands: multiple_brands },
		}).then((data) => {
			this.setState({ btn_load: false, agent_brand: false });
			this.getAgents();
		});
	};
	changeBrands(e) {
		let { brand_data } = this.state;
		let brands = [],
			last_brands = [];
		let check = false;

		if (!e) return this.setState({ agent_brand: true, all_brands: true });
		_.map(e, (a, i) => {
			let found = _.find(brand_data, (o) => createHash(o.name) == createHash(a));
			if (found) {
				last_brands.push(a);
				return brands.push({ value: a, color: 'black' });
			} else {
				check = true;
				return brands.push({ value: a, color: 'red' });
			}
		});
		this.setState({
			agent_brand: true,
			false_brands: brands,
			false_brands_check: check,
			multiple_brands: last_brands,
		});
	}

	async deleteMobileAgent() {
		let { agent_uid } = this.state;
		let { shop } = this.props;

		let data = await post('/shop/agents/delete', {
			uid: agent_uid,
			pid: shop.pid,
		}).then((data) => {
			return data.data;
		});

		if (data.success) {
			this.reset();
			await this.getAgents();
			this.setState({ areusure: false });
			//setReload(reload + 1);
		} else {
			this.setState({ passworderror: true });
		}
	}

	reset() {
		this.setState({ agent_mail: '', agent_name: '', agent_password: '', agent_pos: 'agent' });
	}

	async addMobileAgent() {
		let isTrue = this.validPassword();
		let { agent_mail, agent_name, agent_password, agent_pos, button_name } = this.state;
		this.setState({ agent_loading: true });
		let { shop } = this.props;
		agent_name = agent_name && agent_name.trim();
		if (isTrue && agent_mail && agent_name && agent_password) {
			if (button_name == 'Ekle') {
				let data = await post('/shop/agents/add', {
					role: agent_pos,
					email: agent_mail,
					password: agent_password,
					name: agent_name,
					pid: shop.pid,
				}).then((data) => {
					return data.data;
				});

				if (data.success) {
					this.reset();
					await this.getAgents();
					//setReload(reload + 1);
				} else {
					this.setState({ passworderror: true });
				}
			} else {
				let data = await post('/shop/agents/update', {
					role: agent_pos,
					email: agent_mail,
					password: agent_password,
					name: agent_name,
					pid: shop.pid,
					uid: this.state.agent_uid,
				}).then((data) => {
					return data.data;
				});

				if (data.success) {
					this.reset();
					await this.getAgents();

					//setReload(reload + 1);
				} else {
					this.setState({ passworderror: true });
				}
			}
			this.setState({ agentPanel: false });
		} else {
			this.setState({ passworderror: true });
		}
		this.setState({ agent_loading: false });
	}

	render() {
		let { t } = this.props;
		let {
			open_brands,
			show_all_brands,
			table_menu,
			search_product,
			title,
			logo,
			select_agent,
			whatsapp_phone,
			favicon,
			navbarIcon,
			cover_photo,
			description,
			language,
			agent_list,
			contain,
			newName,
			newPhone,
			newImage,
			newLang,
			activeIndex,
			share_button_text,
			previewContent,
			button_design,
			same_products_show,
			same_products_fast,
			slider_quality,
			image_quality,
			new_products_brand,
			show_videos,
			video_to_product,
			youtubePlasyList,
			show_detail,
			discount_products_brand,
			divide_into_types,
			broadcast,
			telegram,
			telegram_button_message,
			saveButton,
			agent_mail,
			agent_name,
			agent_password,
			agent_pos,
			agentPanel,
			baseState,
			button_name,
			agents,
			languages,
			loadingImage,
			header_color,
			background_color,
			background_image,
			cart_settings,
			bar_menu,
			google_analytics,
		} = this.state;
		return (
			<Page
				header={
					<>
						<Breadcrumb size="big">
							<Breadcrumb.Section>
								<Icon name="cog" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
						</Breadcrumb>
						<Button
							style={{ position: 'absolute', right: '30px', margin: '15px' }}
							color="blue"
							onClick={this.handleSave}
							loading={this.state.loadingSaveButton}
						>
							{saveButton}
						</Button>
					</>
				}
			>
				{activeIndex === 1 && (
					<Button style={{ position: 'absolute', right: '20px', margin: '15px' }} color="green" onClick={() => this.setState({ open: true })}>
						Agent Ekle
					</Button>
				)}
				<Page.Wrap>
					<Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange} />
					{activeIndex === 0 && (
						<Table celled>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell style={{ width: '20%' }}>{t('settings')}</Table.HeaderCell>
									<Table.HeaderCell>{t('value')}</Table.HeaderCell>
									<Table.HeaderCell style={{ width: '30%' }}>{t('description')}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body className="settingsClass" style={{ fontWeight: '800' }}>
								<>
									{broadcast && telegram && (
										<Table.Row>
											<Table.Cell>{t('telegramMessage')}</Table.Cell>
											<Table.Cell>
												<TextArea
													style={{ width: '200px' }}
													id="telegram_message"
													name="telegram_button_message"
													value={telegram_button_message}
													onChange={this.handleChangePhone}
												></TextArea>
											</Table.Cell>
											<Table.Cell>{t('telegramMessageDescription')}</Table.Cell>
										</Table.Row>
									)}
									<Table.Row>
										<Table.Cell>
											Youtube Shorts
											<Icon
												name="info circle"
												color="yellow"
												style={{ curost: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('show_videos')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox style={{ margin: '10px' }} toggle name="show_videos" checked={show_videos} onClick={this.handleChange} />
										</Table.Cell>
										<Table.Cell>
											{t(previews && previews.show_videos && previews.show_videos[0] && previews.show_videos[0].description)}
										</Table.Cell>
									</Table.Row>
									{show_videos ? (
										<Table.Row>
											<Table.Cell>Youtube PlastList</Table.Cell>
											<Table.Cell>
												<Form.Input
													style={{ width: '50%' }}
													fluid
													name="youtubePlasyList"
													value={youtubePlasyList}
													onChange={this.handleChangeYoutubePlatList}
												></Form.Input>
											</Table.Cell>
											<Table.Cell>{t('youtubeReelsDescription')}</Table.Cell>
										</Table.Row>
									) : null}
									{/* {show_videos ? (<Table.Row>
										<Table.Cell>
											Videodan ürüne yönlendirme <Icon name='info circle' color='red' style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => this.openPreviewModal('video_to_product')} />

										</Table.Cell>
										<Table.Cell>
											<Checkbox style={{ margin: '10px' }} toggle name="video_to_product" checked={video_to_product} onClick={this.handleChange} />
										</Table.Cell>
										<Table.Cell>
											{previews &&
												previews.video_to_product &&
												previews.video_to_product[0] &&
												previews.video_to_product[0].description}
										</Table.Cell>
									</Table.Row>) : (null)} */}
									<Table.Row>
										<Table.Cell>
											{t('same_products_show')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('same_products_show')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="same_products_show"
												checked={same_products_show}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>
											{t(
												previews &&
													previews.same_products_show &&
													previews.same_products_show[0] &&
													previews.same_products_show[0].description
											)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('same_products_fast')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('same_products_fast')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="same_products_fast"
												checked={same_products_fast}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>
											{console.log('state', this.state)}
											{t(
												previews &&
													previews.same_products_fast &&
													previews.same_products_fast[0] &&
													previews.same_products_fast[0].description
											)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('Slider Quality')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('slider_quality')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Input type="number" name={'slider_quality'} value={slider_quality} onChange={this.handleChangeQuality} />
										</Table.Cell>
										<Table.Cell>
											{t(previews && previews.slider_quality && previews.slider_quality[0] && previews.slider_quality[0].description)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('Image Quality')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('image_quality')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Input type="number" name="image_quality" value={image_quality} onChange={this.handleChangeQuality} />
										</Table.Cell>
										<Table.Cell>
											{t(previews && previews.image_quality && previews.image_quality[0] && previews.image_quality[0].description)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('cart_settings')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('cart_settings')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="cart_settings"
												checked={cart_settings}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>
											{t(previews && previews.cart_settings && previews.cart_settings[0] && previews.cart_settings[0].description)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('header_color')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('header_color')}
											></Icon>
										</Table.Cell>
										<Table.Cell style={{ display: 'flex' }}>
											<Popup
												trigger={
													<div
														className="color-wrap"
														style={{
															width: '50px',
															height: '30px',
															padding: '2px',
															border: '1px solid rgba(0, 0, 0, 0.2)',
															borderRadius: '3px',
															marginLeft: '5px',
															background: this.state.header_color || '#fff',
														}}
													>
														<div className="color-display" style={{ width: '100%', height: '100%', cursor: 'pointer' }} />
													</div>
												}
												on="click"
												content={
													<ChromePicker
														style={{ zIndex: '9999' }}
														color={this.state.header_color || '#fff'}
														onChange={({ hex }) => this.setState({ header_color: hex })}
													/>
												}
												style={{ borderRadius: 0, padding: 0 }}
											/>
											<div style={{ marginLeft: '20px' }}>
												<Button
													compact
													size="tiny"
													color="#008069"
													style={{ marginRight: '10px', width: '150px', background: '#008069', height: '30px', color: 'white' }}
													onClick={(e) => {
														this.setState({ header_color: '#008069' });
													}}
												>
													Whatsapp
												</Button>
												<Button
													compact
													size="tiny"
													color="#4788a9"
													style={{ marginRight: '10px', width: '150px', background: '#4788a9', height: '30px', color: 'white' }}
													onClick={(e) => {
														this.setState({ header_color: '#4788a9' });
													}}
												>
													Telegram
												</Button>
											</div>
										</Table.Cell>
										<Table.Cell>
											{t(previews && previews.header_color && previews.header_color[0] && previews.header_color[0].description)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('background_image_color')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('background_image_color')}
											></Icon>
										</Table.Cell>
										<Table.Cell style={{ display: 'flex' }}>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="background_image"
												checked={background_image}
												onClick={this.handleChange}
											/>
											{background_image && (
												<Popup
													trigger={
														<div
															className="color-wrap"
															style={{
																width: '50px',
																height: '30px',
																padding: '2px',
																border: '1px solid rgba(0, 0, 0, 0.2)',
																borderRadius: '3px',
																marginLeft: '5px',
																background: this.state.background_color || '#fff',
															}}
														>
															<div className="color-display" style={{ width: '100%', height: '100%', cursor: 'pointer' }} />
														</div>
													}
													on="click"
													content={
														<ChromePicker
															style={{ zIndex: '9999' }}
															color={this.state.background_color || '#fff'}
															onChange={({ hex }) => this.setState({ background_color: hex })}
														/>
													}
													style={{ borderRadius: 0, padding: 0 }}
												/>
											)}
										</Table.Cell>
										<Table.Cell>
											{t(
												previews &&
													previews.background_image_color &&
													previews.background_image_color[0] &&
													previews.background_image_color[0].description
											)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('bar_menu')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('bar_menu')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Input type="number" name="bar_menu" value={bar_menu} onChange={this.handleChangeQuality} />
										</Table.Cell>
										<Table.Cell>{t(previews && previews.bar_menu && previews.bar_menu[0] && previews.bar_menu[0].description)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('productReview')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('show_detail')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="show_detail"
												checked={show_detail}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>
											{t(previews && previews.show_detail && previews.show_detail[0] && previews.show_detail[0].description)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('groupbyDiscountProducts')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('discount_products_brand')}
											/>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												name="discount_products_brand"
												checked={discount_products_brand}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>
											{t(
												previews &&
													previews.discount_products_brand &&
													previews.discount_products_brand[0] &&
													previews.discount_products_brand[0].description
											)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('divide_into_types')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('divide_into_types')}
											/>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												name="divide_into_types"
												checked={divide_into_types}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>
											{t(
												previews &&
													previews.divide_into_types &&
													previews.divide_into_types[0] &&
													previews.divide_into_types[0].description
											)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('new_products_brand')}</Table.Cell>
										<Table.Cell>
											<Form.Input fluid name={'new_products_brand.logo'} value={new_products_brand && new_products_brand.logo}>
												<Popup
													on="click"
													hideOnScroll
													position="bottom left"
													content={
														<img
															width={180}
															src={new_products_brand && new_products_brand.logo}
															alt={'new_products_brand.logo'}
														></img>
													}
													trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
												></Popup>
												<input />
												<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
													<label htmlFor="new_products_brand.logo">
														<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
													</label>
													<Input
														id="new_products_brand.logo"
														type="file"
														name={'new_products_brand.logo'}
														accept="image/png"
														style={{ display: 'none' }}
														onChange={(e) => this.handleFileInput(e)}
													/>
												</div>
											</Form.Input>
										</Table.Cell>
										<Table.Cell>
											{t(
												previews &&
													previews.new_products_brand &&
													previews.new_products_brand[0] &&
													previews.new_products_brand[0].description
											)}
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('button_design')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('button_design')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="button_design"
												checked={button_design}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('buttonDesignDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('table_menu')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('table_menu')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="table_menu"
												checked={table_menu}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('tableMenuDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											Search Bar
											{/* <Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('table_menu')}
											></Icon> */}
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Table Menu"
												name="search_product"
												checked={search_product}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('searchBarDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('open_brands')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('open_brands')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Open Brands"
												name="open_brands"
												checked={open_brands}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('openBrandsDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('select_agent')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('select_agent')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Select Agent"
												name="select_agent"
												checked={select_agent}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('selectAgentDescriptoin')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('show_all_brands')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('show_all_brands')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Show All Brands"
												name="show_all_brands"
												checked={show_all_brands}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('showallbrandDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('contain')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('contain')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Show All Brands"
												name="contain"
												checked={contain}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('containDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>
											{t('cover_photo')}
											<Icon
												name="info circle"
												color="yellow"
												style={{ cursor: 'pointer', marginLeft: '10px' }}
												onClick={() => this.openPreviewModal('cover_photo')}
											></Icon>
										</Table.Cell>
										<Table.Cell>
											<Checkbox
												style={{ margin: '10px' }}
												toggle
												// label="Show All Brands"
												name="cover_photo"
												checked={cover_photo}
												onClick={this.handleChange}
											/>
										</Table.Cell>
										<Table.Cell>{t('coverPhotoDescriotion')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{'Whatsapp ' + t('phone')}</Table.Cell>
										<Table.Cell>
											<Form.Input
												style={{ width: '200px' }}
												fluid
												// label={'Whatsapp ' + t('phone')}
												name="whatsapp_phone"
												placeholder="905XXXXXXXX"
												value={whatsapp_phone}
												onChange={this.handleChangePhone}
											></Form.Input>
										</Table.Cell>
										<Table.Cell>{t('whatsappPhoneDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('logo')}</Table.Cell>
										<Table.Cell>
											<Form.Input fluid name="logo" value={logo} style={{ width: '300px' }}>
												<Popup
													on="click"
													hideOnScroll
													position="bottom left"
													content={<img width={180} src={logo} alt={'name'}></img>}
													trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
												></Popup>
												<input />
												<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
													<label htmlFor="logo">
														<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
													</label>
													<Input
														id="logo"
														type="file"
														name={'logo'}
														accept="image/png"
														style={{ display: 'none' }}
														onChange={(e) => this.handleFileInput(e)}
													/>
												</div>
											</Form.Input>
										</Table.Cell>
										<Table.Cell>{t('logoDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{'Website İkonu'}</Table.Cell>
										<Table.Cell>
											<Form.Input fluid name="favicon" value={favicon} style={{ width: '300px' }}>
												<Popup
													on="click"
													hideOnScroll
													position="bottom left"
													content={<img width={180} src={favicon} alt={'name'}></img>}
													trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
												></Popup>
												<input />
												<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
													<label htmlFor="favicon">
														<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
													</label>
													<Input
														id="favicon"
														type="file"
														name={'favicon'}
														accept="image/png"
														style={{ display: 'none' }}
														onChange={(e) => this.handleFileInput(e)}
													/>
												</div>
											</Form.Input>
										</Table.Cell>
										<Table.Cell>{t('websiteIconDescription')}.</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('title')}</Table.Cell>
										<Table.Cell>
											<Form.Input
												style={{ width: '200px' }}
												fluid
												// label={t('title')}
												name="title"
												value={title}
												onChange={this.handleChangePhone}
											></Form.Input>
										</Table.Cell>
										<Table.Cell>{t('titleDescriotion')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('description')}</Table.Cell>
										<Table.Cell>
											<Form.TextArea
												style={{ width: '200px' }}
												fluid
												// label={t('description')}
												name="description"
												value={description}
												onChange={this.handleChangePhone}
											></Form.TextArea>
										</Table.Cell>
										<Table.Cell>{t('descriptionDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('languagesOptions')}</Table.Cell>
										<Table.Cell>
											<Dropdown
												placeholder="Default Language"
												fluid
												selection
												name="language"
												// label={t('Language')}
												style={{ width: '28%' }}
												value={t(language)}
												options={languages}
												onChange={this.handleChangePhone}
											/>
										</Table.Cell>
										<Table.Cell>{t('languagesOptionsDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('OrderButtonText')}</Table.Cell>
										<Table.Cell>
											<Dropdown
												placeholder="Button Text"
												fluid
												selection
												name="share_button_text"
												// label={t('Language')}
												style={{ width: '28%' }}
												value={share_button_text}
												options={dropdown}
												onChange={this.handleChangePhone}
											/>
										</Table.Cell>
										<Table.Cell>{t('OrderButtonTextDescription')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>{t('google_analytics_text')}</Table.Cell>
										<Table.Cell>
											<Form.Input
												style={{ width: '200px' }}
												fluid
												name="google_analytics"
												placeholder="GA_MEASUREMENT_ID"
												value={google_analytics}
												onChange={this.handleChangePhone}
											></Form.Input>
										</Table.Cell>
										<Table.Cell>{t('google_analytics_description')}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell>Navbar Logo</Table.Cell>
										<Table.Cell>
											<Form.Input fluid name="navbarIcon" value={navbarIcon} style={{ width: '300px' }}>
												<Popup
													on="click"
													hideOnScroll
													position="bottom left"
													content={<img width={180} src={navbarIcon} alt={'navbarIcon'}></img>}
													trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
												></Popup>
												<input />
												<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
													<label htmlFor="navbarIcon">
														<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
													</label>
													<Input
														id="navbarIcon"
														type="file"
														name={'navbarIcon'}
														accept="image/png"
														style={{ display: 'none' }}
														onChange={(e) => this.handleFileInput(e)}
													/>
												</div>
											</Form.Input>
										</Table.Cell>
										<Table.Cell>Bu Alanda Navbar'a Logo ekleyebilirsiniz</Table.Cell>
									</Table.Row>
								</>
							</Table.Body>
						</Table>
					)}
					{activeIndex === 1 && (
						<>
							{/* // <Table.Row>
						// 	<Table.Cell style={{ alignItems: 'flex-start', display: 'flex' }}>{'Agents'}</Table.Cell>
						// 	<Table.Cell style={{ borderRight: '1px solid rgba(34,36,38,.1)' }}> */}
							{agent_list < 1 ? (
								<div>Agent Yok</div>
							) : (
								_.map(agent_list, (it, i) => {
									return (
										<Table.Row>
											<List
												selection
												verticalAlign="middle"
												onClick={() =>
													this.setState({
														open: true,
														updateAgent: true,
														newName: it.text,
														newImage: it.image.src,
														newPhone: it.agentPhone,
														newLang: it.agentLang,
														selectedAgent: i,
													})
												}
											>
												<List.Item>
													<Button
														compact
														size="tiny"
														color="red"
														style={{ marginRight: '10px' }}
														onClick={(e) => {
															e.stopPropagation();
															this.deleteAgent(it.value);
														}}
													>
														x
													</Button>
													<Image avatar={it.image.avatar ? true : false} src={it.image.src} />
													<List.Content>
														<List.Header>
															{it.text}{' '}
															<span style={{ textAlign: 'right' }}>
																{_.map(it.agentLang, (o) => (
																	<span style={{ fontSize: '12px', fontFamily: 'monospace' }}>{' ' + o}</span>
																))}
															</span>
														</List.Header>
													</List.Content>
												</List.Item>
											</List>
										</Table.Row>
									);
								})
							)}

							{/* </Table.Cell>
							<Table.Cell style={{ display: 'flex', border: 'none' }}>
								Bütün aktif olan agentlerınızı buradan görebilir. Ekleme ve çıkarma yapabilirsiniz.
							</Table.Cell>
						</Table.Row> */}
						</>
					)}
					{activeIndex === 2 && (
						<Segment>
							<Header>Agentlar</Header>
							<Table padded>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell width={5}>Eposta</Table.HeaderCell>
										<Table.HeaderCell width={2}>Tip</Table.HeaderCell>
										<Table.HeaderCell width={2} textAlign="left">
											İsim
										</Table.HeaderCell>
										<Table.HeaderCell width={2} textAlign="left">
											Markalar
										</Table.HeaderCell>
										<Table.HeaderCell textAlign={'right'}>
											<Button
												color="green"
												onClick={() =>
													this.setState({
														agentPanel: true,
														button_name: 'Ekle',
														agent_mail: baseState.agent_mail,
														agent_name: baseState.agent_name,
														agent_password: baseState.agent_password,
														agent_pos: baseState.agent_pos,
													})
												}
											>
												Agent Ekle
											</Button>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{_.map(agents, (e, i) => {
										return (
											<Table.Row key={i}>
												<Table.Cell width={5}>{e.email}</Table.Cell>
												<Table.Cell width={2}>{e.role}</Table.Cell>
												<Table.Cell width={2} textAlign="left">
													{e.name}
												</Table.Cell>
												<Table.Cell width={3} textAlign="left">
													<Button
														size={'tiny'}
														icon
														style={{ textAlign: 'center' }}
														color={
															_.findIndex(
																_.map(e && e.extra && e.extra.selected_brands, (e, i) => {
																	let found = _.find(this.state.brand_data, (o) => createHash(o.name) == createHash(e));
																	if (found) {
																		return true;
																	} else {
																		return false;
																	}
																}),
																(a, j) => a == false
															) != -1
																? 'red'
																: 'blue'
														}
														onClick={() => {
															this.changeAgentStateBrand(e);
															this.changeBrands(e.extra.selected_brands);
														}}
													>
														{_.findIndex(
															_.map(e && e.extra && e.extra.selected_brands, (e, i) => {
																let found = _.find(this.state.brand_data, (o) => createHash(o.name) == createHash(e));
																if (found) {
																	return true;
																} else {
																	return false;
																}
															}),
															(a, j) => a == false
														) != -1
															? 'KONTROL'
															: 'MARKALAR'}
													</Button>
													{e.extra && e.extra.selected_brands && e.extra.selected_brands.length <= 0 ? (
														<a style={{ paddingLeft: 20, color: 'black' }}>0 MARKA</a>
													) : e.extra && e.extra.selected_brands && e.extra.selected_brands.length ? (
														<>
															<a style={{ paddingLeft: 20, color: 'red' }}>{e.extra.selected_brands.length}</a>
															<a style={{ color: 'black' }}> MARKA</a>
														</>
													) : (
														<a style={{ paddingLeft: 20, color: 'black' }}>BÜTÜN MARKALAR</a>
													)}
												</Table.Cell>
												<Table.Cell textAlign="right">
													<Button
														size={'tiny'}
														icon
														color="blue"
														onClick={() => {
															this.changeAgentState(e);
														}}
													>
														<Icon name="edit" />
													</Button>
													<Button
														size={'tiny'}
														icon
														color="red"
														onClick={() => this.setState({ areusure: true, agent_uid: e.uid, agent_mail: e.email })}
													>
														<Icon name="trash" />
													</Button>
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
							{agentPanel && (
								<>
									{!this.state.agent_loading ? (
										<Form autoComplete="off">
											<input autoComplete="false" name="" value="" type="email" style={{ display: 'none' }} />
											<FormGroup widths="equal">
												<Form.Input
													placeholder="Email"
													name="agent_mail"
													value={agent_mail}
													disabled={button_name === 'Güncelle'}
													onChange={this.handleChangePhone}
													fluid
													required
													type="email"
												/>
												<Dropdown
													placeholder={t('type')}
													name="agent_pos"
													onChange={this.handleChangePhone}
													selection
													style={{ margin: 'auto', marginTop: '0' }}
													options={agentTypes}
													value={agent_pos}
												/>
												<Form.Input
													placeholder={t('fullName')}
													onChange={this.handleChangePhone}
													name="agent_name"
													value={agent_name}
													fluid
													required={false}
													type="text"
												/>
												<Form.Input
													placeholder={t('password')}
													onChange={this.handleChangePhone}
													name="agent_password"
													value={agent_password}
													error={this.state.passworderror ? { content: t('passwordValid'), pointing: 'above' } : false}
													autoComplete="new-password"
													fluid
													//icon={<Icon name="eye" link onClick={() => handleShowPassword(state.showPassword)} />}
													// error={state.passworderror ? { content: t('passwordValid'), pointing: 'above' } : false}
													required={true}
													type="text"
												/>

												<div className="d-flex">
													<Form.Button
														positive
														content={button_name}
														onClick={() => this.addMobileAgent()}
														style={{ width: '100px' }}
													/>
													<Form.Button
														negative
														content="X"
														onClick={() => {
															this.setState({ agentPanel: false, addAgent: baseState });
														}}
													/>
												</div>
											</FormGroup>
										</Form>
									) : (
										<div>
											<Loader active />
										</div>
									)}
								</>
							)}
						</Segment>
					)}
					<Modal open={this.state.agent_brand}>
						<Modal.Header>MARKALAR</Modal.Header>

						{this.state.false_brands_check && (
							<Modal.Content style={{ fontWeight: '600', fontSize: 14 }}>
								<div style={{ color: 'red' }}>Kırmızı Renkteki Markayı/Markaları, Markalar Sekmesinden Eklemelisiniz!</div>
							</Modal.Content>
						)}

						<Modal.Content>
							{this.state.all_brands ? (
								<div style={{ fontSize: 16, color: 'red' }}>BÜTÜN MARKALAR EKLİ!</div>
							) : (
								<div style={{ fontSize: 16, color: 'red' }}>EKLİ MARKALAR AŞAĞIDAKİ GİBİDİR</div>
							)}
							{_.map(this.state.false_brands, (e, i) => {
								return (
									<div key={i}>
										<a style={{ color: e.color }}>{e.value}</a>
									</div>
								);
							})}
						</Modal.Content>
						<Modal.Content>
							<Label color="red" size="small">
								{'AGENT HESABINA SEÇİLEN MARKALARI EKLE'}
							</Label>
							<Form.Dropdown
								style={{ width: '200px', margin: '10px' }}
								fluid
								search
								name="multiple_brands"
								multiple
								selection
								placeholder="Marka Ekle"
								value={this.state.multiple_brands}
								options={this.state.brand_data_dropdown}
								onChange={this.handleChangePhone}
							/>
							{this.state.multiple_brands && this.state.multiple_brands.length > 0 && (
								<Button style={{ margin: '15px' }} color="blue" onClick={this.saveAgentSettings} loading={this.state.btn_load}>
									Ekle
								</Button>
							)}
						</Modal.Content>

						<Modal.Actions>
							<Button
								color="red"
								onClick={() =>
									this.setState({ agent_brand: false, all_brands: false, false_brands: [], false_brands_check: false, multiple_brands: '' })
								}
							>
								Kapat
							</Button>
						</Modal.Actions>
					</Modal>
					<Modal
						open={this.state.open}
						onClose={() =>
							this.setState({ open: false, updateAgent: false, newName: '', newImage: '', newPhone: '', selectedAgent: '', newLang: '' })
						}
					>
						<Modal.Header>Agent Ekle</Modal.Header>
						<Modal.Content>
							<Label color="red" size="small">
								{'Agent Adı'}
							</Label>
							<Form.Input
								style={{ width: '200px', margin: '10px' }}
								fluid
								// label={'Whatsapp ' + t('phone')}
								name="newName"
								placeholder="Agent Adı"
								value={newName}
								onChange={this.handleChangePhone}
							></Form.Input>
							<Label color="red" size="small">
								{'Agent Resmi'}
							</Label>
							<Form.Input fluid name={'newImage'} value={newImage} style={{ width: '300px', margin: '10px' }} onChange={this.handleChangePhone}>
								<Popup
									on="click"
									hideOnScroll
									position="bottom left"
									content={<img width={180} src={newImage}></img>}
									trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{t('show')}</Label>}
								></Popup>
								<input />
								<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
									<label htmlFor="newImage">
										<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
									</label>
									<Input
										id="newImage"
										type="file"
										name={'newImage'}
										accept="image/*"
										style={{ display: 'none' }}
										onChange={(e) => this.handleFileInput(e)}
									/>
								</div>
								{loadingImage ? (
									<Dimmer active>
										<Loader />
									</Dimmer>
								) : null}
							</Form.Input>
							{this.state.whatsapp_phone ? (
								<>
									<Message
										error
										header="Agentlar tek bir numaraya bağlı gözüküyor."
										content='Agentlar farklı numara kullanıyorlar ise yukarıda ki "Genel Ayarlar" tarafından whatsapp numarasını kaldırmalısınız ve agentlara numara eklemelisiniz!'
									/>
									<Label color="red" size="small">
										{'Agent Telefon'}
									</Label>
								</>
							) : (
								<>
									<Message
										info
										header="Agentlar farklı numaralar mı kullanıyor?"
										content='Agentlar farklı numara kullanıyorlar ise numara eklemelisiniz! Eğer tek bir numara kullanıyor ise "Genel Ayarlar" tarafından numarayı ekleyebilirsiniz ve agent numara tarafını boş bırakabilirsiniz.'
									/>
									<Label color="red" size="small">
										{'Agent Telefon'}
									</Label>
								</>
							)}

							<Form.Input
								style={{ width: '200px', margin: '10px' }}
								fluid
								disabled={this.state.whatsapp_phone}
								// label={'Whatsapp ' + t('phone')}
								name="newPhone"
								placeholder="905XXXXXXXXX"
								value={newPhone}
								onChange={this.handleChangePhone}
							/>
							<Label color="red" size="small">
								{'Agent Dili'}
							</Label>
							<Form.Dropdown
								style={{ width: '200px', margin: '10px' }}
								fluid
								search
								name="newLang"
								multiple
								selection
								placeholder="-"
								value={newLang}
								options={languages}
								onChange={this.handleChangePhone}
							/>
						</Modal.Content>
						<Modal.Actions>
							<Button.Group>
								<Button style={{ marginRight: '5px' }} color="green" onClick={this.addNewAgent}>
									Add
								</Button>
								<Button color="red" onClick={() => this.setState({ open: false })}>
									Cancel
								</Button>
							</Button.Group>
						</Modal.Actions>
					</Modal>
					<Modal open={this.state.preview}>
						<Modal.Header>
							{t(this.state.previewName)} {this.state.warning ? '- ' + this.state.warning : ''}
						</Modal.Header>
						<Modal.Content style={{ textAlign: 'center', display: 'flex' }}>
							{_.map(previewContent, (it, i) => {
								return (
									<a>
										<Checkbox style={{ margin: '10px' }} toggle name="show_all_brands" checked={it.value} />
										<img style={{ maxWidth: '400px', marginRight: '15px' }} src={it.src}></img>
									</a>
								);
							})}
						</Modal.Content>
						<Modal.Content>
							<a style={{ color: 'black' }}>{t(this.state.expl)}</a>
						</Modal.Content>
						<Modal.Actions>
							<Button color="red" onClick={() => this.setState({ preview: false })}>
								Kapat
							</Button>
						</Modal.Actions>
					</Modal>
					<Modal open={this.state.areusure}>
						<Modal.Header>Silmek istediğinizden emin misiniz?</Modal.Header>
						<Modal.Content>
							<b>{this.state.agent_mail}</b> bu agentı silmek üzeresiniz.
						</Modal.Content>
						<Modal.Actions>
							<Button
								positive
								onClick={() => {
									this.deleteMobileAgent();
								}}
							>
								Sil
							</Button>
							<Button
								negative
								onClick={() => {
									this.setState({ areusure: false });
								}}
							>
								Vazgeç
							</Button>
						</Modal.Actions>
					</Modal>
				</Page.Wrap>
			</Page>
		);
	}
}
export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Folder));
