import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginPage, RegisterPage, ForgetPasswordPage, ResetPasswordPage, VerifyPage } from './pages/auth';
import ErrorMessage from './components/ErrorMessage';
import Page from './components/Page';
import Header from './components/Header';
import CheckMail from './pages/checkMail';
import { load_product } from './actions/product.actions';
import Account from './pages/account/router';
import WGroup from './pages/wgroup/index';
import WhatsAppShop from './pages/whatsapp-shop';
import BuildPage from './pages/whatsapp-shop/build';
import TelegramPage from './pages/wgroup/telegramsend';
import Analytic from './pages/analytic';
import BrandPage from './pages/whatsapp-shop/brandPage';
import Settings from './pages/whatsapp-shop/settings';
import UserSettings from './pages/admin/router';
import AgentAccount from './components/AgentAccount';
import Reels from './pages/reels'

function AgentAlert() {
	return <AgentAccount title="Agent Hesaplarının Erişim Yetkisi Yoktur." list={['info@whatsapp-shop.com']} code="403" />;
}

function NoProductMain() {
	return <AgentAccount title="Hesap Ayarlarınız Tanımlanmamıştır. Lütfen Bizimle İletişime Geçin!" list={['info@whatsapp-shop.com']} code="403" />;
}


function NoMatch() {
	return <ErrorMessage title="We are sorry, Page not found!" list={['Please check that the connection is correct.']} code="404" />;
}
function NoProduct() {
	return (
		<>
			<Header />
			<ErrorMessage title="Bu hesabın kayıdı bulunamamıştır." list={['Lütfen bizimle iletişime geçiniz!']} code="" />
		</>
	);
}

const LayoutRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(matchProps) => (
			<>
				<Header />
				<Component {...matchProps} />{' '}
			</>
		)}
	/>
);

function LoadProduct({ dispatch }) {
	React.useEffect(() => {
		dispatch(load_product());
	}, [dispatch]);
	return <Page.Loader />;
}

class App extends React.PureComponent {
	render() {
		if (this.props.access.loading === 'profile' || this.props.product.loading) return <Page.Loader />;
		const user = this.props.access.user;
		if (this.props.product.error) throw new Error(this.props.product.error);
		const admin = user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.admin;
		const agent = user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.isAgent;
		const isProduct = this.props && this.props.product && this.props.product.load_passed && !this.props.product.shop;

		return (
			<Switch>
				<Route path="/login" component={LoginPage} />
				<Route path="/register" component={RegisterPage} />
				<Route exact path="/forgot" component={ForgetPasswordPage} />
				<Route path="/forgot/checkyourmail" component={CheckMail} />
				<Route path="/verify/resetPassword/:token" component={ResetPasswordPage} />
				<Route path="/verify/account/:token" component={VerifyPage} />
				{(!user || !user.isAuthenticated) && <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />}
				{agent && <Route component={AgentAlert} />}
				{isProduct && <Route component={NoProductMain} />}

				{!this.props.product.shop &&
					(!this.props.product.load_passed ? <Route component={() => <LoadProduct {...this.props} />} /> : <Route component={NoProduct} />)}
				<LayoutRoute path="/" exact component={WhatsAppShop} />
				<LayoutRoute path="/whatsapp-shop" component={WhatsAppShop} />
				<LayoutRoute path="/build" component={BuildPage} />
				<LayoutRoute path="/telegram" component={TelegramPage} />
				<LayoutRoute path="/account" component={Account} />
				<LayoutRoute path="/brands" component={BrandPage} />
				<LayoutRoute path="/settings" component={Settings} />

				<LayoutRoute path="/analytic" component={Analytic} />
				<LayoutRoute path="/reels" component={Reels} />
				<LayoutRoute path="/wgroup" component={WGroup} />
				{admin && (
					<Switch>
						<LayoutRoute path="/yta" component={UserSettings} />
						<Redirect to="/yta" />
					</Switch>
				)}
				<LayoutRoute component={NoMatch} />
			</Switch>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, product: state.product, location: state.router.location, sub: state });

export default connect(stateToProps)(App);
