import React, { Component } from 'react';
import { post } from '../../util/api';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import {
	Segment,
	Table,
	Breadcrumb,
	TextArea,
	Icon,
	Button,
	List,
	Modal,
	Header,
	Tab,
	Divider,
	Message,
	Popup,
	Form,
	Checkbox,
	Input,
} from 'semantic-ui-react';

const remove = ['archive', 'backup', 'demo', null, undefined, ' ', 'whitehouse'];

let SWC = {
	headers: {
		'x-maytapi-key': '',
	},
	numberList: [],
	product_id: '',
	share_path: '',
	phoneIdList: [],
	telegramList: [],
	whatsappList: [],
	numberListTarget: [],
};

const settings = [
	{ name: 'Folder Id', desc: 'Mobil Uygulamada Siteyi Güncelleyi Çalıştırır', value: 'folder_id', type: 'input', extra: true },
	{ name: 'Agent Settings', desc: 'Agent Açmak için Aktif Edilen Özellik', value: 'mobile_settings', type: 'switch' },
	{ name: 'BroadCast', desc: 'Broadcast Özelliğini aktif etmek', value: 'broadcast', type: 'switch', findValue: 'server_config.broadcast' },
	{ name: 'Telegram Button Count', desc: 'Telegram Şablon 1 Satırdaki Buton Sayısı', value: 'button_count', type: 'input' },
	{ name: 'Collage Format', desc: 'Görsel formatı (code+renk+code2+renk2)', value: 'collage_format', type: 'switch' },
	{ name: 'sendOnlyAfter', desc: 'Youtube oynatma listelenizi Reels kısmında gösterbilirsiniz', value: 'sendOnlyAfter', type: 'switch' },
	// { name: 'Mobile Images', desc: 'Mobil Uygulama Kolajsız Yükleme Özelliğini Aktif Etmek', value: 'mobile_images', type: 'switch' },
	{ name: 'Telegram Bot Id', desc: 'Telegram Botu API', value: 'telegram_bot_id', type: 'input' },
	{ name: 'Telegram Bot Name', desc: 'Telegram Botu NAME', value: 'telegram_bot_name', type: 'input' },
];

const config_settings = [
	{ name: 'token', value: 'x-maytapi-key', type: 'object', desc: 'DialogTab Token' },
	{ name: 'product_id', value: 'product_id', type: 'string', desc: 'Dialogtab Product Id' },
	// { name: 'numberList', value: 'numberList', type: 'array', desc: 'Ana grup ekleme' },
	{ name: 'share_path', value: 'share_path', type: 'string', desc: 'Subdomain FTP Key' },
	{
		name: 'phoneIdList',
		value: 'phoneIdList',
		type: 'array',
		desc: 'Telegram ve Whatsapp numaraları id(24444) Örn: 24444,24456 whatsapp ve telegram virgülle bu şekilde eklenmelidir.',
	},
	{ name: 'Telegram Phone Id', value: 'telegram_id', type: 'string', desc: 'Telegram Phone Id' },
	{ name: 'Whatsapp Phone Id', value: 'whatsapp_id', type: 'string', desc: 'Whatsapp Phone Id' },
];

class UserSettings extends Component {
	state = {
		users: [],
		loading: true,
		shop: '',
		open: false,
		telegram_alert: '',
		whatsapp_alert: '',
		openCollage: false,
		newCollage: {},
		loader: false,
	};

	async componentDidMount() {
		this.getData();
	}

	resetStates() {
		this.setState({
			users: [],
			open: false,
			telegram_alert: '',
			whatsapp_alert: '',
			whatsapp_data: null,
			telegram_data: null,
			edited_index: null,
			edited_value: null,
		});
	}
	closeEditCollage() {
		this.setState({ openCollage: false, editing: false, foundCollage: null, newCollage: {}, createNewCollage: false });
	}
	closeCreatingCollage() {
		this.setState({ foundCollage: null, newCollage: {}, createNewCollage: false });
	}
	closeDetailCollage() {
		this.setState({ editing: false, foundCollage: null });
	}

	async saveFirmSettings() {
		let { shop } = this.state;
		let saved = '';
		await post('/shop/admin/updateServerConfig', { server_config: shop.server_config, keyword: shop.keyword, swc: shop.swc }).then((result) => {
			saved = result.data.success ? 'Kaydedildi' : 'Hata';
		});
	}

	async getData() {
		let data_result = [];
		await post('/shop/admin/users').then((data) => {
			if (data.data && data.data.result) {
				data_result = data.data.result;
				data_result = _.sortBy(data_result, (e) => e.keyword, 'asc');
				_.remove(data_result, function (n) {
					return _.find(remove, function (o) {
						if (!n.keyword) return true;
						return n.keyword == o;
					});
				});
			}
			let news = _.cloneDeepWith(data_result);

			this.setState({ loading: false, data: _.cloneDeepWith(data_result), default_value: news });
		});
	}

	async getNewWhatsapp() {
		let { shop } = this.state;
		let { swc } = shop;
		if (!swc) {
			this.setState({ whatsapp_alert: 'Eksik ayar, ayarlardan kullanıcı girişini yapınız!' });
			return;
		}
		let { headers, product_id, whatsapp_id, numberListTarget } = swc;
		let returning = [];
		if (!headers || !product_id || !whatsapp_id) {
			this.setState({ whatsapp_alert: 'Eksik ayar, ayarlardan kullanıcı girişini yapınız!' });
			return;
		}

		let element = whatsapp_id;
		let result = await axios({
			method: 'get',
			url: `https://wapi.yapaytech.com/api/${product_id}/${element}/getGroups?token=${headers['x-maytapi-key']}&page=0&time=`,
		}).then((resp) => resp.data);

		if (result && result.data && result.data.length > 0) {
			let filtered = _.filter(result.data, (e, i) => {
				return e.id.includes('@g.us');
			});

			returning.push(...filtered);
		}

		if (returning && returning.length < 1) {
			this.setState({ whatsapp_alert: 'Yeni whatsapp kanalı bulunamadı!', whatsapp_data: returning });
		} else {
			this.setState({ whatsapp_alert: 'Whatsapp kanalları çekildi.', whatsapp_data: returning });
		}
		setTimeout(() => {
			this.setState({ whatsapp_alert: '' });
		}, 5000);
	}

	async getNewTelegram() {
		let { shop } = this.state;
		let { swc } = shop;
		let result;
		if (!swc) {
			this.setState({ telegram_alert: 'Eksik ayar, ayarlardan kullanıcı girişini yapınız!' });
			return;
		}
		let { headers, product_id, phoneIdList, telegram_id } = swc;
		let returning = [];
		if (!headers || !product_id || !phoneIdList) {
			this.setState({ telegram_alert: 'Eksik ayar, ayarlardan kullanıcı girişini yapınız!' });
			return;
		}
		for (let index = 0; index < phoneIdList.length; index++) {
			const element = phoneIdList[index];
			result = await axios({
				method: 'get',
				url: `https://wapi.yapaytech.com/api/${product_id}/${element}/logs?token=${headers['x-maytapi-key']}&page=0&time=`,
			}).then((resp) => resp.data);
			if (!result) {
				result = await axios({
					method: 'get',
					url: `https://app.dialogtab.com/api/telegram/logs${product_id}/${telegram_id}`,
				}).then((resp) => resp.data);
			}

			if (result && result.data && result.data.list && result.data.list && result.data.list.length > 0) {
				let filtered = _.filter(result.data.list, (e, i) => {
					return e.data && e.data.status && e.data.status == 'administrator';
				});

				returning.push(...filtered);
			}
		}
		if (result && result.data && result.data.list && result.data.list.length == 0) {
			result = await axios({
				method: 'get',
				url: `https://dev.dialogtab.com/api/telegram/logs/${product_id}/${telegram_id}`,
			}).then((resp) => resp.data);
			console.log('result2 ', result);
			if (result && result.response && result.response.length > 0) {
				let filtered = _.filter(result.response, (e, i) => {
					return e.body && e.body.status && e.body.status == 'administrator';
				});

				returning.push(...filtered);
			}
		}

		if (returning && returning.length < 1) {
			this.setState({ telegram_alert: 'Yeni telegram kanalı bulunamadı!', telegram_data: returning });
		} else {
			this.setState({ telegram_alert: 'Telegram kanalları çekildi.', telegram_data: returning });
		}
		setTimeout(() => {
			this.setState({ telegram_alert: '' });
		}, 5000);
	}

	handlechangeTelegram = (e, { name, value }) => {
		let { shop, edited_index } = this.state;
		let { swc } = shop;
		let { telegramList } = swc;
		if (name == 'number') {
			telegramList[edited_index][name] = value;
		} else {
			telegramList[edited_index][name] = value.toLowerCase();
		}
		this.setState({ shop });
	};

	handlechangeNumberlist = (e, { name, value }) => {
		let { shop, edited_index } = this.state;
		let { swc } = shop;
		let { numberList } = swc;
		numberList[edited_index][name] = value.toLowerCase();
		this.setState({ shop });
	};
	handlechangeTelegramAndWhatsapp = (e, { name, value }) => {
		let { shop, edited_index } = this.state;
		let { swc } = shop;
		let { numberListTarget } = swc;
		numberListTarget[edited_index][name] = value;

		this.setState({ shop });
	};

	handlechangeWhatsapp = (e, { name, value }) => {
		let { shop, edited_index } = this.state;
		let { swc } = shop;
		let { whatsappList } = swc;
		whatsappList[edited_index][name] = value.toLowerCase();
		this.setState({ shop });
	};

	handlechangeSWC = (e, { name, value }) => {
		let new_shop = this.state.shop;
		if (!new_shop.swc.headers) new_shop.swc = SWC;

		if (name == 'x-maytapi-key') {
			new_shop.swc.headers[name] = value;
		} else {
			new_shop.swc[name] = value;
		}

		this.setState({ shop: new_shop });
	};

	editPhoneIdList() {
		let new_shop = this.state.shop;
		if (new_shop.swc && new_shop.swc.phoneIdList && new_shop.swc.phoneIdList.indexOf(',') != -1) {
			new_shop.swc.phoneIdList = new_shop.swc.phoneIdList.split(',');
		} else {
			new_shop.swc.phoneIdList = [new_shop.swc.phoneIdList];
		}
		this.setState({ shop: new_shop });
	}

	async editShop(shop) {
		this.resetStates();
		let { default_value } = this.state;
		let news = _.cloneDeepWith(default_value);

		let new_filtered = _.filter(news, (e, i) => e.pid == shop.pid)[0];

		if (shop && shop.swc) this.setState({ shop: new_filtered, open: true });
	}

	async editCollage(detail) {
		let { pid, keyword } = detail;
		await post('/shop/admin/schemas', { pid }).then((data) => {
			if (data && data.data) {
				let { result } = data.data;
				this.setState({ collages: result });
			}
		});
		this.setState({ openCollage: true, keyword, selected_pid: pid });
	}
	editSchema(_id) {
		let { collages } = this.state;
		let foundCollage = _.find(collages, (e, i) => e.id == _id);
		this.setState({ foundCollage, editing: true });
	}

	async deleteSchema(_id) {
		let { collages, selected_pid, keyword } = this.state;
		let foundCollage = _.find(collages, (e, i) => e.id == _id);
		if (foundCollage) {
			await post('/shop/admin/schema/delete', { id: _id, pid: selected_pid }).then(async (data) => {
				if (data && data.data) {
					await this.editCollage({ pid: selected_pid, keyword });
					//this.getData();
				}
			});
		}
	}

	createNewCollage() {
		this.setState({ createNewCollage: true });
	}

	async addCollage(type) {
		this.setState({ loader: true });
		let { selected_pid, newCollage, keyword, foundCollage } = this.state;
		if (type == 'add') {
			await post('/shop/admin/schema/add', { pid: selected_pid, data: newCollage }).then(async (data) => {
				if (data && data.data && data.data.success) {
					this.setState({ createNewCollage: false });
					await this.editCollage({ pid: selected_pid, keyword });
				}
			});
		} else if (type == 'editing') {
			await post('/shop/admin/schema/editing', { pid: selected_pid, data: foundCollage.data, id: foundCollage.id }).then(async (data) => {
				if (data && data.data && data.data.success) {
					this.setState({ createNewCollage: false });
					await this.editCollage({ pid: selected_pid, keyword });
				}
			});
		}

		this.setState({ loader: false });
	}

	async addShop(shop) {
		let body = { pid: shop.pid, keyword: shop.keyword };
		await post('/shop/admin/createSchema', body).then((data) => {
			if (data && data.data) {
				this.getData();
			}
		});
		if (shop && shop.swc) this.setState({ shop: shop, open: true });
	}

	async saveUserSettings(i, type) {
		let { shop } = this.state;
		if (shop && shop.swc && shop.swc.telegramList && type == 'telegram') {
			let { swc } = shop;
			let { telegramList } = swc;
			this.setState({ edited_index: i, edited_value: telegramList[i] });
		}
		if (shop && shop.swc && shop.swc.whatsappList && type == 'whatsapp') {
			let { swc } = shop;
			let { whatsappList } = swc;
			this.setState({ edited_index: i, edited_value: whatsappList[i] });
		}
		if (type == 'all') {
			this.setState({ btn_loading: true });
			let alert_counter = 0;
			let { server_config, swc } = shop;
			let { numberList, numberListTarget, telegramList, whatsappList, phoneIdList } = swc;
			if (!_.isArray(phoneIdList)) {
				this.setState({ alert_: 'Ayarlar -> phoneIdList -> Check', btn_loading: false });
			}
			numberList =
				numberList && numberList.length > 0
					? _.map(numberList, (e, i) => {
							if (e.new == true) {
								delete e.new;
							}
							return e;
					  })
					: numberList;
			shop.swc.numberList = numberList;

			numberListTarget =
				numberListTarget && numberListTarget.length > 0
					? _.map(numberListTarget, (e, i) => {
							if (e.new == true) {
								delete e.new;
								delete e.type;
							}
							if (e.source == '') {
								alert_counter++;
							}
							if (e.number == '') {
								alert_counter++;
							}
							return e;
					  })
					: numberListTarget;
			shop.swc.numberListTarget = numberListTarget;

			telegramList =
				telegramList && telegramList.length > 0
					? _.map(telegramList, (e, i) => {
							if (e.new == true) {
								delete e.new;
								delete e.number;
							}
							return e;
					  })
					: telegramList;
			shop.swc.telegramList = telegramList;

			whatsappList =
				whatsappList && whatsappList.length > 0
					? _.map(whatsappList, (e, i) => {
							if (e.new == true) {
								delete e.new;
								delete e.type;
							}
							if (!e.number) {
								alert_counter++;
							}
							if (e.source == '') {
								alert_counter++;
							}
							return e;
					  })
					: whatsappList;
			shop.swc.whatsappList = whatsappList;
			if (alert_counter < 1) {
				//save
				await this.saveFirmSettings();
			} else {
				this.setState({ btn_loading: false, alert_: 'Hatalı Eksik Bilgi Bulunmaktadır.' });
			}
			setTimeout(() => {
				this.setState({ btn_loading: false, alert_: '' });
			}, 5000);
		}
	}

	async addMasterGroup(i, type) {
		let { shop } = this.state;
		let { server_config } = shop;
		if (type == 'whatsapp') {
			let found_whatsapp = _.find(shop.swc.numberList, (e, i) => {
				return e.type == 'whatsapp';
			});
			shop.swc.whatsappList[i].source = (found_whatsapp && found_whatsapp.source) || '';
			shop.swc.numberListTarget.unshift(shop.swc.whatsappList[i]);
			shop.swc.whatsappList.splice(i, 1);
			if (shop.swc && shop.swc.whatsappList < 1) {
				shop.swc.whatsappList = [];
			}
		}
		if (type == 'telegram') {
			shop.swc.telegramList[i].number = server_config.telegram_bot_name || '';
			shop.swc.numberList.unshift(shop.swc.telegramList[i]);
			shop.swc.telegramList.splice(i, 1);
			if (shop.swc && shop.swc.telegramList < 1) {
				shop.swc.telegramList = [];
			}
		}
		if (type == 'whatsapp_us') {
			let found_whatsapp = _.find(shop.swc.numberList, (e, i) => {
				return e.type == 'whatsapp';
			});

			if (found_whatsapp) {
				this.setState({ whatsapp_alert: 'Zaten grubumuz bulunmaktadır. Onu editleyebilirsiniz.' });

				setTimeout(() => {
					this.setState({ whatsapp_alert: '' });
				}, 5000);
			} else {
				if (shop.swc.telegramList && shop.swc.telegramList.length > 0) shop.swc.telegramList[i].number = '';
				if (shop.swc.whatsappList && shop.swc.whatsappList[i]) {
					shop.swc.whatsappList[i].group_id = shop.swc.whatsappList[i].group_id + '';
					if (shop.swc.whatsappList[i].source == '') {
						delete shop.swc.whatsappList[i].source;
					}
				}
				shop.swc.numberList.unshift(shop.swc.whatsappList[i]);
				shop.swc.whatsappList.splice(i, 1);
				if (shop.swc && shop.swc.whatsappList < 1) {
					shop.swc.whatsappList = [];
				}
			}
		}
		this.setState({ shop });
	}

	async getFolderId(data) {
		let new_shop = this.state.shop;
		let { pid, value } = data;
		await post(`/shop/admin/folder/get`, { pid }).then((data) => {
			if (data && data.data && data.data.result) {
				let result = data.data.result.id;
				new_shop.server_config[value] = result;
				this.setState({ shop: new_shop });
			}
		});
	}
	handlechangeValue = (e, { name, value }) => {
		let new_shop = this.state.shop;
		let { server_config } = new_shop;
		server_config[name] = value;
		this.setState({ shop: new_shop });
	};

	handleChangeChecked = (e, { name, value }) => {
		if (!value) value = false;
		let new_shop = this.state.shop;
		new_shop.server_config[name] = !value;
		this.setState({ shop: new_shop });
	};

	addNewTelegram = (index) => {
		let { shop } = this.state;

		let { chat } = index;
		let { id, title } = chat;

		shop.swc.telegramList.unshift({
			group_id: id + '',
			name: title,
			phone_id: shop.swc.telegram_id,
			telegram_id: shop.swc.telegram_id,
			type: 'telegram',
			new: true,
		});
		this.setState({ shop });
	};

	addNewWhatsapp = (index) => {
		let { shop } = this.state;
		let { swc } = shop;
		let { id, name } = index;
		let found_whatsapp = _.find(swc.numberList, (e, i) => {
			return e.type == 'whatsapp';
		});

		shop.swc.whatsappList.unshift({
			group_id: id,
			name,
			phone_id: swc.whatsapp_id,
			source: (found_whatsapp && found_whatsapp.group_id) || '',
			type: 'whatsapp',
			new: true,
		});
		this.setState({ shop });
	};

	render() {
		let { t } = this.props;
		let {
			loading,
			foundCollage,
			editing,
			collages,
			data,
			shop,
			open,
			telegram_alert,
			edited_value,
			edited_index,
			telegram_data,
			whatsapp_data,
			whatsapp_alert,
			openCollage,
			createNewCollage,
			newCollage,
			keyword,
			loader,
		} = this.state;

		if (!data) {
			return <Page.Loader></Page.Loader>;
		}
		return (
			<div>
				<Page.Wrap>
					<Segment>
						{/* <Button labelPosition="left" color="teal" icon as={'a'} href={`#`} target="_blank" rel="noopener noreferrer">
							<Icon name="eye"></Icon>
							{'Önizleme'}
						</Button> */}
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={3}>{t('name')}</Table.HeaderCell>
									<Table.HeaderCell width={2} style={{ textAlign: 'right' }}>
										{t('Edit')}
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(data, (e, i) => {
									return (
										<Table.Row key={i} style={{ background: i % 2 ? 'white' : '#f5f3f3' }}>
											<Table.Cell>{e.keyword}</Table.Cell>
											<Table.Cell width={2} style={{ textAlign: 'right' }}>
												<Button
													labelPosition="left"
													color="teal"
													icon
													size={'small'}
													style={{ width: 100, marginRight: 10 }}
													onClick={() => {
														this.editCollage(e);
													}}
												>
													<Icon name="clipboard"></Icon>
													{'Kolaj'}
												</Button>
												{e && e.swc ? (
													<Button
														labelPosition="left"
														color="gray"
														icon
														size={'small'}
														style={{ width: 125 }}
														onClick={() => {
															this.editShop(e);
														}}
													>
														<Icon name="edit outline"></Icon>
														{t('Edit')}
													</Button>
												) : (
													<Button
														labelPosition="left"
														color="red"
														icon
														size={'small'}
														style={{ width: 125 }}
														onClick={() => {
															this.addShop(e);
														}}
													>
														<Icon name="edit outline"></Icon>
														{t('Ekle')}
													</Button>
												)}
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</Segment>
				</Page.Wrap>
				<Modal open={open}>
					<Modal.Header>
						{shop.keyword} {this.state.alert_ && <a style={{ color: 'red' }}>- {this.state.alert_}</a>}
					</Modal.Header>
					<Modal.Content>
						<Modal.Description>
							<Tab
								menu={{ secondary: true }}
								panes={[
									{
										menuItem: 'Whatsapp',
										render: () => (
											<Tab.Pane attached={false}>
												<div style={{ height: '215px', overflowY: 'auto' }}>
													<Divider horizontal>Yeni Whatsapp Kanalı Ekle</Divider>
													<Message
														info
														header="Ne durumda ne yapılmalı?"
														list={[
															'Whatsapp isimleri filezilla ile aynı olmalı',
															'Filezilladaki isimlerin hepsi küçük karakter olmalı ve türkçe karakter içermemeli!',
															'Grup değiştiyse "yeni telegram kanallarını çek" butonuna basıp orada yeni eklenen değerlerle güncellenmeli!',
														]}
													/>
													<div>
														<Button icon="checkmark" positive onClick={() => this.getNewWhatsapp()}>
															Yeni Whatsapp Kanallarını Çek
														</Button>
														{whatsapp_alert && <Message negative>{whatsapp_alert}</Message>}
														{whatsapp_data && whatsapp_data.length > 0 && (
															<div>
																<Divider horizontal>Eklenebilir Whatsapp Kanalları</Divider>
																<div style={{ display: 'flex', flexDirection: 'row' }}>
																	{_.map(whatsapp_data, (e, i) => {
																		return (
																			<div
																				style={{
																					height: '100px',
																					border: '1px solid #dbdbdb',
																					padding: '5px',
																					margin: '10px',
																					flex: 'auto',
																					borderRadius: '6px',
																				}}
																			>
																				<div>Grup Adı: {e.name}</div>
																				<div>Grup Id: {e.id}</div>
																				<div>
																					<Button
																						positive
																						disabled={
																							!!_.find(shop.swc.whatsappList, (a, i) => {
																								return a.group_id == e.id;
																							}) ||
																							!!_.find(shop.swc.numberListTarget, (c, t) => {
																								return e.id == c.group_id;
																							})
																						}
																						style={{ marginTop: '13px' }}
																						onClick={() => {
																							this.addNewWhatsapp(e);
																						}}
																					>
																						Ekle
																					</Button>
																				</div>
																			</div>
																		);
																	})}
																</div>
															</div>
														)}
													</div>
												</div>
												<Divider horizontal>Kanallar</Divider>
												{shop && shop.swc && shop.swc.whatsappList ? (
													<div className="main-telegram">
														{shop.swc.whatsappList.map((value, i) => (
															<div className="telegram-div" style={{ background: value.new ? '#dfffdf' : 'white' }}>
																{edited_index == i ? (
																	<div className="child-telegram" key={i}>
																		<div>
																			<b>Whatsapp Adı(Filezilla ismi):</b>
																			<Input
																				style={{ width: '100%' }}
																				fluid
																				name="name"
																				value={value.name}
																				onChange={this.handlechangeWhatsapp}
																			></Input>
																		</div>
																		<div>
																			<b>Phone Id:</b>
																			<Input
																				disabled={true}
																				style={{ width: '100%' }}
																				fluid
																				name="phone_id"
																				value={value.phone_id}
																				onChange={this.handlechangeWhatsapp}
																			></Input>
																		</div>
																		<div>
																			<b>Grup Id:</b>
																			<Input
																				style={{ width: '100%' }}
																				fluid
																				name="group_id"
																				value={value.group_id}
																				onChange={this.handlechangeWhatsapp}
																			></Input>
																		</div>
																		<div>
																			<b>Number:</b>
																			<Input
																				style={{ width: '100%' }}
																				fluid
																				name="number"
																				value={value.number}
																				onChange={this.handlechangeWhatsapp}
																			></Input>
																		</div>
																		<div>
																			<b>Source(Bizim Whatsapp Grup Idsi):</b>
																			<Input
																				style={{ width: '100%' }}
																				fluid
																				name="source"
																				value={value.source}
																				onChange={this.handlechangeWhatsapp}
																			></Input>
																		</div>
																	</div>
																) : (
																	<div className="child-telegram" key={i}>
																		<div>
																			<b>Whatsapp Adı(Filezilla ismi):</b> {value.name}
																		</div>
																		<div>
																			<b>Phone Id:</b> {value.phone_id}
																		</div>
																		<div>
																			<b>Grup Id:</b> {value.group_id}
																		</div>
																		<div>
																			<b>Number:</b> {value.number}
																		</div>
																		<div>
																			<b>Source(Bizim Whatsapp Grup Idsi):</b> {value.source}
																		</div>
																	</div>
																)}

																<div style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: 20 }}>
																	{edited_index == i ? (
																		<Button
																			color="red"
																			onClick={() => {
																				this.setState({ edited_index: null, edited_value: null });
																			}}
																		>
																			Kapat
																		</Button>
																	) : (
																		<>
																			<Button warning onClick={() => this.addMasterGroup(i, 'whatsapp_us')}>
																				Bizim Grup
																			</Button>
																			<Button onClick={() => this.addMasterGroup(i, 'whatsapp')}>Ana Grup</Button>
																			<Button positive onClick={() => this.saveUserSettings(i, 'whatsapp')}>
																				Düzenle
																			</Button>
																		</>
																	)}

																	{/* <Button
																		color="red"
																		onClick={() => {
																			this.setState({ open: false });
																		}}
																	>
																		Sil
																	</Button> */}
																</div>
															</div>
														))}
													</div>
												) : (
													<div style={{ textAlign: 'center' }}>Ekli Whatsapp Grup Bulunamamaktadır.</div>
												)}
											</Tab.Pane>
										),
									},
									{
										menuItem: 'Whatsapp Ana Grup',
										render: () => (
											<Tab.Pane attached={false}>
												<div style={{ overflowX: 'overlay', height: '555px' }}>
													<Divider horizontal>Ana Kanallar</Divider>
													{shop && shop.swc && shop.swc.numberListTarget ? (
														<div>
															{shop.swc.numberListTarget.map((value, i) => (
																<div className="telegram-div" style={{ background: value.new ? '#dfffdf' : 'white' }}>
																	{edited_index == i ? (
																		<div className="child-telegram" key={i}>
																			<div>
																				<b>Grup Adı:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="name"
																					value={value.name}
																					onChange={this.handlechangeTelegramAndWhatsapp}
																				></Input>
																			</div>
																			<div>
																				<b>Phone Id:</b>
																				<Input
																					disabled={true}
																					style={{ width: '100%' }}
																					fluid
																					name="phone_id"
																					value={value.phone_id}
																					onChange={this.handlechangeTelegramAndWhatsapp}
																				></Input>
																			</div>
																			<div>
																				<b>Number:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="number"
																					value={value.number}
																					onChange={this.handlechangeTelegramAndWhatsapp}
																				></Input>
																			</div>
																			<div>
																				<b>Grup Id:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="group_id"
																					value={value.group_id}
																					onChange={this.handlechangeTelegramAndWhatsapp}
																				></Input>
																			</div>
																			<div>
																				<b>Source(Bizim Whatsapp Grup Idsi):</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="source"
																					value={value.source}
																					onChange={this.handlechangeTelegramAndWhatsapp}
																				></Input>
																			</div>
																		</div>
																	) : (
																		<div className="child-telegram" key={i}>
																			<div>
																				<b>Grup Adı:</b> {value.name}
																			</div>
																			<div>
																				<b>Phone Id:</b> {value.phone_id}
																			</div>
																			<div>
																				<b>Number:</b> {value.number}
																			</div>
																			<div>
																				<b>Grup Id:</b> {value.group_id}
																			</div>
																			<div>
																				<b>Source(Bizim Whatsapp Grup Idsi):</b> {value.source}
																			</div>
																		</div>
																	)}

																	<div style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: 20 }}>
																		{edited_index == i ? (
																			<Button
																				color="red"
																				onClick={() => {
																					this.setState({ edited_index: null, edited_value: null });
																				}}
																			>
																				Kapat
																			</Button>
																		) : (
																			<Button positive onClick={() => this.saveUserSettings(i, 'telegram')}>
																				Düzenle
																			</Button>
																		)}

																		{/* <Button
																		color="red"
																		onClick={() => {
																			this.setState({ open: false });
																		}}
																	>
																		Sil
																	</Button> */}
																	</div>
																</div>
															))}
														</div>
													) : (
														<div style={{ textAlign: 'center' }}>Ekli Whatsapp Ana Grup Bulunamamaktadır.</div>
													)}
												</div>
											</Tab.Pane>
										),
									},
									{
										menuItem: 'Telegram',
										render: () => (
											<Tab.Pane attached={false}>
												<div style={{ height: '215px', overflowY: 'auto' }}>
													<Divider horizontal>Yeni Telegram Kanalı Ekle</Divider>
													<Message
														info
														header="Ne durumda ne yapılmalı?"
														list={[
															'Telegram isimleri filezilla ile aynı olmalı',
															'Filezilladaki isimlerin hepsi küçük karakter olmalı ve türkçe karakter içermemeli!',
															'Grup değiştiyse "yeni telegram kanallarını çek" butonuna basıp orada yeni eklenen değerlerle güncellenmeli!',
														]}
													/>
													<div>
														<Button icon="checkmark" positive onClick={() => this.getNewTelegram()}>
															Yeni Telegram Kanallarını Çek
														</Button>
														{telegram_alert && <Message negative>{telegram_alert}</Message>}
														{telegram_data && telegram_data.length > 0 && (
															<div>
																<Divider horizontal>Eklenebilir Telegram Kanalları</Divider>
																<div style={{ display: 'flex', flexDirection: 'row' }}>
																	{_.map(telegram_data, (e, i) => {
																		return (
																			<div
																				style={{
																					height: '100px',
																					border: '1px solid #dbdbdb',
																					padding: '5px',
																					margin: '10px',
																					flex: 'auto',
																					borderRadius: '6px',
																				}}
																			>
																				<div>Grup Adı: {e.body ? e.body.chat.title : e.data.chat.title}</div>
																				<div>Grup Id: {e.body ? e.body.chat.id : e.data.chat.id}</div>
																				<div>
																					<Button
																						positive
																						disabled={
																							!!_.find(shop.swc.telegramList, (a, i) => {
																								let chat_id = e.body ? e.body.chat.id : e.data.chat.id;
																								return a.group_id == chat_id;
																							})
																						}
																						style={{ marginTop: '13px' }}
																						onClick={() => {
																							this.addNewTelegram(e.body ? e.body : e.data);
																						}}
																					>
																						Ekle
																					</Button>
																				</div>
																			</div>
																		);
																	})}
																</div>
															</div>
														)}
													</div>
												</div>
												<Divider horizontal>Kanallar</Divider>
												{shop && shop.swc && shop.swc.telegramList ? (
													<div className="main-telegram">
														{shop.swc.telegramList.map((value, i) => (
															<div className="telegram-div" style={{ background: value.new ? '#dfffdf' : 'white' }}>
																{edited_index == i ? (
																	<div className="child-telegram" key={i}>
																		<div>
																			<b>Telegram Adı(Filezilla ismi):</b>
																			<Input
																				style={{ width: '100%' }}
																				fluid
																				name="name"
																				value={value.name}
																				onChange={this.handlechangeTelegram}
																			></Input>
																		</div>
																		<div>
																			<b>Phone Id:</b>
																			<Input
																				disabled={true}
																				style={{ width: '100%' }}
																				fluid
																				name="phone_id"
																				value={value.phone_id}
																				onChange={this.handlechangeTelegram}
																			></Input>
																		</div>
																		<div>
																			<b>Grup Id:</b>
																			<Input
																				style={{ width: '100%' }}
																				fluid
																				name="group_id"
																				value={value.group_id}
																				onChange={this.handlechangeTelegram}
																			></Input>
																		</div>
																	</div>
																) : (
																	<div className="child-telegram" key={i}>
																		<div>
																			<b>Telegram Adı(Filezilla ismi):</b> {value.name}
																		</div>
																		<div>
																			<b>Phone Id:</b> {value.phone_id}
																		</div>
																		<div>
																			<b>Grup Id:</b> {value.group_id}
																		</div>
																	</div>
																)}

																<div style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: 20 }}>
																	{edited_index == i ? (
																		<Button
																			color="red"
																			onClick={() => {
																				this.setState({ edited_index: null, edited_value: null });
																			}}
																		>
																			Kapat
																		</Button>
																	) : (
																		<>
																			<Button onClick={() => this.addMasterGroup(i, 'telegram')}>Ana Grup</Button>
																			<Button positive onClick={() => this.saveUserSettings(i, 'telegram')}>
																				Düzenle
																			</Button>
																		</>
																	)}

																	{/* <Button
																		color="red"
																		onClick={() => {
																			this.setState({ open: false });
																		}}
																	>
																		Sil
																	</Button> */}
																</div>
															</div>
														))}
													</div>
												) : (
													<div style={{ textAlign: 'center' }}>Ekli Telegram Kanalı Bulunamamaktadır.</div>
												)}
											</Tab.Pane>
										),
									},
									{
										menuItem: 'Telegram Ana Grup & Whatsapp Bizim Grup',
										render: () => (
											<Tab.Pane attached={false}>
												<div style={{ overflowX: 'overlay', height: '555px' }}>
													<Divider horizontal>Ana Kanallar</Divider>
													{shop && shop.swc && shop.swc.numberList ? (
														<div>
															{shop.swc.numberList.map((value, i) => (
																<div className="telegram-div" style={{ background: value.new ? '#dfffdf' : 'white' }}>
																	{edited_index == i ? (
																		<div className="child-telegram" key={i}>
																			<div>
																				<b>Grup Adı:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="name"
																					value={value.name}
																					onChange={this.handlechangeNumberlist}
																				></Input>
																			</div>
																			<div>
																				<b>Phone Id:</b>
																				<Input
																					disabled={true}
																					style={{ width: '100%' }}
																					fluid
																					name="phone_id"
																					value={value.phone_id}
																					onChange={this.handlechangeNumberlist}
																				></Input>
																			</div>
																			<div>
																				<b>Number:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="number"
																					value={value.number}
																					onChange={this.handlechangeNumberlist}
																				></Input>
																			</div>
																			<div>
																				<b>Grup Id:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="group_id"
																					value={value.group_id}
																					onChange={this.handlechangeNumberlist}
																				></Input>
																			</div>
																			<div>
																				<b>Tip:</b>
																				<Input
																					style={{ width: '100%' }}
																					fluid
																					name="type"
																					value={value.type}
																					onChange={this.handlechangeNumberlist}
																				></Input>
																			</div>
																		</div>
																	) : (
																		<div className="child-telegram" key={i}>
																			<div>
																				<b>Grup Adı:</b> {value.name}
																			</div>
																			<div>
																				<b>Phone Id:</b> {value.phone_id}
																			</div>
																			<div>
																				<b>Number:</b> {value.number}
																			</div>
																			<div>
																				<b>Grup Id:</b> {value.group_id}
																			</div>
																			<div>
																				<b>Tip:</b> {value.type}
																			</div>
																		</div>
																	)}

																	<div style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: 20 }}>
																		{edited_index == i ? (
																			<Button
																				color="red"
																				onClick={() => {
																					this.setState({ edited_index: null, edited_value: null });
																				}}
																			>
																				Kapat
																			</Button>
																		) : (
																			<Button positive onClick={() => this.saveUserSettings(i, 'telegram')}>
																				Düzenle
																			</Button>
																		)}

																		{/* <Button
																		color="red"
																		onClick={() => {
																			this.setState({ open: false });
																		}}
																	>
																		Sil
																	</Button> */}
																	</div>
																</div>
															))}
														</div>
													) : (
														<div style={{ textAlign: 'center' }}>Ekli Whatsapp Ana Grup Bulunamamaktadır.</div>
													)}
												</div>
											</Tab.Pane>
										),
									},
									{
										menuItem: 'Ayarlar',
										render: () => (
											<Tab.Pane attached={false}>
												<div style={{ overflowX: 'overlay', height: '555px' }}>
													<Divider horizontal>Shop Ayarları</Divider>
													<Table celled>
														<Table.Header>
															<Table.Row>
																<Table.HeaderCell style={{ width: '20%' }}>{t('settings')}</Table.HeaderCell>
																<Table.HeaderCell>{t('value')}</Table.HeaderCell>
																<Table.HeaderCell style={{ width: '30%' }}>{t('description')}</Table.HeaderCell>
															</Table.Row>
														</Table.Header>
														<Table.Body className="settingsClass" style={{ fontWeight: '800' }}>
															{_.map(settings, (e, i) => {
																return (
																	<Table.Row>
																		<Table.Cell>{e.name}</Table.Cell>
																		<Table.Cell>
																			{e.type == 'input' && (
																				<Form.Input
																					style={{ width: '50%' }}
																					fluid
																					name={e.value}
																					value={shop.server_config[e.value]}
																					onChange={this.handlechangeValue}
																				></Form.Input>
																			)}
																			{e.type == 'switch' && (
																				<Checkbox
																					style={{ margin: '10px' }}
																					toggle
																					// label="Table Menu"
																					name={e.value}
																					checked={shop.server_config[e.value]}
																					value={shop.server_config[e.value]}
																					onClick={this.handleChangeChecked}
																				/>
																			)}
																			{e.extra && (
																				<Button
																					style={{ marginTop: 10 }}
																					positive
																					onClick={() => this.getFolderId({ pid: shop.pid, value: 'folder_id' })}
																				>
																					Folder Id Getir
																				</Button>
																			)}
																		</Table.Cell>
																		<Table.Cell>{e.desc}</Table.Cell>
																	</Table.Row>
																);
															})}
														</Table.Body>
													</Table>
													<Divider horizontal>Grup Ayarları</Divider>
													<Table celled style={{ width: '99%' }}>
														<Table.Header>
															<Table.Row>
																<Table.HeaderCell style={{ width: '20%' }}>{t('settings')}</Table.HeaderCell>
																<Table.HeaderCell>{t('value')}</Table.HeaderCell>
																<Table.HeaderCell style={{ width: '30%' }}>{t('description')}</Table.HeaderCell>
															</Table.Row>
														</Table.Header>
														<Table.Body className="settingsClass" style={{ fontWeight: '800' }}>
															{_.map(config_settings, (e, i) => {
																return (
																	<Table.Row>
																		<Table.Cell>{e.name}</Table.Cell>
																		<Table.Cell>
																			<Form.Input
																				style={{ width: '50%' }}
																				fluid
																				name={e.value}
																				value={
																					e.value == 'x-maytapi-key'
																						? (this.state.shop.swc &&
																								this.state.shop.swc.headers &&
																								this.state.shop.swc.headers[e.value]) ||
																						  ''
																						: (this.state.shop.swc && this.state.shop.swc[e.value]) || ''
																				}
																				onChange={this.handlechangeSWC}
																			></Form.Input>
																			{e.name == 'phoneIdList' && (
																				<Button
																					style={{ marginTop: 10 }}
																					positive
																					onClick={() => this.editPhoneIdList()}
																				>
																					Check
																				</Button>
																			)}
																		</Table.Cell>
																		<Table.Cell>{e.desc}</Table.Cell>
																	</Table.Row>
																);
															})}
														</Table.Body>
													</Table>
												</div>
											</Tab.Pane>
										),
									},
								]}
							/>
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						<Button
							color="red"
							onClick={() => {
								this.resetStates();
							}}
						>
							Vazgeç
						</Button>
						<Button loading={this.state.btn_loading} icon="checkmark" positive onClick={() => this.saveUserSettings(null, 'all')}>
							Kaydet
						</Button>
					</Modal.Actions>
				</Modal>
				<Modal open={openCollage}>
					<Modal.Header>{keyword} </Modal.Header>
					<Modal.Content>
						<Modal.Description>
							{editing ? (
								<>
									<a>{foundCollage.data.name}</a>
									<TextArea
										style={{ borderWidth: 1, borderColor: 'gray', borderRadius: 5, padding: 10, width: '100%', height: 300 }}
										multiline={true}
										value={JSON.stringify(foundCollage.data, null, 2)}
										onChange={(e, text) => {
											try {
												this.setState({ foundCollage: { ...foundCollage, data: JSON.parse(text.value) } });
											} catch (error) {
												console.error('Geçersiz JSON formatı', error);
											}
										}}
									/>
								</>
							) : (
								<>
									{createNewCollage ? (
										<>
											<TextArea
												style={{ borderWidth: 1, borderColor: 'gray', borderRadius: 5, padding: 10, width: '100%', height: 300 }}
												multiline={true}
												value={JSON.stringify(newCollage, null, 2)}
												onChange={(e, text) => {
													try {
														this.setState({ newCollage: JSON.parse(text.value) });
													} catch (error) {
														console.error('Geçersiz JSON formatı', error);
													}
												}}
											/>
										</>
									) : (
										<Button
											labelPosition="left"
											color="teal"
											icon
											size={'small'}
											onClick={() => {
												this.createNewCollage();
											}}
										>
											<Icon name="clipboard"></Icon>
											{'Yeni Kolaj Ekle'}
										</Button>
									)}
									{createNewCollage ? (
										<></>
									) : (
										<Table celled style={{ width: '99%' }}>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell style={{ width: '38.5%' }}>{t('name')}</Table.HeaderCell>
													<Table.HeaderCell style={{ width: '11.5%' }}>{t('Edit')}</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body className="settingsClass" style={{ fontWeight: '800' }}>
												{_.map(collages, (e) => {
													return (
														<Table.Row>
															<Table.Cell>{e.data.name}</Table.Cell>
															<Table.Cell>
																<Button
																	loading={loader}
																	disabled={loader}
																	color="teal"
																	onClick={() => {
																		this.editSchema(e.id);
																	}}
																>
																	{t('Edit')}
																</Button>
																<Button
																	loading={loader}
																	disabled={loader}
																	color="orange"
																	onClick={() => {
																		const confirmed = window.confirm(
																			`${e.data.name} adlı kolajı silmek istediğinizden emin misiniz?`
																		);
																		if (confirmed) {
																			this.deleteSchema(e.id);
																		} else {
																			console.log("Kullanıcı onaylamadı veya alert'i kapattı.");
																		}
																	}}
																>
																	{t('delete')}
																</Button>
															</Table.Cell>
														</Table.Row>
													);
												})}
											</Table.Body>
										</Table>
									)}
								</>
							)}
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions>
						{editing ? (
							<>
								<Button
									color="orange"
									loading={loader}
									disabled={loader}
									onClick={() => {
										this.closeDetailCollage();
									}}
								>
									Geri
								</Button>
								<Button loading={loader} disabled={loader} icon="checkmark" color="facebook" onClick={() => this.addCollage('editing')}>
									Kolajı Kaydet
								</Button>
							</>
						) : createNewCollage ? (
							<>
								<Button
									color="red"
									loading={loader}
									disabled={loader}
									onClick={() => {
										this.closeEditCollage();
									}}
								>
									Kapat
								</Button>
								<Button
									color="orange"
									loading={loader}
									disabled={loader}
									onClick={() => {
										this.closeCreatingCollage();
									}}
								>
									Geri
								</Button>
								<Button
									color="linkedin"
									loading={loader}
									disabled={loader}
									onClick={() => {
										this.addCollage('add');
									}}
								>
									Yeni Kolajı Ekle
								</Button>
							</>
						) : (
							<>
								<Button
									color="red"
									loading={loader}
									disabled={loader}
									onClick={() => {
										this.closeEditCollage();
									}}
								>
									Kapat
								</Button>
								{/* <Button loading={this.state.btn_loading} icon="checkmark" positive onClick={() => this.saveUserSettings(null, 'all')}>
									Kaydet
								</Button> */}
							</>
						)}
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}

export default withTranslation()(UserSettings);
