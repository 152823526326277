import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { Segment, Table, Breadcrumb, Icon, Button, Modal, Checkbox, Label, Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
class Folder extends Component {
	state = {
		loading: true,
		openModal: false,
		activeStep: 1,
		post_data: [],
	};
	componentDidMount() {
		this.getData();
	}
	onClose() {
		this.setState({ openModal: false, activeStep: 1, post_data: [], sub_folders: [] });
	}
	async importData() {
		this.setState({ loadingImport: true });
		let { post_data } = this.state;
		console.log(post_data);
		/*return; */
		try {
			await post('/shop/folder/import', { folder: post_data }).then((data) => {
				if (data.data.success) {
					window.location.reload();
				}
			});
		} catch (error) {
			window.alert('Error');
		}
	}
	async getData() {
		await post('/shop/folder/list', {}).then((data) => {
			this.setState({ data: data.data.result, loading: false });
		});
	}
	async getModalData() {
		this.setState({ importBtnLoad: true });
		let { keyword } = this.props;
		let shop = this.props.product.shop;
		let justOneMonth = shop.server_config.justOneMonth || false;
		await post('/shop/folder/list_all', { base_path: keyword, folders: true, files: true, justOneMonth }).then((data) => {
			//console.log(data);
			this.setState({ wg_data: data.data.result, openModal: true, importBtnLoad: false });
		});
	}
	async getModalSubData(id, subfolder) {
		let { post_data } = this.state;
		let { keyword } = this.props;
		let shop = this.props.product.shop;
		let group_by_name = shop.server_config.group_by_name; ///^\w+_\w+_(\w+-(.*)?\w+|)_\d+\.\d+/; //
		let group_by_name_no_group = shop.server_config.group_by_name_no_group;
		let justOneMonth = shop.server_config.justOneMonth || false;
		group_by_name = group_by_name && new RegExp(group_by_name);
		let post_object = { name: subfolder, path: keyword + '/' + subfolder };
		//console.log('post_object', post_object);
		if (id) post_object.id = id;
		post_data.push(post_object);
		this.setState({ post_data });
		if (id) {
			await post('/shop/subfolder/list', { folder_id: id }).then((folders) => {
				let sub_folders = folders.data.result;
				this.setState({ sub_folders });
			});
			await post('/shop/kolaj/list_all', {
				base_path: keyword + '/' + subfolder,
				folders: true,
				files: true,
				folder_id: id,
				onlyActive: false,
				justOneMonth,
			}).then((wgresult) => {
				let post_data_start = wgresult.data.result;
				this.setState({ post_data_start });
			});
		}
		await post('/shop/folder/list_all', { base_path: keyword + '/' + subfolder, folders: true, files: true, justOneMonth }).then((data) => {
			let wg_sub_data = data.data.result[0].folders;
			wg_sub_data = _.filter(wg_sub_data, (it) => it.files && it.files.length);
			console.log('wg_sub_data', wg_sub_data);
			this.setState({ wg_sub_data });
		});

		this.selectArray(group_by_name, group_by_name_no_group);
		this.setState({ activeStep: 2 });
	}
	selectArray(group_by_name, group_by_name_no_group) {
		let { wg_sub_data, sub_folders, post_data_start } = this.state;
		console.log('wg_sub_data', group_by_name);
		_.map(wg_sub_data, (it) => {
			let files = it.files;
			console.log('files', files);
			files = _.map(files, (it) => ({ name: it.name, path: it.path }));
			let group_images = _.groupBy(files, (o) => {
				if (group_by_name) {
					let fname = o.name.match(group_by_name);
					//console.log('fname', fname);
					if (fname && !group_by_name_no_group) o.name = fname[0];
					console.log('o.name;', o.name);
					return o.name;
				} else if (group_by_name_no_group) {
					return o.name;
				} else {
					if (/^L\d/.test(o.name)) {
						let fname = o.name.match(/L\d{13}/);
						if (fname) o.name = fname[0];
					} else if (/^[a-zA-Z]{1,4}\d{3,5}(\w+)?-(\d{1,3}-)?\w{3}/.test(o.name)) {
						let fname = o.name.match(/^[a-zA-Z]{1,4}\d{3,5}(\w+)?-(\d{1,3}-)?\w{3}/);
						if (fname) o.name = fname[0];
					} else if (/\d{5}[-\d\w\s.]*?_\d{2,3}/.test(o.name)) {
						let fname = o.name.match(/\d{5}[-\d\w\s.]*?_\d{2,3}/);
						if (fname) o.name = fname[0];
					}
					return o.name.replace(/_.*/, '');
				}
			});
			console.log('group_images', group_images);
			let group_images_arr = _.map(group_images, (it) => it);
			let wg_sub_item = _.find(sub_folders, (o) => o.data.name === it.name);
			console.log('group_images_arr', group_images_arr, wg_sub_item);
			if (_.find(post_data_start, { name: it.name })) {
				this.handleChangePostData(it, group_images_arr);
			}
		});
	}
	handleChangePostData(data, groups) {
		let { post_data } = this.state;
		if (!post_data[0].sub_folders) post_data[0].sub_folders = [];
		let name = data.name || data.data.name;
		let i = _.findIndex(post_data[0].sub_folders, (it) => it.name === name);
		if (i >= 0) {
			_.pullAt(post_data[0].sub_folders, [i]);
		} else {
			post_data[0].sub_folders.push({ name: data.name || data.data.name, path: data.path || data.data.path, kolaj: groups });
		}
		this.setState({ post_data });
	}
	render() {
		const { t } = this.props;
		let shop = this.props.product.shop;
		let group_by_name = shop.server_config.group_by_name;
		let group_by_name_no_group = shop.server_config.group_by_name_no_group;
		let { loading, data, openModal, wg_data, activeStep, sub_folders, wg_sub_data, post_data, loadingImport, importBtnLoad } = this.state;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);

		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap>
					<Segment>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('name')}</Table.HeaderCell>
									<Table.HeaderCell>{t('date')}</Table.HeaderCell>
									<Table.HeaderCell width={6} textAlign="center">
										{t('status')}
									</Table.HeaderCell>
									<Table.HeaderCell width={1}>
										<Button
											disabled={shop && shop.server_config && shop.server_config.ftp_key ? false : true}
											primary
											onClick={() => this.getModalData()}
											loading={importBtnLoad}
										>
											{t('import')}
										</Button>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(data, (it, i) => {
									if (it.kolaj && it.kolaj[0] && it.kolaj[0].status) {
										let done_table = _.filter(it.kolaj, (o) => o.status === 'done');
										let failed_table = _.filter(it.kolaj, (o) => o.status === 'failed');
										let verified_table = _.filter(it.kolaj, (o) => o.status === 'verified');
										let pending_table = _.filter(it.kolaj, (o) => o.status === 'pending');
										return (
											<Table.Row key={i}>
												<Table.Cell>
													<Link to={'/whatsapp-shop/' + it.id}>{it.data.name}</Link>
												</Table.Cell>
												<Table.Cell>{moment(it.updated_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
												<Table.Cell style={{ textAlign: 'right' }}>
													{pending_table && _.sumBy(pending_table, 'count') ? (
														<Label color="orange" image>
															İşlenen
															<Label.Detail>{_.sumBy(pending_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{failed_table && _.sumBy(failed_table, 'count') ? (
														<Label color="red" image>
															Eksik
															<Label.Detail>{_.sumBy(failed_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{done_table && _.sumBy(done_table, 'count') ? (
														<Label color="yellow" image>
															Bekleyen
															<Label.Detail>{_.sumBy(done_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{verified_table && _.sumBy(verified_table, 'count') ? (
														<Label color="green" image>
															Onaylanan
															<Label.Detail>{_.sumBy(verified_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													<Label className="label-fixed-size" color="blue" image>
														Toplam
														<Label.Detail style={{ width: '34px' }}>{it.count}</Label.Detail>
													</Label>
												</Table.Cell>
												<Table.Cell></Table.Cell>
											</Table.Row>
										);
									}
								})}
							</Table.Body>
						</Table>
					</Segment>
					{shop && shop.server_config && shop.server_config.ftp_key ? (
						''
					) : (
						<Message negative>
							<h2>
								{t('donthaveFtpKey1')} <strong>info@whatsapp-shop.com </strong>
							</h2>
						</Message>
					)}

					<Modal className="import-modal" open={openModal} closeIcon onClose={() => this.onClose()}>
						<Modal.Header>{t('import')}</Modal.Header>
						{activeStep === 1 ? (
							<Modal.Content scrolling>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell>{t('importCount')}</Table.HeaderCell>
											<Table.HeaderCell>{t('totalCount')}</Table.HeaderCell>
											<Table.HeaderCell width={3}></Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(data, (it, i) => {
											let wg_item = _.find(wg_data, (o) => o.name === it.data.name);
											let folder_files = wg_item && wg_item.folders && _.filter(wg_item.folders, (it) => it.files && it.files.length);
											return (
												<Table.Row key={i}>
													<Table.Cell>{it.data.name}</Table.Cell>
													<Table.Cell>{it.count}</Table.Cell>
													<Table.Cell>{folder_files && folder_files.length}</Table.Cell>
													<Table.Cell>
														<Button
															primary
															disabled={(it && it.data.name === 'kolajsiz') || (it && it.data.name === 'kolajsız')}
															onClick={() => this.getModalSubData(it.id, it.data.name)}
														>
															{t('select')}
														</Button>
													</Table.Cell>
												</Table.Row>
											);
										})}
										{_.map(wg_data, (it, i) => {
											let data_item = _.find(data, (o) => o.data.name === it.name);
											if (!data_item)
												return (
													<Table.Row key={i}>
														<Table.Cell>{it.name}</Table.Cell>
														<Table.Cell>0</Table.Cell>
														<Table.Cell>{it.folders.length}</Table.Cell>
														<Table.Cell>
															<Button
																primary
																disabled={(it && it.name === 'kolajsiz') || (it && it.name === 'kolajsız')}
																onClick={() => this.getModalSubData(null, it.name)}
															>
																{t('select')}
															</Button>
														</Table.Cell>
													</Table.Row>
												);
										})}
									</Table.Body>
								</Table>
							</Modal.Content>
						) : (
							<Modal.Content>
								<Button basic labelPosition="left" color="grey" icon onClick={() => this.setState({ activeStep: 1 })}>
									<Icon name="arrow left"></Icon>
									{t('back')}
								</Button>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell></Table.HeaderCell>
											<Table.HeaderCell>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell>{t('importCount')}</Table.HeaderCell>
											<Table.HeaderCell>{t('totalCount')}</Table.HeaderCell>
											<Table.HeaderCell width={3}>
												<Button primary loading={loadingImport} onClick={() => this.importData()}>
													{t('import')}
												</Button>
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(sub_folders, (it, i) => {
											let wg_sub_item = _.find(wg_sub_data, (o) => o.name === it.data.name);
											let files = wg_sub_item && wg_sub_item.files;
											//let group_images = _.groupBy(files, (o) => o.name.replace(/_.*/, ''));
											let group_images = _.groupBy(files, (o) => {
												if (group_by_name) {
													let fname = o.name.match(group_by_name);
													//console.log('fname', fname);
													if (fname && !group_by_name_no_group) o.name = fname[0];
													//console.log('o.name;', o.name);
													return o.name;
												} else if (group_by_name_no_group) {
													return o.name;
												} else {
													if (/^L\d/.test(o.name)) {
														let fname = o.name.match(/L\d{13}/);
														if (fname) o.name = fname[0];
													} else if (/^[a-zA-Z]{1,4}\d{3,5}(\w+)?-(\d{1,3}-)?\w{3}/.test(o.name)) {
														let fname = o.name.match(/^[a-zA-Z]{1,4}\d{3,5}(\w+)?-(\d{1,3}-)?\w{3}/);
														if (fname) o.name = fname[0];
													} else if (/\d{5}[-\d\w\s.]*?_\d{2,3}/.test(o.name)) {
														let fname = o.name.match(/\d{5}[-\d\w\s.]*?_\d{2,3}/);
														if (fname) o.name = fname[0];
													}
													return o.name.replace(/_.*/, '');
												}
											});
											let group_images_arr = _.map(group_images, (it) => it);
											let isChecked = post_data && post_data[0] && !!_.find(post_data[0].sub_folders, (o) => o.name === it.data.name);
											return (
												<Table.Row key={i}>
													<Table.Cell>
														<Checkbox
															checked={isChecked}
															onChange={() => this.handleChangePostData(it, group_images_arr)}
														></Checkbox>
													</Table.Cell>
													<Table.Cell>{it.data.name}</Table.Cell>
													<Table.Cell>{it.count}</Table.Cell>
													<Table.Cell>{group_images_arr.length}</Table.Cell>
													<Table.Cell></Table.Cell>
												</Table.Row>
											);
										})}
										{_.map(wg_sub_data, (it, i) => {
											let data_item = _.find(sub_folders, (o) => o.data.name === it.name);
											if (!data_item) {
												let files = it.files;
												let group_images = _.groupBy(files, (o) => o.name.replace(/_.*/, ''));
												let group_images_arr = _.map(group_images, (it) => it);
												let isChecked = post_data && post_data[0] && !!_.find(post_data[0].sub_folders, (o) => o.name === it.name);
												return (
													<Table.Row key={i}>
														<Table.Cell>
															<Checkbox
																checked={isChecked}
																onChange={() => this.handleChangePostData(it, group_images_arr)}
															></Checkbox>
														</Table.Cell>
														<Table.Cell>{it.name}</Table.Cell>
														<Table.Cell>0</Table.Cell>
														<Table.Cell>{group_images_arr.length}</Table.Cell>
														<Table.Cell></Table.Cell>
													</Table.Row>
												);
											}
										})}
									</Table.Body>
								</Table>
							</Modal.Content>
						)}
					</Modal>
				</Page.Wrap>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ product: state.product });

export default connect(stateToProps)(withTranslation()(Folder));
