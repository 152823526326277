import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Icon, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import { logout } from '../actions/access.actions';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const MarginZero = { fontSize: '1.25em', opacity: 0.7 };
const HeaderItem = (props) => {
	if (props.onClick) {
		if (props.flag) {
			return (
				<div className="header-item item" onClick={props.onClick} name={props.name}>
					<img src={`/icons/flags/${props.flag}.png`} alt={`/icons/flags/${props.flag}.png`} style={{ width: '18px' }}></img>
					<br></br>
					<p className="title"> {props.name}</p>
				</div>
			);
		}
		return (
			<div className="header-item item" onClick={props.onClick} name={props.name}>
				<Icon name={props.icon} style={MarginZero} />

				<span className="title">{props.name}</span>
			</div>
		);
	}
	return (
		<NavLink className="header-item item" {...props}>
			<Icon name={props.icon} style={MarginZero} />
			<span className="title">{props.name}</span>
		</NavLink>
	);
};

/* function ChangeLanguage() {
	const { i18n } = useTranslation();
	return (
		<HeaderItem
			onClick={() => {
				i18n.changeLanguage(i18n.language === 'tr' ? 'en' : 'tr');
			}}
			flag={i18n.language === 'tr' ? 'tr' : 'gb'}
			name={i18n.language === 'tr' ? 'TR' : 'EN'}
		/>
	);
} */
function ChangeLanguageNew() {
	const [openModal, setOpenModal] = useState(0);
	const { i18n } = useTranslation();
	let langs = [
		{ flag: 'gb', key: 'en', text: 'English' },
		{ flag: 'tr', key: 'tr', text: 'Türkçe' },
		{ flag: 'de', key: 'de', text: 'Deutsche' },
		{ flag: 'fr', key: 'fr', text: 'Français' },
		{ flag: 'es', key: 'es', text: 'Español' },
		{ flag: 'pt', key: 'pt', text: 'Português' },
		{ flag: 'ru', key: 'ru', text: 'Pусский' },
	];
	let selectedLang = _.find(langs, { key: i18n.languages[0] }) || _.find(langs, { key: 'en' });
	if (openModal) {
		return (
			<>
				<HeaderItem onClick={() => setOpenModal(true)} flag={selectedLang.flag} name={selectedLang.text} />
				<Modal
					open={openModal}
					closeIcon
					onClose={() => {
						setOpenModal(false);
					}}
				>
					<Modal.Content style={{ background: 'lightgray' }}>
						<div className="lang-modal" style={{ justifyContent: 'center' }}>
							{_.map(langs, (o) => {
								return (
									<div
										className="header-item item"
										key={o.key}
										name={o.text}
										style={{ textAlign: 'center', fontSize: '22px', marginLeft: '10px', marginRight: '10px' }}
										onClick={() => {
											i18n.changeLanguage(o.key);
											setOpenModal(false);
										}}
									>
										<img src={`/icons/flags/${o.flag}.png`} alt={`/icons/flags/${o.flag}.png`}></img>
										<br></br>
										<p className="title"> {o.text} </p>
									</div>
								);
							})}
						</div>
					</Modal.Content>
				</Modal>
			</>
		);
	}
	return <HeaderItem onClick={() => setOpenModal(true)} flag={selectedLang.flag} name={selectedLang.text} />;
}

/*<Menu.Item as={NavLink} to="/variables" name="Variables">
			<Icon name="dolly" />
			Variables
		</Menu.Item>*/

const Header = (props) => {
	const { t } = useTranslation();
	let { shop } = props;
	let isTelegram = shop.server_config && shop.server_config.telegram;
	let isWhatsapp = shop.server_config && shop.server_config.whatsapp;
	let isbroadcast = shop.server_config && shop.server_config.broadcast;
	let isYoutube=shop.client_config&&shop.client_config.show_videos
	return (
		<div className="ui vertical icon menu navbar">
			<Link className="item console-logo" to="/">
				<img src={window.CONFIG.icon} alt="" />
			</Link>
			<HeaderItem to="/whatsapp-shop" name="Kolaj" icon="block layout" />
			<HeaderItem exact to="/build" name="Build" icon="cart" />
			<HeaderItem exact to="/analytic" name="Analitik" icon="chart bar" />
			{isbroadcast && isWhatsapp && <HeaderItem to="/wgroup" name="Broadcast" icon="feed" />}
			{isbroadcast && isTelegram && <HeaderItem exact to="/telegram" name="Telegram" icon="telegram" />}
			{/* 	{isExtApp && <HeaderItem to="/appstore" name={t('Apps')} icon="table" />} */}
			<HeaderItem exact to="/brands" name={t('brands')} icon="list" />
			{isYoutube && <HeaderItem name="Reels" icon="youtube" to="reels"/>} 
			<div className="mt-auto" />
			{/* {isAgent || reseller ? null : <HeaderItem to="docs.dialogtab.com" name={t('docs')} icon="file" />} */}
			<div className="header-seperator"></div>
			<ChangeLanguageNew />
			<HeaderItem exact to="/settings" name={t('settings')} icon="cog" />
			<HeaderItem to="/account" name={t('myAccount')} icon="user" />
			<HeaderItem onClick={props.logoutUser} name={t('logout')} icon="log out" />
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	logoutUser: () => dispatch(logout()),
});
export default connect((state) => ({ shop: state.product.shop }), mapDispatchToProps)(Header);
