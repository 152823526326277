import React, { Component } from 'react';
import Page from '../../components/Page';
import Picker from '../../components/DateRangePicker';
import { Link } from 'react-router-dom';
import { post } from '../../util/api';
import { Segment, Table, Breadcrumb, Icon, Button, Popup, Grid, Header, Container } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import 'moment/min/locales';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);

const barOptions = {
	responsive: true,
	interaction: {
		intersect: false,
		axis: 'x',
	},
	plugins: {
		title: {
			display: true,
			text: (ctx) => 'Saatlik Eklenen Kolaj Sayısı',
		},
	},
};

function chartData(data) {
	var hourArr = getHourArray();
	let gdata = data || [];
	let labels = [];

	let hgroup = _.map(data, (it, i) => ({
		hour: parseInt(i),
		count: _.sumBy(it, (o) => parseInt(o.count)),
	}));
	_.each(hourArr, function (d, i) {
		if (_.findIndex(hgroup, { hour: d }) === -1) {
			hgroup.push({
				hour: d,
				count: 0,
			});
		}
	});
	hgroup = _.sortBy(hgroup, (it) => parseInt(it.hour));
	console.log(hgroup);
	let nc = [
		{
			label: 'Eklenen',
			data: _.map(hgroup, (item) => item.count),
			borderColor: '#E10840',
			backgroundColor: '#E10840',
			fill: false,
			stepped: true,
		},
	];
	console.log('nc', nc, labels);
	labels = hourArr;
	return {
		labels,
		datasets: nc,
	};
}
var getDateArray = function (start, end, format) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		if (format) {
			arr.push(moment(new Date(dt)).format(format));
		} else arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}
	arr = _.reverse(arr);
	return arr;
};

var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
class Folder extends Component {
	state = {
		loading: true,
		openModal: false,
		tableLoading: false,
		activeStep: 1,
		post_data: [],
		start_date: moment().subtract(0, 'day').startOf('day'),
		end_date: moment().subtract(0, 'day').endOf('day'),
		today: moment(),
	};
	componentDidMount() {
		this.getData();
	}

	async getData() {
		let { shop } = this.props;
		console.log(shop);
		this.setState({ tableLoading: true });
		let { start_date, end_date } = this.state;
		await post('/shop/analytic/added', {
			pid: shop.pid,
			start_date: moment(start_date).format('YYYY-MM-DD'),
			end_date: moment(end_date).format('YYYY-MM-DD'),
			type: 'day',
		}).then((data) => {
			let addedData = _.map(data.data.result, (it) => ({ ...it, added_count: it.count }));
			let g_addedData = _.groupBy(addedData, 'folder_name');
			this.setState({ addedData, g_addedData });
		});
		await post('/shop/analytic/added', {
			pid: shop.pid,
			start_date: moment(start_date).format('YYYY-MM-DD'),
			end_date: moment(end_date).add('1', 'd').format('YYYY-MM-DD'),
			type: 'hour',
		}).then((data) => {
			let hour_data = _.groupBy(data.data.result, (it) => moment(it.date).format('H'));
			this.setState({ hour_data });
		});
		await post('/shop/analytic/updated', {
			pid: shop.pid,
			end_date: moment(start_date).format('YYYY-MM-DD'),
		}).then((data) => {
			let updateData = _.map(data.data.result, (it) => ({ ...it, update_count: it.count }));
			this.setState({ updateData, loading: false, tableLoading: false });
		});
	}

	componentDidUpdate(oldProps, oldState) {
		const check_list = ['start_date', 'end_date'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (!_.isEqual(this.state[key], oldState[key])) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	timeChangeStart = (value) => {
		let { start_date } = this.state;
		start_date = moment(start_date).set({ hour: moment(value, 'HH:mm').format('HH'), minute: moment('00', 'mm').format('mm') });
		this.setState({ start_date });
	};
	timeChangeEnd = (value) => {
		let { end_date } = this.state;
		end_date = moment(end_date).set({ hour: moment(value, 'HH:mm').format('HH'), minute: moment('59', 'mm').format('mm') });
		this.setState({ end_date });
	};
	render() {
		const { t, i18n } = this.props;
		let { loading, addedData, updateData, start_date, end_date, tableLoading, g_addedData, hour_data } = this.state;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/analytic`}>
								<Icon name="chart bar" />
								{t('analytic')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		let main_data = _.concat(addedData, updateData);
		let unique_data = _.uniqBy(main_data, 'folder_name');
		let date_array = getDateArray(start_date, end_date);
		let group_updateData = _.groupBy(updateData, 'folder_name');
		let selectedLang = (i18n && i18n.languages && i18n.languages[0]) || 'tr-TR';
		let data = chartData(hour_data, start_date, end_date, this.state.selectedDate);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/analytic`}>
							<Icon name="chart bar" />
							{t('analytic')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap>
					<Segment>
						<Popup
							trigger={
								<Button
									primary
									style={{ marginRight: '10px', marginBottom: '10px' }}
									content={`${moment(start_date).locale(selectedLang).format('MMM DD, YYYY')} - ${moment(end_date)
										.locale(selectedLang)
										.format('MMM DD, YYYY')}`}
									floated="right"
								/>
							}
							content={<Picker dateChange={this.dateChange} startDate={start_date.toDate()} endDate={end_date.toDate()} lang={selectedLang} />}
							on="click"
							position="bottom right"
						/>

						<Table striped celled structured>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={2} rowSpan="2">
										{'Marka'}
									</Table.HeaderCell>
									<Table.HeaderCell width={1} rowSpan="2">
										{'Güncellenen'}
									</Table.HeaderCell>
									<Table.HeaderCell width={1} rowSpan="2">
										{'Toplam Eklenen'}
									</Table.HeaderCell>
									<Table.HeaderCell colSpan={date_array.length} textAlign="center">
										{'Eklenen'}
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									{_.map(date_array, (it) => (
										<Table.HeaderCell>{moment(it).format('DD-MM')}</Table.HeaderCell>
									))}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell>{t('total')}</Table.Cell>
									<Table.Cell>
										{(updateData && updateData.length && _.sumBy(updateData, (it) => parseInt(it.update_count))) || '0'}
									</Table.Cell>

									<Table.Cell>{(addedData && addedData.length && _.sumBy(addedData, (it) => parseInt(it.count))) || '0'}</Table.Cell>
									{_.map(date_array, (dit) => {
										let date_obj = _.filter(addedData, (o) => moment(o.date).dayOfYear() === moment(dit).dayOfYear());

										return <Table.Cell>{(date_obj && date_obj.length && _.sumBy(date_obj, (it) => parseInt(it.count))) || '0'}</Table.Cell>;
									})}
								</Table.Row>
								{_.map(unique_data, (it, i) => {
									let added_obj = g_addedData[it.folder_name];
									let updated_obj = group_updateData[it.folder_name];
									return (
										<Table.Row key={i}>
											<Table.Cell>{it.folder_name}</Table.Cell>
											<Table.Cell>{(updated_obj && _.sumBy(updated_obj, (o) => parseInt(o.update_count))) || '0'}</Table.Cell>
											<Table.Cell>{(added_obj && _.sumBy(added_obj, (it) => parseInt(it.count))) || '0'}</Table.Cell>
											{_.map(date_array, (dit) => {
												let date_obj = _.find(added_obj, (o) => moment(o.date).dayOfYear() === moment(dit).dayOfYear());
												return <Table.Cell>{(date_obj && date_obj.count) || '0'}</Table.Cell>;
											})}
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
						<Container>
							<ChartWrap width={8}>
								<Line data={data} options={barOptions} />
							</ChartWrap>
						</Container>
					</Segment>
				</Page.Wrap>
			</Page>
		);
	}
}

export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(Folder));
