import React, { Component } from 'react';
import Page from '../../components/Page';
import { Segment, Table, Breadcrumb, Icon, Button, Modal, Checkbox, Dropdown, Header } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { post, toWapi } from '../../util/api';
import _ from 'lodash';
import { connect } from 'react-redux';

class SendWhatsapp extends Component {
	state = {
		targets: [],
		kolaj_arr: [],
		loading: true,
		phones: [],
	};
	async getData() {
		let { folder } = this.props.match.params;
		await post('/shop/subfolder/list', { folder_id: folder }).then((data) => {
			this.setState({ data: data.data.result, loading: false, tableloading: false, folder_data: data.data.result[0].folder });
		});
	}
	async componentDidMount() {
		await this.getListPhone();
		let { phones } = this.state;
		await this.getData();
		if (!phones.length) {
			console.log('ss', phones);
			return this.setState({ loading: false, error: true });
		}
		for (let index = 0; index < phones.length; index++) {
			const element = phones[index];
			/* if (index === 0) */ this.getGroups(element.id);
		}
	}
	async getGroups(phone_id) {
		let { phones } = this.state;
		let { shop } = this.props;
		let whatsapp_config = shop.server_config.whatsapp;
		if (!whatsapp_config) return;
		let pid = whatsapp_config.product_id,
			w_token = whatsapp_config.key;
		let data = await toWapi(`/api/${pid}/${phone_id}/contacts`, w_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				return data.data && data.data.data;
			})
			.catch((err) => {
				_.find(phones, { id: phone_id }).isError = true;
				console.error(err);
			});
		let fi = _.find(phones, { id: phone_id });
		fi.groups = data;
		fi.loading = false;
		if (data && data.type === 'error') {
			_.find(phones, { id: phone_id }).isError = true;
		}
		this.setState({ phones });
	}
	async getListPhone() {
		let { shop } = this.props;
		let whatsapp_config = shop.server_config.whatsapp;
		if (!whatsapp_config) return;
		let pid = whatsapp_config.product_id,
			w_token = whatsapp_config.key;
		await toWapi(`/api/${pid}/listPhones`, w_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				_.map(data, (it) => (it.loading = true));
				let dropdownList = _.map(
					_.filter(data, (o) => o.number),
					(it) => ({
						value: it.id,
						text: it.number,
					})
				);
				this.setState({
					dropdownList,
					phones: _.filter(data, (o) => o.number),
					btnLoading: false,
					selectedphone: dropdownList.length === 2 ? dropdownList[1] && dropdownList[1].value : dropdownList[0] && dropdownList[0].value,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	sumFilter(data, status) {
		return _.sumBy(_.flatten(_.map(data, (o) => _.filter(o.kolaj, (it) => it && it.status === status))), 'count');
	}
	handleChange = (e, { name, value }) => {
		this.setState({ [name]: value });
		if (name === 'selectedphone' && value === 'all') this.setState({ sw_contacts: false });
	};
	toggle = (o, number, phone) => {
		let { targets } = this.state;
		let isCheck = _.find(targets, { id: o.id, number });
		o.number = number;
		o.phone = phone;
		if (!isCheck) {
			targets.push(o);
		} else {
			_.remove(targets, function (it) {
				return it.id === o.id && it.phone === o.phone;
			});
		}
		this.setState({ targets });
	};
	toggleKolaj = (kolaj) => {
		let { kolaj_arr } = this.state;
		let isCheck = _.find(kolaj_arr, { id: kolaj.id });
		console.log(kolaj_arr);
		if (!isCheck) {
			kolaj_arr.push(kolaj);
		} else {
			_.remove(kolaj_arr, function (it) {
				return it.id === kolaj.id;
			});
		}
		this.setState({ kolaj_arr });
	};
	closeModal = () => {
		this.setState({ openModal: false, openListModal: false });
	};
	sendMessage() {
		let { targets, kolaj_arr } = this.state;
		console.log('targets', targets, kolaj_arr);
		//todo get selected kolaj and send to wa queue
	}
	render() {
		const { t } = this.props;
		let {
			loading,
			data,
			folder_data,
			tableloading,
			dropdownList,
			phones,
			selectedphone,
			targets,
			kolaj_arr,
			error,
		} = this.state;
		let { folder } = this.props.match.params;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		if (error) {
			return <Redirect to="/whatsapp-shop" />;
		}
		console.log('kolaj_arr', kolaj_arr);
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>{folder_data.data.name}</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				{tableloading ? (
					<Page.Loader></Page.Loader>
				) : (
					<Page.Wrap>
						<Segment.Group horizontal>
							<Segment>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={1}></Table.HeaderCell>
											<Table.HeaderCell width={4}>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell width={8}>{t('Onaylanan Sayısı')}</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(data, (it, i) => {
											let isCheck = !!_.find(kolaj_arr, { id: it.id });
											let verified_table = _.filter(it.kolaj, (o) => o.status === 'verified');
											return (
												<Table.Row key={it.data.name}>
													<Table.Cell>
														{_.sumBy(verified_table, 'count') ? (
															<Checkbox checked={isCheck} onChange={() => this.toggleKolaj(it)}></Checkbox>
														) : null}
													</Table.Cell>
													<Table.Cell>
														<Link to={'/whatsapp-shop/' + folder + '/' + it.id}>
															{it.data.name} {it.data.showing_name ? ' (' + it.data.showing_name + ')' : null}
														</Link>
													</Table.Cell>

													<Table.Cell>{_.sumBy(verified_table, 'count')}</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</Segment>
							<Segment className="scroll" style={{ padding: '0' }}>
								<Table className="phone">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={12}>
												<Dropdown
													label={t('phone')}
													style={{ marginLeft: '20px' }}
													selection
													options={dropdownList}
													name="selectedphone"
													value={selectedphone}
													onChange={this.handleChange}
												></Dropdown>
											</Table.HeaderCell>
											<Table.HeaderCell>
												{/* <div style={{ display: 'flex' }}>
													<Checkbox
														label={t('Kişiler')}
														name="sw_contacts"
														checked={sw_contacts}
														disabled
														style={{ marginRight: '10px' }}
														onChange={this.toggleSw}
													/>
													<Checkbox label={t('Gruplar')} name="sw_groups" checked={sw_groups} onChange={this.toggleSw} />
												</div> */}
											</Table.HeaderCell>
											<Table.HeaderCell>{t('type')}</Table.HeaderCell>
											<Table.HeaderCell textAlign="center">
												<Button onClick={() => this.setState({ targets: [] })}>{t('cleanAll')}</Button>
											</Table.HeaderCell>
											<Table.HeaderCell textAlign="center">
												<Button positive onClick={() => this.setState({ openModal: true })}>
													{t('send')}
												</Button>
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{_.map(phones, (it) => (
											<>
												<Table.Row style={{ background: 'lightgray' }}>
													<Table.Cell>{it.number}</Table.Cell>
													<Table.Cell></Table.Cell>
													<Table.Cell></Table.Cell> <Table.Cell></Table.Cell>
													<Table.Cell></Table.Cell>
												</Table.Row>

												{it.loading ? (
													<Table.Row>
														<Table.Cell width={16}>
															<Page.Loader></Page.Loader>
														</Table.Cell>
													</Table.Row>
												) : it.isError ? (
													<Table.Row>
														<Table.Cell>{t('error')}</Table.Cell>
													</Table.Row>
												) : (
													_.map(it.groups, (o) => {
														let isCheck = !!_.find(targets, { id: o.id, number: it.id });
														return (
															<Table.Row>
																<Table.Cell>{o.name}</Table.Cell> <Table.Cell></Table.Cell>
																<Table.Cell>{o.type === 'chat' ? t('Kişi') : t('Grup')}</Table.Cell>
																<Table.Cell textAlign="center">
																	<Checkbox checked={isCheck} onChange={() => this.toggle(o, it.id, it.number)}></Checkbox>
																</Table.Cell>
																<Table.Cell></Table.Cell>
															</Table.Row>
														);
													})
												)}
											</>
										))}
									</Table.Body>
								</Table>
							</Segment>
						</Segment.Group>

						<Modal open={this.state.openModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
							<Header icon="whatsapp" content={`${t('sendMessage')} (${targets.length})`} />
							<Modal.Content>
								<div style={{ maxHeight: '500px', overflowY: 'auto' }}>
									<Table>
										<Table.Body>
											{_.map(targets, (it) => (
												<Table.Row>
													<Table.Cell>{it.name || it.id}</Table.Cell>
													<Table.Cell>{it.type === 'chat' ? t('Kişi') : t('Grup')}</Table.Cell>
													<Table.Cell>{it.phone}</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
									</Table>
								</div>
							</Modal.Content>
							<Modal.Actions>
								<div style={{ display: 'flex' }}>
									<p style={{ display: 'flex', alignItems: 'center' }}>{t('sendGroupsSure')}</p>
									<div style={{ marginLeft: 'auto' }}>
										<Button color="red" onClick={this.closeModal}>
											<Icon name="remove" /> {t('no')}
										</Button>
										<Button color="green" onClick={() => this.sendMessage()}>
											<Icon name="checkmark" /> {t('yes')}
										</Button>
									</div>
								</div>
							</Modal.Actions>
						</Modal>
					</Page.Wrap>
				)}
			</Page>
		);
	}
}

export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(SendWhatsapp));
