//import Cookies from 'js-cookie';
import * as ActionTypes from '../constants/actionTypes';
//const YTECH_COOKIE_NAME = 'ytech-auth-user';

const initialState = {
	error: null,
	loading: null,
	load_passed: null,
};

export default function product(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.PRODUCT_REQUESTED:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.PRODUCT_UPDATED:
		case ActionTypes.PRODUCT_SUCCEEDED: {
			let newstate = {
				...state,
				error: null,
				loading: null,
				load_passed: true,
			};
			if (action.product) newstate[action.product.product_type || 'shop'] = action.product;
			return newstate;
		}
		case ActionTypes.PRODUCT_FAILED: {
			return {
				...state,
				error: action.error,
				loading: null,
			};
		}
		case ActionTypes.LOGOUT_SUCCEEDED:
		case ActionTypes.LOGIN_SUCCEEDED: {
			return {
				error: null,
				loading: null,
			};
		}
		default:
			break;
	}
	return state;
}
