import axios from 'axios';
const API_ADDRESS = process.env.REACT_APP_API_URL || 'http://51.15.11.170:4200/api';

const LOG_ADDRESS = process.env.REACT_APP_LOG_URL || 'https://log.yapaytech.com';
const WG_ADDRESS = 'https://wg.dialogtab.com';

export function get(path, params) {
	const url = `${API_ADDRESS}${path}`;

	return axios({
		method: 'get',
		url,
		params,
		withCredentials: true,
	}).then((resp) => resp.data);
}

export function getWG(path, params) {
	const url = `${WG_ADDRESS}${path}`;

	return axios({
		method: 'get',
		url,
		params,
	}).then((resp) => resp.data);
}
export function postWg(path, data, params) {
	const url = `${WG_ADDRESS}${path}`;
	return axios({
		method: 'post',
		url,
		data,
	});
}

export function post(path, data, params) {
	const url = `${API_ADDRESS}${path}`;
	return axios({
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		url,
		data: '__json__=' + encodeURIComponent(JSON.stringify(data)),
		params,
		withCredentials: true,
	});
}
export function log(path, data, params) {
	const url = `${LOG_ADDRESS}${path}`;
	return axios({
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		url,
		data: '__json__=' + encodeURIComponent(JSON.stringify(data)),
		params,
	});
}
const WAPI_URL = 'https://wapi.yapaytech.com';
export function toWapi(path, token, data) {
	const url = `${WAPI_URL}${path}`;
	return axios({
		method: data ? 'post' : 'get',
		headers: {
			'x-api-key': token,
		},
		url,
		data,
	});
}

export function getFull(path, params) {
	const url = `${path}`;

	return axios({
		method: 'get',
		url,
		params
	}).then((resp) => resp.data);
}

export function postEx(path, data, params) {
	const url = `${path}`;
	return axios({
		method: 'post',
		url,
		data,
	});
}