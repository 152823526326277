import React, { Component, Suspense } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';
import { get, post } from '../../util/api';
import { Header, Segment, Breadcrumb, Icon, Checkbox, Form, Button, Modal, Image, Dropdown, Input } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { copied_data, fontFamily, textAlign, brands } from './datas';

// eslint-disable-next-line

function createHash(obj) {
	if (obj && !obj._hash) obj._hash = _.deburr(obj.name).toLowerCase().trim();
}

const Editor = React.lazy(() => import('../whatsapp-shop/editor'));

class CreateCollage extends Component {
	state = {
		exportdata: {},
		loading: true,
		wait: false,
	};

	editor = React.createRef();

	async getData() {
		let data = await get('/shop/schema/all');
		let result = copied_data;
		if (!result) {
			return this.setState({ loading: false, error: true });
		}
		let selected_images =
			_.map(result.data.images, (it) => {
				let name = it && it.split('/');
				name = name && name[name.length - 1];
				return { path: it, name, checked: true };
			}) || result.data.selected_images;
		let kolaj_config = result.data.kolaj_editor && result.data.kolaj_editor.text_data;
		let template_id = result.data.kolaj_editor && result.data.kolaj_editor.template;
		let template_data = data.result;
		let selectedTemplate = (template_id && _.find(template_data, { id: template_id })) || data.result[0];
		console.log('selected ', selectedTemplate);
		let schemas_filtered = _.orderBy(
			_.filter(template_data, (it) => it === selectedTemplate || (it.data && !it.data.hidden)),
			(it) => it.data && (it.data.default || it.data.force)
		);
		let template_dropdown = _.map(schemas_filtered, (it) => ({ text: it.data.name, value: it.id }));

		let refined_images = result.data.refined_images;
		let barcode_img = refined_images && _.find(refined_images, { has_barcode: true });
		let selected_zoom_img;
		if (barcode_img) {
			selected_zoom_img = barcode_img.image.__image;
		} else {
			selected_zoom_img = _.last(result.data.images);
		}
		let zoom_images = _.map(result.data.images, (it) => ({
			text: it.replace(/.*_/, ''),
			value: encodeURI(it),
		}));

		_.each(brands, createHash);
		this.setState({
			data: result,
			brands: brands,
			selected_images,
			loading: false,
			folder_data: copied_data.folder,
			subfolder_data: copied_data.subfolder,
			kolaj_config: kolaj_config || { price: '0' },
			refined_images,
			barcode_img,
			selected_zoom_img,
			template_data,
			template_dropdown,
			selectedTemplateId: selectedTemplate.id,
			selectedTemplate: selectedTemplate,
			zoom_images,
			value: null,
			clicked_type: null,
			clicked_name: null,
		});
	}
	componentDidMount() {
		this.getData();
	}
	handleChangeArray(i) {
		let { selected_images } = this.state;
		this.setState({
			selected_images: selected_images.map((it, _i) => {
				if (i === _i) it.checked = !it.checked;
				return it;
			}),
		});
	}

	onDragEnd = (result) => {
		//console.log(result);
		let last;
		let from = result.source && result.source.index;
		let to = result.destination && result.destination.index;
		let { selectedTemplate } = this.state;
		if ((to === 0 || to) && from !== to) {
			selectedTemplate.data.panel.splice(to, 0, selectedTemplate.data.panel.splice(from, 1)[0]);
			last = selectedTemplate;
			this.setState({
				selectedTemplate: last,
				temp_template: last,
			});
		}
	};

	setValueInput = (type) => {
		let { clicked_type, selectedTemplate } = this.state;
		let result = null;
		if (clicked_type) {
			let check = _.find(selectedTemplate.data.texts, (e, i) => {
				let check = e.format;
				if (check && check.search(this.state.clicked_type + '}}') != -1) {
					return e;
				}
			});
			console.log('check ', check);
			if (check && check[type]) {
				result = check[type];
			} else {
				if (type == 'color') result = 'black';
				if (type == 'font') result = 'arial';
				if (type == 'align') result = 'center';

				if (result && check) check[type] = result;
			}
		}
		return result;
	};

	setSettings = (type, name) => {
		this.setState({ clicked_type: type, settings: true, clicked_name: name });
	};
	setValue = (e, { name, value }) => {
		this.setState({ [name]: value });
	};

	setPanel = (template, option) => {
		let data = template && template.data;

		// data changes
		if (option == 'brands') {
			console.log('geldi');
			/*
             image or text ?type = brands
                *image
                align
            
                *text
                font family -> Roboto - Roboto Medium - Roboto Black
                font size
                align
             */
		} else {
			/*
				font family -> Roboto - Roboto Medium - Roboto Black
                font size
                align
				color
			 */
		}
		template.data = data;
		this.setState({ selectedTemplate: template, settings: true });
	};

	handleChange = (e, { name, value }) => {
		let { kolaj_config } = this.state;
		kolaj_config[name] = value;
		this.setState({ kolaj_config });
	};
	onChangeValue = (e, { name, value }) => {
		let { selectedTemplate } = this.state;
		if (name == 'sablon') {
			selectedTemplate.data.sablon = value;
		}
		this.setState({ selectedTemplate, selectedTemplateId: selectedTemplate.id });
		console.log(name, value);
	};
	async saveData(data, publish) {
		let kolaj_editor = this.editor.current && this.editor.current.save();
		if (!kolaj_editor) return;
		this.setState({ btnLoading: true });
		let body = { id: data.id, data: { kolaj_editor, selected_images: this.state.selected_images } };
		if (publish) {
			body.status = 'done';
		}
		await post('/shop/kolaj/save', body).then(() => {
			if (!publish) this.setState({ btnLoading: false });
		});
		if (publish) {
			await get(`/shop/kolaj/final/${data.id}`).then((response) => {
				let exportdata = response.result.data.export;
				this.setState({ openExportModal: true, btnLoading: false, exportdata });
			});
		}
	}

	async exportData(data) {
		this.setState({ modalButton: true });
		let body = { id: data.id, data: {}, status: 'verified' };
		await post('/shop/kolaj/save', body).then(() => {
			this.setState({ openExportModal: false, modalButton: false });
		});
	}
	handleChangeTemplate = (e, { name, value }) => {
		let { template_data, selectedTemplate } = this.state;
		selectedTemplate = _.find(template_data, { id: value });
		this.setState({ [name]: value, selectedTemplate, selectedTemplateId: value });
	};
	render() {
		const { t } = this.props;
		let {
			loading,
			data,
			selected_images,
			kolaj_config,
			btnLoading,
			//template_data,
			template_dropdown,
			selectedTemplate,
			selectedTemplateId,
			openExportModal,
			exportdata,
			error,
			brands,
			value,
			clicked_name,
			clicked_type,
		} = this.state;

		if (loading) return <Page.Loader />;
		if (error) {
			return <Redirect to="/whatsapp-shop" />;
		}
		let kolaj_editor = data.data.kolaj_editor;
		// console.log('selectedTemplateId', this.state);
		let first_result = data.data.first_result;
		return (
			<>
				<div className="page-collage-wrap">
					<div className="konva-editor-wrap">
						<div className="ke-collage-info">
							<Segment style={{ display: 'flex' }}>
								<Button loading={btnLoading} positive onClick={() => this.saveData(data, true)}>
									Kolajı Gör
								</Button>
								<Form.Field inline style={{ marginLeft: 30 }}>
									<Dropdown options={template_dropdown} selection value={selectedTemplateId} onChange={this.handleChangeTemplate} />
								</Form.Field>
							</Segment>

							<Segment>
								<Header>Panel</Header>
								<Button style={{ color: 'white', width: '100%', marginBottom: 10 }} positive>
									Yeni Alan Ekle
								</Button>
								<Form className="panel-extra">
									<DragDropContext onDragEnd={this.onDragEnd}>
										<Droppable droppableId="actions" type="list">
											{(provided) => {
												return (
													<div
														className="files-list"
														{...provided.droppableProps}
														ref={provided.innerRef}
														style={{ overflowY: 'auto', maxHeight: '100%', height: '600px', overflowX: 'hidden' }}
													>
														<div>
															{_.map(selectedTemplate.data.panel, (it, i) => {
																let s_value = first_result && first_result[it.keyword];
																let result_panel;
																if (it.type && it.type.search('_dropdown') != -1 && selectedTemplate.data[it.type]) {
																	if (!selectedTemplate.__sd)
																		selectedTemplate.__sd = _.map(selectedTemplate.data[it.type], (it) => ({
																			value: it.range,
																			text: it.range,
																		}));

																	result_panel = (
																		<div className="inline field" key={it.keyword}>
																			<label>{it.title}</label>
																			<Dropdown
																				search
																				placeholder={it.default}
																				selection
																				name={it.keyword}
																				value={kolaj_config['' + it.keyword]}
																				onChange={this.handleChange}
																				options={selectedTemplate.__sd}
																			/>
																		</div>
																	);
																} else if (it.type === 'brands' && selectedTemplate.data.brands) {
																	if (!selectedTemplate.__brands) {
																		/* selectedTemplate.__brands = _.map(selectedTemplate.data.brands, (it) => ({
												value: it,
												text: it,
											})); */
																	}
																	selectedTemplate.__brands = _.map(brands, (it) => ({
																		value: it.name,
																		text: it.name,
																	}));
																	result_panel = (
																		<div className="inline field" style={{ display: 'flex' }} key={it.keyword}>
																			<label style={{ alignSelf: 'center' }}>{it.title}</label>
																			<Dropdown
																				search
																				placeholder={it.default}
																				selection
																				name={it.keyword}
																				value={kolaj_config['' + it.keyword]}
																				onChange={this.handleChange}
																				options={selectedTemplate.__brands}
																			/>
																			<Icon
																				name="setting"
																				style={{
																					marginLeft: '10px',
																					marginTop: 'auto',
																					marginBottom: 'auto',
																					cursor: 'pointer',
																				}}
																				onClick={() => this.setSettings('brands', it.title)}
																			></Icon>

																			{first_result && kolaj_config['' + it.keyword] !== s_value ? (
																				<Icon
																					name="pencil alternate"
																					style={{ marginLeft: '22px', marginTop: 'auto', marginBottom: 'auto' }}
																				></Icon>
																			) : (
																				''
																			)}
																		</div>
																	);
																} else
																	result_panel = (
																		<div className="inline field" style={{ display: 'flex' }}>
																			<Form.Input
																				inline
																				key={it.keyword}
																				label={it.title}
																				placeholder={it.default}
																				name={it.keyword}
																				value={kolaj_config['' + it.keyword]}
																				onChange={this.handleChange}
																			/>
																			<Icon
																				name="setting"
																				style={{
																					marginTop: 'auto',
																					marginBottom: 'auto',
																					cursor: 'pointer',
																				}}
																				onClick={() => this.setSettings(it.keyword, it.title)}
																			></Icon>
																			<Icon
																				name="trash"
																				style={{
																					marginLeft: '10px',
																					marginRight: '10px',
																					marginTop: 'auto',
																					marginBottom: 'auto',
																					cursor: 'pointer',
																					color: 'red',
																				}}
																				onClick={() => this.setSettings('brands', it.title)}
																			></Icon>
																			{first_result && kolaj_config['' + it.keyword] !== s_value ? (
																				<Icon
																					name="pencil alternate"
																					style={{ marginLeft: '10px', marginTop: 'auto', marginBottom: 'auto' }}
																				></Icon>
																			) : (
																				''
																			)}
																		</div>
																	);

																return (
																	<Draggable draggableId={it.keyword} index={i} key={it.keyword}>
																		{/*key={it['name']} draggableId={it['name']} index={i} */}
																		{(provided) => {
																			return (
																				<div
																					ref={provided.innerRef}
																					className="yt-panel-design"
																					{...provided.draggableProps}
																				>
																					<div {...provided.dragHandleProps}>
																						<Icon name="arrows alternate vertical" size="large"></Icon>
																					</div>
																					{result_panel}
																				</div>
																			);
																		}}
																	</Draggable>
																);
															})}
														</div>
													</div>
												);
											}}
										</Droppable>
									</DragDropContext>
								</Form>
							</Segment>
							<Segment>
								<Header>Extra</Header>
								<div className="inline field" style={{ display: 'flex' }}>
									<a>Şablon Arkaplan</a>

									<Input style={{ width: '100%' }} fluid type="text" name="sablon" placeholder="Şablon" onChange={this.onChangeValue} />
								</div>
							</Segment>
						</div>
						<div className="ke-editor">
							{console.log('selected ', selectedTemplate)}
							<Suspense fallback={<div>Yükleniyor...</div>}>
								<Editor
									editor={this.editor}
									kolaj_config={kolaj_config}
									images={selected_images}
									kolaj_editor={kolaj_editor}
									template={selectedTemplate}
									brands={this.state.brands}
								/>
							</Suspense>
						</div>
					</div>
				</div>
				<Modal
					className="export"
					style={{ width: 'auto' }}
					open={openExportModal}
					size="large"
					onClose={() => this.setState({ openExportModal: false })}
				>
					<Modal.Content style={{ padding: '4px' }}>
						<a href={exportdata.url} target="_blank" rel="noopener noreferrer">
							<Image centered src={exportdata.url} style={{ height: '85vh', width: 'auto' }}></Image>
						</a>
					</Modal.Content>
					<Modal.Actions style={{ display: 'flex' }}>
						<h4>{t('imageVerify')}</h4>
						<Button style={{ marginLeft: 'auto' }} negative onClick={() => this.setState({ openExportModal: false })}>
							{t('no')}
						</Button>
						<Button positive loading={this.state.modalButton} onClick={() => this.exportData(data)}>
							{t('yes')}
						</Button>
					</Modal.Actions>
				</Modal>
				<Modal
					className="export"
					open={this.state.settings}
					onClose={() => {
						this.setState({ settings: false });
					}}
				>
					<Modal.Header>
						{clicked_name}({clicked_type}) Ayarları
					</Modal.Header>
					<Modal.Content style={{ padding: '15px' }}>
						{clicked_type === 'brands' && selectedTemplate.data.brands ? (
							<>
								<Form>
									<Form.Field>
										<Checkbox radio label="Resimli Logo" name="value" value="this" checked={value === 'this'} onChange={this.setValue} />
									</Form.Field>
									<Form.Field>
										<Checkbox radio label="Yazılı Logo" name="value" value="that" checked={value === 'that'} onChange={this.setValue} />
									</Form.Field>
									<Form.Field>
										<Checkbox radio label="Logosuz" name="value" value="thus" checked={value === 'thus'} onChange={this.setValue} />
									</Form.Field>
								</Form>
								{value == 'this' ? (
									<div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
										<a>Image</a>
										<Input fluid type="text" placeholder="Image" />
										<a>Text Align</a>
										<Dropdown placeholder="Text Align" fluid selection options={textAlign} />
									</div>
								) : value == 'that' ? (
									<div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
										<a>Font Family</a>
										<Dropdown placeholder="Font Family" fluid selection options={fontFamily} />

										<a>Text Align</a>
										<Dropdown placeholder="Text Align" fluid selection options={textAlign} />
										<a>Font Size</a>
										<div>
											<Input fluid type="number" placeholder="Size" />
										</div>
										<a>Height</a>
										<div>
											<Input fluid type="number" placeholder="10px" />
										</div>
										<a>Width</a>
										<div>
											<Input fluid type="number" placeholder="10px" />
										</div>
									</div>
								) : value == 'thus' ? null : null}
							</>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
								<a>Font Family</a>
								<Dropdown
									placeholder="Font Family"
									fluid
									selection
									name="font"
									options={fontFamily}
									value={this.setValueInput('font')}
									onChange={this.onChangeValue}
								/>

								<a>Text Align</a>
								<Dropdown placeholder="Text Align" fluid selection options={textAlign} value={this.setValueInput('align')} />
								<a>Font Size</a>
								<div>
									<Input fluid type="number" placeholder="Size" value={this.setValueInput('size')} />
								</div>
								<a>Color</a>
								<div>
									<Input fluid type="text" placeholder="black & #fff" value={this.setValueInput('color')} />
								</div>
								<a>Height</a>
								<div>
									<Input fluid type="number" placeholder="10px" value={this.setValueInput('height')} />
								</div>
								<a>Width</a>
								<div>
									<Input fluid type="number" placeholder="10px" value={this.setValueInput('width')} />
								</div>
							</div>
						)}
					</Modal.Content>
					{/* <Modal.Actions style={{ display: 'flex' }}>
								<Button positive loading={this.state.modalButton} onClick={() => this.setPanel(selectedTemplate, clicked_type)}>
									{t('save')}
								</Button>
							</Modal.Actions> */}
				</Modal>
			</>
		);
	}
}

export default withTranslation()(CreateCollage);
