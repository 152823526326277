import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';
import { post } from '../../util/api';
import { Segment, Table, Breadcrumb, Icon, Label, Button, Dropdown, Modal, Input, Checkbox } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
class Folder extends Component {
	state = {
		loading: true,
		checked: true,
	};
	componentDidMount() {
		this.getData();
	}
	async getData() {
		let { folder } = this.props.match.params;
		if (folder === 'modal') return;
		await post('/shop/subfolder/list', { folder_id: folder, onlyActive: true }).then((data) => {
			let result = data.data && data.data.result;
			this.setState({ data: result, loading: false, tableloading: false, folder_data: result && result[0] && result[0].folder });
		});
	}
	sumFilter(data, status) {
		return _.sumBy(_.flatten(_.map(data, (o) => _.filter(o.kolaj, (it) => it && it.status === status))), 'count');
	}
	build() {
		this.setState({ buildLoading: true });
		let { folder } = this.props.match.params;
		post('/shop/build', { folder_id: folder }).then(() => this.setState({ buildLoading: false }));
	}
	async stateUpdate(subfolder_id, state_now) {
		let state = state_now === 'active' ? 'passive' : 'active';
		this.setState({ tableloading: true });
		await post('/shop/state/update', { subfolder_id: parseInt(subfolder_id), state }).then(() => this.getData());
	}
	async updateSubFolder() {
		this.setState({ btnLoadName: true });
		let { selected_sub, showing_name } = this.state;

		let body = {
			...selected_sub.data,
			showing_name,
		};
		await post(`/shop/subfolder/update/${selected_sub.id}`, { data: body }).then(() => {
			this.setState({ openNameModal: false, btnLoadName: false });
			this.getData();
		});
	}
	onChangeCheckbox = (evt, data) => {
		let checked = data.checked;
		this.setState({ checked });
	};

	render() {
		const { t, subdomain, shop_config } = this.props;
		let { loading, data, folder_data, tableloading, openNameModal, showing_name, btnLoadName, selected_sub, buildLoading, checked } = this.state;
		let { folder } = this.props.match.params;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		if (!data.length) {
			return <Redirect to="/whatsapp-shop" />;
		}
		let done_total = this.sumFilter(data, 'done');
		let failed_total = this.sumFilter(data, 'failed');
		let pending_total = this.sumFilter(data, 'pending');
		let verified_total = this.sumFilter(data, 'verified');
		let filtered_data = data;
		if (checked) {
			filtered_data = _.filter(data, (it) => it.state !== 'passive');
		}
		let check_download = (shop_config && shop_config.downloadAll) || false; //this.props && this.props.subdomain && (this.props.subdomain == 'laserafashion' || this.props.subdomain == 'idyl') ? true : false;
	
		filtered_data = _.orderBy(filtered_data, 'data.name', 'asc');
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>{folder_data.data.name}</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				{tableloading ? (
					<Page.Loader></Page.Loader>
				) : (
					<Page.Wrap>
						<Button basic labelPosition="left" color="grey" icon as={Link} to={`/whatsapp-shop`}>
							<Icon name="arrow left"></Icon>
							{t('back')}
						</Button>
						<Button basic labelPosition="left" color="orange" icon disabled={!done_total} as={Link} to={`/whatsapp-shop/verify/${folder}`}>
							<Icon name="arrow right"></Icon>
							{'Onay Bekleyenler'}
						</Button>
						<Button basic labelPosition="left" color="green" icon onClick={() => this.build()} loading={buildLoading}>
							<Icon name="arrow up"></Icon>
							{'Siteyi Güncelle'}
						</Button>
						<Button basic labelPosition="left" color="brown" icon as={'a'} href={`https://${subdomain}.whatsapp-shop.com`} target="_blank">
							<Icon name="arrow up"></Icon>
							{'Siteye Git'}
						</Button>
						<Button basic labelPosition="left" color="blue" icon as={Link} to={`/build`}>
							<Icon name="arrow right"></Icon>
							{'Builde Git'}
						</Button>
						<Checkbox toggle label="Pasifleri Gizle" checked={checked} onClick={(evt, data) => this.onChangeCheckbox(evt, data)} />
						<Segment>
							<div style={{ display: 'flex' }}>
								<Button as={Link} to={'/whatsapp-shop/' + folder + '/all'}>
									Hepsini Göster
								</Button>
								<div style={{ marginLeft: 'auto' }}>
									{pending_total ? (
										<Label color="orange" image>
											İşlenen
											<Label.Detail>{pending_total}</Label.Detail>
										</Label>
									) : null}
									{failed_total ? (
										<Label color="red" image>
											Eksik
											<Label.Detail>{failed_total}</Label.Detail>
										</Label>
									) : null}
									{done_total ? (
										<Label color="yellow" image>
											Bekleyen
											<Label.Detail>{done_total}</Label.Detail>
										</Label>
									) : null}
									{verified_total ? (
										<Label color="green" image>
											Onaylanan
											<Label.Detail>{verified_total}</Label.Detail>
										</Label>
									) : null}
									<Label className="label-fixed-size" color="blue" image>
										Toplam
										<Label.Detail style={{ width: '34px' }}>{_.sumBy(data, (o) => parseInt(o.count) || 0)}</Label.Detail>
									</Label>
								</div>
							</div>
							<Table>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell width={4}>{t('name')}</Table.HeaderCell>
										<Table.HeaderCell width={2}>{t('date')}</Table.HeaderCell>
										<Table.HeaderCell width={2}>{t('status')}</Table.HeaderCell>
										<Table.HeaderCell width={8} textAlign="center">
											{t('details')}
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(filtered_data, (it, i) => {
										let done_table = _.filter(it.kolaj, (o) => o && o.status === 'done');
										let failed_table = _.filter(it.kolaj, (o) => o && o.status === 'failed');
										let pending_table = _.filter(it.kolaj, (o) => o && o.status === 'pending');
										let verified_table = _.filter(it.kolaj, (o) => o && o.status === 'verified');
										return (
											<Table.Row key={it.data.name}>
												<Table.Cell>
													<Link to={'/whatsapp-shop/' + folder + '/' + it.id}>
														{it.data.name} {it.data.showing_name ? ' (' + it.data.showing_name + ')' : null}
													</Link>
												</Table.Cell>

												<Table.Cell>{moment(it.updated_at).format('DD-MM-YYYY HH:mm')}</Table.Cell>
												<Table.Cell>
													{<Icon name={it.state === 'active' ? 'check' : 'x'} color={it.state === 'active' ? 'green' : 'red'}></Icon>}
													{t(it.state)}
												</Table.Cell>
												<Table.Cell style={{ textAlign: 'right' }}>
													{pending_table && _.sumBy(pending_table, 'count') ? (
														<Label color="orange" image>
															İşlenen
															<Label.Detail>{_.sumBy(pending_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{failed_table && _.sumBy(failed_table, 'count') ? (
														<Label color="red" image>
															Eksik
															<Label.Detail>{_.sumBy(failed_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{done_table && _.sumBy(done_table, 'count') ? (
														<Label color="yellow" image>
															Bekleyen
															<Label.Detail>{_.sumBy(done_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													{verified_table && _.sumBy(verified_table, 'count') ? (
														<Label color="green" image>
															Onaylanan
															<Label.Detail>{_.sumBy(verified_table, 'count')}</Label.Detail>
														</Label>
													) : null}
													<Label className="label-fixed-size" color="blue" image>
														Toplam
														<Label.Detail style={{ width: '34px' }}>{it.count || 0}</Label.Detail>
													</Label>

													<Dropdown
														style={{ marginLeft: '20px' }}
														floating
														labeled
														button
														icon="bars"
														className="icon"
														text={'Seçenekler'}
														compact
													>
														<Dropdown.Menu>
															<Dropdown.Item
																text={it.state === 'passive' ? 'Aktif Yap' : 'Pasif Yap'}
																onClick={() => this.stateUpdate(it.id, it.state)}
															/>
															<Dropdown.Item
																text={'Marka İsmi Gir'}
																onClick={() => this.setState({ openNameModal: true, selected_sub: it })}
															/>
															{check_download && (
																<Dropdown.Item
																	text={'İndir'}
																	as="a"
																	href={'/api/shop/subfolder/download/' + it.id}
																	target="_blank"
																/>
															)}

															{/* <Dropdown.Item text={'Gönder'} as="a" href={'/wgroup/' + it.id} /> */}
														</Dropdown.Menu>
													</Dropdown>
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						</Segment>
						<Modal open={openNameModal} onClose={() => this.setState({ openNameModal: false })}>
							<Modal.Content>
								{selected_sub && selected_sub.data ? <p>{selected_sub.data.name} klasörüne marka ismi ver.</p> : null}
								<Input
									label="Marka"
									value={showing_name}
									name="showing_name"
									onChange={(e, { value }) => this.setState({ showing_name: value })}
								></Input>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={() => this.setState({ openNameModal: false })}>
									{t('no')}
								</Button>
								<Button disabled={!showing_name} loading={btnLoadName} color="green" onClick={() => this.updateSubFolder()}>
									{t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
					</Page.Wrap>
				)}
			</Page>
		);
	}
}

export default withTranslation()(Folder);
