import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Table from './table';
import Phones from './phone';
import TelegramSend from './telegramsend';
import Folder from './folder';
import SubFolder from './sub_folder';
import ProductionImages from './production_images';
import ProductionTable from './production_table';
import MobileImages from './mobile_images';
import MobileTable from './mobile_table';
class WGROUP extends PureComponent {
	render() {
		return (
			<Switch>
				<Route path="/wgroup" exact component={Folder}></Route>
				<Route path="/wgroup/telegram" exact component={TelegramSend}></Route>
				<Route path="/wgroup/phones_status" exact component={Phones}></Route>
				<Route path="/wgroup/production_images" exact component={ProductionImages}></Route>
				<Route path="/wgroup/mobile_images" exact component={MobileImages}></Route>
				<Route path="/wgroup/:folder" exact component={SubFolder}></Route>
				<Route path="/wgroup/send/:subfolder" exact component={Table}></Route>
				<Route path="/wgroup/send_production/:subfolder" exact component={ProductionTable}></Route>
				<Route path="/wgroup/send_mobile/:subfolder" exact component={MobileTable}></Route>
				<Redirect to="/wgroup" />
			</Switch>
		);
	}
}

export default WGROUP;
