const copied_data = {
	id: '34972',
	pid: 'acccc539-993e-4e8b-8d8e-e3e9a23abb9f',
	subfolder_id: 360,
	data: {
		export: {
			url: 'https://wg.dialogtab.com/export/360/34972/1.jpg',
			hash: 'fc4642e6469dee2ca9bd61d0f7aa3f54',
			path: '/export/360/34972/1.jpg',
			version: 1,
		},
		images: [
			'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil0.jpeg',
			'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil1.jpeg',
			'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil2.jpeg',
		],
		kolaj_name: 'tonsurton8432-camel-norm221300-siyah-norm221248-yesil',
		kolaj_editor: {
			data: [
				{
					x: 0,
					y: 171,
					id: '0',
					min: 1.2898248674711292,
					src: 'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil0.jpeg',
					__ws: true,
					crop: {
						x: 245.91722604551538,
						y: 17.897756542790006,
						width: 649.7006075274459,
						height: 1568.4299869069487,
					},
					image: {},
					scale: 1.2898248674711292,
					width: 838,
					height: 2023,
					isDragging: false,
				},
				{
					x: 848,
					y: 171,
					id: '1',
					min: 0.629375,
					src: 'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil1.jpeg',
					__ws: true,
					crop: {
						x: 0,
						y: 67.83270456877895,
						width: 1093.1479642502482,
						height: 1600,
					},
					image: {},
					scale: 0.629375,
					width: 688,
					height: 1007,
					isDragging: false,
				},
				{
					x: 848,
					y: 1187,
					id: '2',
					min: 0.6275,
					src: 'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil2.jpeg',
					__ws: true,
					crop: {
						x: 0,
						y: 122.66769923459582,
						width: 1096.4143426294822,
						height: 1600.0000000000002,
					},
					image: {},
					scale: 0.6275,
					width: 688,
					height: 1004,
					isDragging: false,
				},
			],
			sema: {
				name: 'Test Collage',
				panel: [
					{
						type: 'brands',
						title: 'Marka',
						default: 'Yapaytech',
						keyword: 'firm',
					},
					{
						title: 'Kod',
						default: 'TEST-YT007',
						keyword: 'code',
					},
					{
						title: 'Renk',
						default: 'BLACK',
						keyword: 'color',
					},
					{
						title: 'Bedenler',
						default: '30-40',
						keyword: 'size',
					},
					{
						title: 'Tip',
						default: 'DRESS',
						keyword: 'type',
					},
					{
						title: 'Ücret',
						default: '15',
						keyword: 'price',
					},
					{
						title: '2. Ürün Kod',
						default: 'TEST-YT008',
						keyword: 'code2',
					},
					{
						title: 'Renk',
						default: 'RED',
						keyword: 'color2',
					},
					{
						title: 'Bedenler',
						default: '30-38',
						keyword: 'size2',
					},
					{
						title: 'Tip',
						default: 'PANTS',
						keyword: 'type2',
					},
					{
						title: 'Ücret',
						default: '20',
						keyword: 'price2',
					},
					{
						title: 'Kod',
						default: 'TEST-YT009',
						keyword: 'code3',
					},
					{
						title: 'Renk',
						default: 'GRAY',
						keyword: 'color3',
					},
					{
						title: 'Bedenler',
						default: '30-36',
						keyword: 'size3',
					},
					{
						title: 'Tip',
						default: 'SKIRT',
						keyword: 'type3',
					},
					{
						title: 'Ücret',
						default: '60',
						keyword: 'price3',
					},
				],
				texts: [
					{
						x: 0,
						y: 2110,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 1536,
						height: 90,
						background: 'https://wg.dialogtab.com/static/whline.png',
					},
					{
						x: 768,
						y: 167,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 167,
						height: 226,
						background: 'https://wg.dialogtab.com/static/wh-plussize.png',
					},
					{
						x: 641,
						y: 2105,
						font: 'Roboto',
						size: 42,
						type: 'brands',
						align: 'center',
						width: 250,
						format: '{{firm}}',
						height: 150,
					},
					{
						x: 19,
						y: 2235,
						font: 'Roboto Medium',
						size: 37,
						width: 500,
						format: '{{type}}: {{code}}',
						height: 36,
					},
					{
						x: 693,
						y: 2235,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 149,
						format: '{{size}}',
						height: 36,
					},
					{
						x: 1070,
						y: 2235,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 200,
						format: '{{color}}',
						height: 36,
					},
					{
						x: 1422,
						y: 2227,
						font: 'Roboto Black',
						size: 50,
						align: 'right',
						color: 'white',
						width: 100,
						format: '{{price}}$',
						height: 55,
					},
					{
						x: 19,
						y: 2290,
						font: 'Roboto Medium',
						size: 37,
						width: 500,
						format: '{{type2}}: {{code2}}',
						height: 36,
					},
					{
						x: 693,
						y: 2290,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 149,
						format: '{{size2}}',
						height: 36,
					},
					{
						x: 1070,
						y: 2290,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 200,
						format: '{{color2}}',
						height: 36,
					},
					{
						x: 1422,
						y: 2290,
						font: 'Roboto Black',
						size: 50,
						align: 'right',
						color: 'white',
						width: 100,
						format: '{{price2}}$',
						height: 55,
					},
					{
						x: 19,
						y: 2340,
						font: 'Roboto Medium',
						size: 37,
						width: 500,
						format: '{{type3}}: {{code3}}',
						height: 36,
					},
					{
						x: 693,
						y: 2340,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 149,
						format: '{{size3}}',
						height: 36,
					},
					{
						x: 1070,
						y: 2340,
						font: 'Roboto Medium',
						size: 37,
						align: 'center',
						width: 200,
						format: '{{color3}}',
						height: 36,
					},
					{
						x: 1422,
						y: 2340,
						font: 'Roboto Black',
						size: 50,
						align: 'right',
						color: 'white',
						width: 100,
						format: '{{price3}}$',
						height: 55,
					},
				],
				width: 1536,
				brands: [
					'AL MARWAH CATALOG',
					'ANNAVERO PLUS',
					'AVERSIA PLUS',
					'BASICO PLUS',
					'BERRIN CATALOG',
					'BIZE CATALOG',
					'BOEEM CATALOG',
					'CARACLAN CATALOG',
					'CARLA CARMELITA PLUS',
					'CARRA CATALOG',
					'CARRA PLUS',
					'CEREMONY CATALOG',
					'DIAGONAL PLUS',
					'DIMARE CATALOG',
					'DZYN LINE CATALOG',
					'DZYN LINE PLUS',
					'ELLA CATALOG',
					'FEVER CATALOG',
					'GARMONIYA CATALOG',
					'GARMONIYA PLUS',
					'GEMKO PLUS',
					'GHADA PLUS',
					'GUSTO CATALOG',
					'HUKKA CATALOG',
					'KAMEYA CATALOG',
					'KASHA CATALOG',
					'KAPRIS PLUS',
					'KSAS PLUS',
					'KUPSS CATALOG',
					'LADYFORM CATALOG',
					'LA LUCE CATALOG',
					'LA SIBELLA PLUS',
					'LAQBI CATALOG',
					'LAZOR PLUS',
					'LUISA WANG CATALOG',
					'MARCH CATALOG',
					'MARCH PLUS',
					'MARE SOLE CATALOG',
					'MARE SOLE PLUS',
					'MEES CATALOG',
					'MIANOTTE CATALOG',
					'MISS LILIUM CATALOG',
					'MISS LILIUM PLUS',
					'MUGU CATALOG',
					'MYRAANG CATALOG',
					'NERI PLUS',
					'NOMENS CATALOG',
					'NORM CATALOG',
					'NORM PLUS',
					'PIENA CATALOG',
					'PIENA PLUS',
					'POLIN CATALOG',
					'QMEK CATALOG',
					'QUIZAS PLUS',
					'REINA LINE CATALOG',
					'REVARO CATALOG',
					'SEMPRE CATALOG',
					'SETRE CATALOG',
					'SI DESIGN CATALOG',
					'TONSURTON CATALOG',
					'TONSURTON PLUS',
					'TOPWOMAN CATALOG',
					'VEDI CATALOG',
					'VEDI PLUS',
					'VIA VIA PLUS',
				],
				height: 2396,
				hidden: false,
				images: [
					{
						x: 0,
						y: 171,
						width: 838,
						config: {
							has_face: true,
							has_person: true,
							has_barcode: false,
						},
						height: 2023,
					},
					{
						x: 848,
						y: 171,
						width: 688,
						config: {
							has_person: true,
							has_barcode: false,
						},
						height: 1007,
					},
					{
						x: 848,
						y: 1187,
						width: 688,
						config: {
							has_person: true,
							has_barcode: false,
						},
						height: 1004,
					},
				],
				mobile: true,
				sablon: 'https://wg.dialogtab.com/static/wh-plus-3.png',
			},
			template: 113,
			text_data: {
				code: 'TONSURTON8432',
				firm: 'ICON',
				size: '36-38-40',
				type: 'JACKET',
				code2: 'NORM221300',
				code3: 'NORM221248',
				color: 'CAMEL',
				price: '49',
				size2: '36-38-40-42',
				size3: '36-38-40-42',
				type2: 'DRESS',
				type3: 'DRESS',
				color2: 'SIYAH',
				color3: 'YESIL',
				price2: '22',
				price3: '27',
			},
		},
		selected_images: [
			{
				name: 'Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil0.jpeg',
				path: 'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil0.jpeg',
				checked: true,
			},
			{
				name: 'Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil1.jpeg',
				path: 'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil1.jpeg',
				checked: true,
			},
			{
				name: 'Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil2.jpeg',
				path: 'https://wg.dialogtab.com/mobile_upload/yapaytech/icon/Ltonsurton8432-camel-norm221300-siyah-norm221248-yesil2.jpeg',
				checked: true,
			},
		],
	},
	status: 'done',
	created_at: '2022-12-15T07:28:16.126Z',
	updated_at: '2022-12-15T07:28:16.562Z',
	state: 'active',
	uid: 1,
	subfolder: {
		id: 360,
		pid: 'acccc539-993e-4e8b-8d8e-e3e9a23abb9f',
		folder_id: 26,
		data: {
			name: 'icon',
			path: 'yapaytech/kolaj/icon',
		},
		status: 'active',
		created_at: '2022-12-15T07:28:16.122425+00:00',
		updated_at: '2022-12-15T07:28:16.122425+00:00',
		state: 'active',
	},
	folder: {
		id: 26,
		pid: 'acccc539-993e-4e8b-8d8e-e3e9a23abb9f',
		data: {
			name: 'kolaj',
			path: 'yapaytech/kolaj',
		},
		status: 'active',
		created_at: '2022-09-08T08:09:36.915353+00:00',
		updated_at: '2022-09-08T08:09:36.915353+00:00',
	},
};

const fontFamily = [
	{
		key: 'Roboto',
		text: 'Roboto',
		value: 'Roboto',
	},
	{
		key: 'Roboto Medium',
		text: 'Roboto Medium',
		value: 'Roboto Medium',
	},
	{
		key: 'Roboto Black',
		text: 'Roboto Black',
		value: 'Roboto Black',
	},
	{
		key: 'NexaBold',
		text: 'NexaBold',
		value: 'NexaBold',
	},
	{
		key: 'Ariel',
		text: 'Ariel',
		value: 'Ariel',
	},
	{
		key: 'Cuprum',
		text: 'Cuprum Regular',
		value: 'Cuprum',
	},
];

const textAlign = [
	{
		key: 'left',
		text: 'Left',
		value: 'left',
	},
	{
		key: 'center',
		text: 'Center',
		value: 'center',
	},
	{
		key: 'right',
		text: 'Right',
		value: 'right',
	},
];

const brands = [
	{
		active: true,
		cover_photo: 'https://cdnydm.com/permedia/6evfe5_PTFtPlWezaHZ06A.png?size=225x225',
		link: '',
		logo: 'https://cdnydm.com/permedia/6evfe5_PTFtPlWezaHZ06A.png?size=225x225',
		name: 'M&G Fashion',
		other_texts: ['M&G Fashion'],
		_hash: 'm&g fashion',
	},
	{
		active: true,
		cover_photo: 'https://cdnydm.com/permedia/6evfe5_PTFtPlWezaHZ06A.png?size=225x225',
		link: '',
		logo: 'https://cdnydm.com/permedia/IRzR-c4rGZqemxyEunUBdQ.png?size=842x595',
		name: 'GIZIA',
		other_texts: ['GIZIA'],
		_hash: 'gizia',
	},
	{
		active: true,
		cover_photo: 'https://cdnydm.com/permedia/6evfe5_PTFtPlWezaHZ06A.png?size=225x225',
		link: '',
		logo: 'https://wg.dialogtab.com/brands/vokzal/mugu.png',
		name: 'MUGU',
		other_texts: ['MUGU'],
		_hash: 'mugu',
	},
];

export { copied_data, fontFamily, textAlign, brands };
