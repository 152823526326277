import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { post } from '../../util/api';
import { Segment, Table, Breadcrumb, Icon, Button, List } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

class Folder extends Component {
	state = { loading: true };
	componentDidMount() {
		this.getData();
	}
	async getData() {
		await post('/shop/build/list', {}).then((data) => {
			this.setState({ data: data.data.result, loading: false, keyword: data.data.keyword });
		});
	}
	render() {
		let { t } = this.props;
		let { keyword } = this.state;
		let { loading, data } = this.state;
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		if (!data.length) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section>Build</Breadcrumb.Section>
						</Breadcrumb>
					}
				>
					<Page.Wrap>
						<Segment>Henüz build yapılmamıştır.</Segment>
					</Page.Wrap>
				</Page>
			);
		}
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>Build</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap>
					<Segment>
						<Button basic labelPosition="left" color="grey" icon as={Link} to={`/whatsapp-shop`}>
							<Icon name="arrow left"></Icon>
							{t('back')}
						</Button>
						<Button
							labelPosition="left"
							color="teal"
							icon
							as={'a'}
							href={`https://${keyword}.whatsapp-shop.com/preview/${data[0].id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon name="eye"></Icon>
							{'Önizleme'}
						</Button>
						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('date')}</Table.HeaderCell>
									<Table.HeaderCell width={6}>{t('status')}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								<Table.Row>
									<Table.Cell>{moment(data[0].updated_at).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
									<Table.Cell>{data[0].status}</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>

						<Table>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>{t('name')}</Table.HeaderCell>
									<Table.HeaderCell width={6}>{t('kolaj')}</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{_.map(data, (p) => {
									return _.map(p.data, (it) => {
										return _.map(it.data, (o, oi) => (
											<Table.Row key={oi}>
												<Table.Cell>{o.name}</Table.Cell>
												<Table.Cell>
													<List>
														{_.map(o.products, (j, ji) => (
															<List.Item key={ji}>
																{j.name}
																<a style={{ marginLeft: '10px' }} href={j.image} target="_blank" rel="noopener noreferrer">
																	<Icon name="external"></Icon>
																</a>
															</List.Item>
														))}
													</List>
												</Table.Cell>
											</Table.Row>
										));
									});
								})}
							</Table.Body>
						</Table>
					</Segment>
				</Page.Wrap>
			</Page>
		);
	}
}
export default withTranslation()(Folder);
