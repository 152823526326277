import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';
import { post } from '../../util/api';
import { Segment, Table, Breadcrumb, Icon, Header, Label, Button, Dropdown, Form, Checkbox, Accordion } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

function DownloadButton(props) {
	let { data } = props;
	if (!data || !data.id) return null;

	return (
		<Button as="a" href={'/api/shop/kolaj/download/' + data.id} target="_blank">
			İndir
		</Button>
	);
}
class Folder extends Component {
	state = {
		activeIndex: 0,
		doneActive: 1,
		verifiedActive: 0,
		failedActive: 1,
		pendingActive: 0,
		loading: true,
		checked: true,
		selected_list: [],
		sort: false,
	};
	componentDidMount() {
		this.getData();
	}
	onChangeCheckbox = (evt, data) => {
		let checked = data.checked;
		this.setState({ checked });
	};
	async getData() {
		let { folder, subfolder } = this.props.match.params;
		if (subfolder === 'all') {
			await post(`/shop/folder/get/${folder}/all`, {}).then((data) => {
				let _data = data.data.result.kolaj;
				let result = data.data.result;
				let done_table = _.filter(_data, (it) => it.status === 'done');
				let failed_table = _.filter(_data, (it) => it.status === 'failed');
				let pending_table = _.filter(_data, (it) => it.status === 'pending');
				let verified_table = _.filter(_data, (it) => it.status === 'verified');
				this.setState({
					data: _data,
					done_table,
					failed_table,
					pending_table,
					verified_table,
					loading: false,
					tableloading: false,
					subfolder_data: result && result.subfolder && result.subfolder[0],
					folder_data: result && result.folder && result.folder[0],
				});
			});
		} else {
			await post('/shop/kolaj/list', { subfolder_id: subfolder }).then((data) => {
				let _data = data.data.result;
				let result = data.data.result;
				let done_table = _.filter(_data, (it) => it.status === 'done');
				let failed_table = _.filter(_data, (it) => it.status === 'failed');
				let pending_table = _.filter(_data, (it) => it.status === 'pending');
				let verified_table = _.filter(_data, (it) => it.status === 'verified');
				if (result && result.length) {
					this.setState({
						data: _data,
						done_table,
						failed_table,
						pending_table,
						verified_table,
						loading: false,
						tableloading: false,
						subfolder_data: result[0].subfolder,
						folder_data: result[0].folder,
						loadRefresh: false,
					});
				} else {
					this.setState({ loading: false, data: [] });
				}
			});
		}
	}
	async forceKolaj(id) {
		await post('/shop/kolaj/force', { id }).then(() => this.getData());
	}
	async stateUpdate(kolaj_id, state_now) {
		let state = state_now === 'active' ? 'passive' : 'active';
		this.setState({ tableloading: true });
		await post('/shop/state/update', { kolaj_id: parseInt(kolaj_id), state }).then(() => this.getData());
	}
	async ListStateUpdate(state) {
		let { selected_list } = this.state;
		await post('/shop/state/update', { selected_list, state }).then(() => this.getData());
		this.setState({ selected_list: [] });
	}
	handleSelectedList(value) {
		let { selected_list } = this.state;
		if (_.includes(selected_list, value)) {
			_.pull(selected_list, value);
		} else {
			selected_list.push(value);
		}
		this.setState({ selected_list });
	}
	changeSort = () => {
		let { sort } = this.state;
		let newSort = !sort;
		this.setState({ sort: newSort });
	};
	TableHeader(t, data, look) {
		let { selected_list } = this.state;
		let isAllSelected = '';
		return (
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell width={1}></Table.HeaderCell>
					<Table.HeaderCell width={4}>{t('name')}</Table.HeaderCell>
					<Table.HeaderCell width={3}>{t('imageCount')}</Table.HeaderCell>
					<Table.HeaderCell width={2}>
						{look === true ? (
							<p id="adcCreatedDay" onClick={() => this.changeSort()} style={{ cursor: 'pointer' }}>
								<Icon size="normal" name="sort alphabet down" /> {t('createDate')}
							</p>
						) : (
							t('createDate')
						)}
					</Table.HeaderCell>
					<Table.HeaderCell width={2}>{t('updateDate')}</Table.HeaderCell>
					<Table.HeaderCell width={3}>{t('status')}</Table.HeaderCell>
					<Table.HeaderCell width={2}></Table.HeaderCell>
				</Table.Row>
			</Table.Header>
		);
	}
	TableBody(t, data, isDone, isVerified) {
		let { folder, subfolder } = this.props.match.params;
		let { checked, selected_list, sort } = this.state;
		if (data && checked) {
			data = _.filter(data, (it) => it.state !== 'passive');
			if (sort === true) {
				data = _.sortBy(data, [
					function (o) {
						return o.created_at;
					},
				]);
			} else {
				data = _.sortBy(data, [
					function (o) {
						return o.created_at;
					},
				]).reverse();
			}
		}
		//console.log('data', data);
		return (
			<Table.Body>
				{data && data.length ? (
					_.map(data, (it, i) => (
						<Table.Row key={i}>
							<Table.Cell>
								<Checkbox checked={_.includes(selected_list, it.id)} onChange={() => this.handleSelectedList(it.id)}></Checkbox>
							</Table.Cell>
							<Table.Cell>
								<Link
									to={
										subfolder === 'all'
											? '/whatsapp-shop/' + folder + '/' + it.subfolder_id + '/' + it.id + '?all=true'
											: '/whatsapp-shop/' + folder + '/' + it.subfolder_id + '/' + it.id
									}
								>
									{it.data.kolaj_name}
								</Link>
							</Table.Cell>
							<Table.Cell>{it.data.images.length}</Table.Cell>
							<Table.Cell>{moment(it.created_at).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
							<Table.Cell>{moment(it.updated_at).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
							<Table.Cell>
								{<Icon name={it.state === 'active' ? 'check' : 'x'} color={it.state === 'active' ? 'green' : 'red'}></Icon>}
								{t(it.state)}
							</Table.Cell>
							<Table.Cell style={{ textAlign: 'end' }}>
								{isDone ? (
									<Dropdown floating labeled button icon="bars" className="icon" text={'Seçenekler'}>
										<Dropdown.Menu>
											<Dropdown.Item text="Tekrar İşle" onClick={() => this.forceKolaj(it.id)} />
											<Dropdown.Item
												text={it.state === 'passive' ? 'Aktif Yap' : 'Pasif Yap'}
												onClick={() => this.stateUpdate(it.id, it.state)}
											/>
										</Dropdown.Menu>
									</Dropdown>
								) : isVerified ? (
									<div style={{ display: 'flex' }}>
										<Dropdown floating labeled button icon="bars" className="icon" text={'Seçenekler'}>
											<Dropdown.Menu>
												<Dropdown.Item
													text={it.state === 'passive' ? 'Aktif Yap' : 'Pasif Yap'}
													onClick={() => this.stateUpdate(it.id, it.state)}
												/>
											</Dropdown.Menu>
										</Dropdown>
										<DownloadButton t={t} data={it} />
									</div>
								) : null}
							</Table.Cell>
						</Table.Row>
					))
				) : (
					<Table.Row>
						<Table.Cell>Liste Boş</Table.Cell>
					</Table.Row>
				)}
			</Table.Body>
		);
	}
	textfilter(data, kolaj_editor, text) {
		let main_text = _.deburr(data).toLowerCase();
		let sub_code = (kolaj_editor && kolaj_editor.text_data && kolaj_editor.text_data.code) || '';
		text = _.deburr(text).toLowerCase();
		sub_code = _.deburr(sub_code).toLowerCase();
		//let re = new RegExp(text);
		return main_text.includes(text) || (sub_code && sub_code.includes(text));
	}
	handleAccordion = (name) => {
		const newIndex = this.state[name];
		let result = newIndex ? 0 : 1;
		this.setState({ [name]: result });
	};
	render() {
		const { t } = this.props;
		let {
			loading,
			folder_data,
			subfolder_data,
			done_table,
			failed_table,
			pending_table,
			verified_table,
			tableloading,
			search_text,
			data,
			checked,
			activeIndex,
			doneActive,
			verifiedActive,
			pendingActive,
			failedActive,
			selected_list,
		} = this.state;
		let { folder, subfolder } = this.props.match.params;
		if (checked) {
			failed_table = _.filter(failed_table, (it) => it.state !== 'passive');
			done_table = _.filter(done_table, (it) => it.state !== 'passive');
			pending_table = _.filter(pending_table, (it) => it.state !== 'passive');
			verified_table = _.filter(verified_table, (it) => it.state !== 'passive');
		}
		if (loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
								<Icon name="block layout" />
								Kolaj
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		if (!data.length) {
			return <Redirect to="/whatsapp-shop" />;
		}
		if (search_text) {
			done_table = _.filter(done_table, (it) => this.textfilter(it.data.kolaj_name, it.data.kolaj_editor, search_text));
			failed_table = _.filter(failed_table, (it) => this.textfilter(it.data.kolaj_name, it.data.kolaj_editor, search_text));
			pending_table = _.filter(pending_table, (it) => this.textfilter(it.data.kolaj_name, it.data.kolaj_editor, search_text));
			verified_table = _.filter(verified_table, (it) => this.textfilter(it.data.kolaj_name, it.data.kolaj_editor, search_text));
		}
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/whatsapp-shop`}>
							<Icon name="block layout" />
							Kolaj
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/whatsapp-shop/${folder}`}>
							{folder_data.data.name}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section>{subfolder === 'all' ? 'Hepsi' : subfolder_data.data.name}</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				{tableloading ? (
					<Page.Loader></Page.Loader>
				) : (
					<Page.Wrap>
						<div style={{ display: 'flex' }}>
							<div>
								<Button basic labelPosition="left" color="grey" icon as={Link} to={`/whatsapp-shop/${folder}`}>
									<Icon name="arrow left"></Icon>
									{t('back')}
								</Button>
								<Button
									basic
									labelPosition="left"
									color="green"
									icon
									onClick={() => {
										this.setState({ tableloading: true });
										this.getData();
									}}
								>
									<Icon name="redo"></Icon>
									Yenile
								</Button>
								<Dropdown text="Seçili İşlemleri" icon="list" floating labeled button className="icon" basic color="brown">
									<Dropdown.Menu>
										<Dropdown.Item icon="circle outline" text="Seçilileri Pasif Yap" onClick={() => this.ListStateUpdate('passive')} />
										<Dropdown.Item icon="circle" text="Seçilileri Aktif Yap" onClick={() => this.ListStateUpdate('active')} />
									</Dropdown.Menu>
								</Dropdown>
								<Checkbox toggle label="Pasifleri Gizle" checked={checked} onClick={(evt, data) => this.onChangeCheckbox(evt, data)} />
							</div>
							<div>
								<Form style={{ marginLeft: '20px' }}>
									<Form.Input
										placeholder={t('search')}
										icon={'search'}
										name="search_text"
										value={search_text}
										onChange={(e, { value }) => this.setState({ search_text: value })}
									></Form.Input>
								</Form>
							</div>
						</div>
						<Accordion fluid styled>
							<Segment color="red" style={{ padding: 0, marginTop: '10px' }}>
								<Accordion.Title active={failedActive === 1} index={1} onClick={() => this.handleAccordion('failedActive')}>
									<div style={{ display: 'flex' }}>
										<Icon name="dropdown" />
										<p style={{ fontSize: '18px', fontWeight: '700', lineHeight: '18px' }}>Müdahele Gerekenler</p>
										<Label color="red" image style={{ marginLeft: 'auto', height: 'fit-content' }}>
											{t('total')}
											<Label.Detail>{failed_table.length}</Label.Detail>
										</Label>
									</div>
								</Accordion.Title>
								<Accordion.Content active={failedActive === 1}>
									<Table>
										{this.TableHeader(t, failed_table)}
										{this.TableBody(t, failed_table, true)}
									</Table>
								</Accordion.Content>
							</Segment>
						</Accordion>
						<Accordion fluid styled>
							<Segment color="yellow" style={{ padding: 0, marginTop: '10px' }}>
								<Accordion.Title active={doneActive === 1} index={1} onClick={() => this.handleAccordion('doneActive')}>
									<div style={{ display: 'flex' }}>
										<Icon name="dropdown" />
										<p style={{ fontSize: '18px', fontWeight: '700', lineHeight: '18px' }}>Onay Bekleyenler</p>
										<Label color="yellow" image style={{ marginLeft: 'auto', height: 'fit-content' }}>
											{t('total')}
											<Label.Detail>{done_table.length}</Label.Detail>
										</Label>
									</div>
								</Accordion.Title>
								<Accordion.Content active={doneActive === 1}>
									<Table>
										{this.TableHeader(t)}
										{this.TableBody(t, done_table, true)}
									</Table>
								</Accordion.Content>
							</Segment>
						</Accordion>
						<Accordion fluid styled>
							<Segment color="green" style={{ padding: 0, marginTop: '10px' }}>
								<Accordion.Title active={verifiedActive === 1} index={1} onClick={() => this.handleAccordion('verifiedActive')}>
									<div style={{ display: 'flex' }}>
										<Icon name="dropdown" />
										<p style={{ fontSize: '18px', fontWeight: '700', lineHeight: '18px' }}>Onaylananlar</p>
										<Label color="green" image style={{ marginLeft: 'auto', height: 'fit-content' }}>
											{t('total')}
											<Label.Detail>{verified_table.length}</Label.Detail>
										</Label>
									</div>
								</Accordion.Title>
								<Accordion.Content active={verifiedActive === 1}>
									<Table>
										{this.TableHeader(t, null, true)}
										{this.TableBody(t, verified_table, true)}
									</Table>
								</Accordion.Content>
							</Segment>
						</Accordion>

						<Accordion fluid styled>
							<Segment color="orange" style={{ padding: 0, marginTop: '10px' }}>
								<Accordion.Title active={pendingActive === 1} index={1} onClick={() => this.handleAccordion('pendingActive')}>
									<div style={{ display: 'flex' }}>
										<Icon name="dropdown" />
										<p style={{ fontSize: '18px', fontWeight: '700', lineHeight: '18px' }}>İşlenenler</p>
										<Label color="orange" image style={{ marginLeft: 'auto', height: 'fit-content' }}>
											{t('total')}
											<Label.Detail>{pending_table.length}</Label.Detail>
										</Label>
									</div>
								</Accordion.Title>
								<Accordion.Content active={pendingActive === 1}>
									<Table>
										{this.TableHeader(t)}
										{this.TableBody(t, pending_table, true)}
									</Table>
								</Accordion.Content>
							</Segment>
						</Accordion>
					</Page.Wrap>
				)}
			</Page>
		);
	}
}

export default withTranslation()(Folder);
