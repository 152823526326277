import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Segment, Icon, Table, Button, Message, Checkbox, Confirm, Modal, Form } from 'semantic-ui-react';
import { getWG, postWg, toWapi, post, getFull } from '../../util/api';
import _ from 'lodash';
import moment from 'moment';

class WTable extends Component {
	state = {
		loading: true,
		selected_array: [],
		selectedHour: 'all',
		oneDay: false,
	};
	async componentDidMount() {
		let { subfolder } = this.props.match.params;
		let { shop } = this.props;
		await getWG(`/api/${shop.keyword}/log`).then((data) => {
			this.setState({ log: data.result });
		});
		await getFull(shop.server_config.mobile_images).then((data) => {
			let file = _.find(data.result.folders.files, { name: subfolder });
			let images = file.images;
			let _data = _.orderBy(images, ['stats.mtimeMs'], ['desc']);
			let g_verified_table = _.groupBy(_data, (it) => moment(it.stats.birthtime).format('DD-MM-YYYY'));
			this.setState({ data: _data, loadRefresh: false, verified_table: images, g_verified_table, folder_data: data.result.folders.name });
		});
		await this.getListPhone();
	}
	async getListPhone() {
		let { shop } = this.props;
		let { whatsapp } = shop.server_config;
		toWapi(`/api/${whatsapp.product_id}/listPhones`, whatsapp.key)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				let activePhones = _.filter(data, (it) => it.status === 'active');
				let warningPhones = _.filter(data, (it) => it.status !== 'active');
				this.setState({ phones: data, activePhones, warningPhones, loading: false, btnLoading: false });
			})
			.catch((err) => {
				console.error(err);
			});
	}
	async handleSubmit() {
		let { subfolder } = this.props.match.params;
		let { selected_array, verified_table, subfolder_data, folder_data, selectedHour, log } = this.state;
		let { shop } = this.props;
		let images_list = [];
		if (selected_array.length === 1 && selectedHour === 'onlyAfter') {
			let selected_day = selected_array[0];
			let status = _.find(log, (lit) => lit.brand === subfolder && lit.day === selected_day);
			let status_updateDate = status && status.update_date ? moment(status.update_date) : '';
			images_list = _.filter(
				verified_table,
				(it) => _.includes(selected_array, moment(it.stats.birthtime).format('DD-MM')) && moment(it.stats.birthtime).isAfter(status_updateDate, 'm')
			);
		} else {
			images_list = _.filter(verified_table, (it) => _.includes(selected_array, moment(it.stats.birthtime).format('DD-MM')));
		}
		let send_list =
			images_list &&
			images_list.length &&
			_.map(images_list, (it) => `https://cdn.whatsapp-shop.com/img960/mobile_kolajsiz/${folder_data}/${subfolder}/${it.name}`);
		/* console.log({
			images_list,
			images: send_list,
			firm: shop.keyword,
			brand: subfolder,
			days: selected_array,
			selectedHour,
		});
		return; */
		await postWg('/api/send_kolaj', {
			images: send_list,
			firm: shop.keyword,
			brand: subfolder,
			days: selected_array,
		}).then((data) => {
			if (data.data.success) {
				this.setState({
					open: false,
					cardType: 'green',
					cardMessage: 'Seçmiş olduğunuz klasör atılmaya başlamıştır.',
				});
				this.componentDidMount();
			} else {
				this.setState({ open: false, cardType: 'red', cardMessage: 'Bu klasör daha önce atılmıştır.' });
			}
		});
	}
	handleChange(value) {
		let { selected_array } = this.state;
		let isExist = _.includes(selected_array, value);
		if (isExist) {
			_.pull(selected_array, value);
		} else {
			selected_array.push(value);
		}
		this.setState(selected_array);
	}
	TimeModal(selected_array, selectedHour, log) {
		let { subfolder } = this.props.match.params;
		let selected_day = selected_array[0];
		let status = _.find(log, (lit) => lit.brand === subfolder && lit.day === selected_day);

		let status_updateDate = status && status.update_date ? moment(status.update_date).format('HH:mm') : '';
		if (status) {
			return (
				<Form style={{ marginTop: '10px' }}>
					<Form.Field>Gönderim Seçimi</Form.Field>
					<Form.Field>
						<Checkbox
							radio
							label="Hepsini Gönder"
							name="selectedHour"
							value="all"
							checked={selectedHour === 'all'}
							onChange={() => this.setState({ selectedHour: 'all' })}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							radio
							label={`${status_updateDate} Saatinden Sonrakileri Gönder`}
							name="checkboxRadioGroup"
							value="onlyAfter"
							checked={selectedHour === 'onlyAfter'}
							onChange={() => this.setState({ selectedHour: 'onlyAfter' })}
						/>
					</Form.Field>
				</Form>
			);
		}
	}
	render() {
		let { data, loading, subfolder_data, cardType, cardMessage, phones, activePhones, warningPhones, g_verified_table, selected_array, log, selectedHour } =
			this.state;
		let { subfolder } = this.props.match.params;
		const { t, shop } = this.props;
		let { server_config } = shop;
		if (loading) {
			return (
				<Page title={'WhatsApp Group'} icon="whatsapp">
					<Page.Loader></Page.Loader>
				</Page>
			);
		}

		if (!data.length) {
			return <Redirect to="/wgroup" />;
		}
		return (
			<Page title={'WhatsApp Group'} icon="whatsapp">
				<Page.Wrap>
					<div className="wapi-state" style={{ display: 'flex' }}>
						<div className="but">
							<Button
								icon
								color="green"
								basic
								onClick={() => {
									this.setState({ btnLoading: true });
									this.componentDidMount();
								}}
							>
								<Icon name="refresh" loading={this.state.btnLoading} color="white"></Icon>
							</Button>
						</div>
						<div className="logo">
							<div class="wapi-logo" style={{ background: '#25D366' }}>
								<img src="/images/whatsapp.svg" alt="whatsapp" style={{ height: '36px', display: 'flex', padding: '6px' }}></img>
							</div>
						</div>
						<div className="info" style={{ width: '100%', display: 'flex' }}>
							<div className="active-part" style={{ display: 'flex', background: '#d4edda', lineHeight: '36px', color: '#155724' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="check" color="green" /> Aktif {(activePhones && activePhones.length) + ' / ' + (phones && phones.length)}
								</div>
							</div>
							{warningPhones && warningPhones.length ? (
								<div className="warning-part" style={{ display: 'flex', background: '#fff3cd', lineHeight: '36px', color: '#856404' }}>
									<div style={{ marginLeft: '10px', marginRight: '10px' }}>
										<Icon name="warning" color="brown" />{' '}
										{_.map(warningPhones, (it, i) => (
											<span>{it.number + '(' + it.id + '): ' + it.status + (i !== warningPhones.length - 1 ? ', ' : ' ')}</span>
										))}
									</div>
								</div>
							) : (
								''
							)}
							<div className="phonestat" style={{ display: 'flex', lineHeight: '36px', background: '#9696f378', color: 'darkblue' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="mobile alternate" color="darkblue" />{' '}
									<Link to="/wgroup/phones_status" style={{ color: 'darkblue' }}>
										Kuyruk Durumuna Git
									</Link>
								</div>
							</div>
						</div>
					</div>
					<Segment>
						{cardType && <Message color={cardType} content={cardMessage}></Message>}
						{this.state.btnLoading ? (
							<Page.Loader></Page.Loader>
						) : (
							<div>
								<Table>
									<Table.Header>
										<Table.HeaderCell width={2}>{subfolder}</Table.HeaderCell>
										<Table.HeaderCell>Gün</Table.HeaderCell>
										<Table.HeaderCell>Resim Sayısı</Table.HeaderCell>
										<Table.HeaderCell width={3}>
											<Button positive onClick={() => this.setState({ open: true })}>
												Gönder
											</Button>
										</Table.HeaderCell>
									</Table.Header>
									<Table.Body>
										{_.map(g_verified_table, (it, i) => {
											let status = _.find(
												log,
												(lit) =>
													lit.brand === subfolder &&
													lit.day === moment(it[0].stats.birthtime).format('DD-MM') &&
													lit.year === moment(it[0].stats.birthtime).year()
											);
											return (
												<Table.Row>
													<Table.Cell>
														<Checkbox onClick={() => this.handleChange(moment(it[0].stats.birthtime).format('DD-MM'))}></Checkbox>
													</Table.Cell>
													<Table.Cell>{moment(it[0].stats.birthtime).format('DD-MM')}</Table.Cell>
													<Table.Cell>{it.length}</Table.Cell>
													<Table.Cell>
														{(status &&
															status.status &&
															t(status.status) +
																(status.update_date ? ' (' + moment(status.update_date).format('DD/MM - HH:mm') + ')' : '')) ||
															'Gönderilmedi'}
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</div>
						)}
					</Segment>
					<Modal open={this.state.open}>
						<Modal.Content>
							<Modal.Description>Kolaj gönderim işlemine başlamak istediğinize emin misiniz?</Modal.Description>
							{server_config.sendOnlyAfter && selected_array.length === 1 ? this.TimeModal(selected_array, selectedHour, log) : ''}
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={() => this.setState({ open: false, oneDay: false })}>{t('no')}</Button>
							<Button onClick={() => this.handleSubmit()} positive>
								{t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
				</Page.Wrap>
			</Page>
		);
	}
}

export default connect((state) => ({ shop: state.product.shop }))(withTranslation()(WTable));
